import InputAdornment from '@mui/material/InputAdornment';
import InputBase, {InputBaseProps} from '@mui/material/InputBase';
import {styled, useTheme} from '@mui/material/styles';
import React from 'react';

import cn from 'classnames';

import {Button, ButtonProps} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';

const StyledInput = styled(InputBase)(({theme}) => ({
  '&.MuiInputBase-root': {
    position: 'relative',
    width: '100%',
    fontFamily: '"Inter", sans-serif',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: 8,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover:not(:disabled):not(:focus)': {
      borderColor: theme.palette.border.tertiary,
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    padding: `0`,
    fontSize: theme.size.fontSize.S,
    fontWeight: theme.size.fontWeight.regular,
    lineHeight: theme.size.lineHeight.S,
    letterSpacing: theme.size.letterSpacing.S,
    color: theme.palette.body.primary,
    WebkitTextFillColor: 'unset !important',
    '&:hover:not(:disabled):not(:focus)': {
      borderColor: theme.palette.border.tertiary,
    },
    '&::placeholder': {
      color: theme.palette.body.placeholder,
      opacity: 1,
    },
  },
  '& .MuiInputAdornment-positionEnd': {
    '&.hidden': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
}));

export interface ISearchInputProps extends InputBaseProps {
  onClear?(): void;
  onClickSearchButton?(): void;
  searchByButton?: boolean;
  SearchButtonProps?: ButtonProps;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  onClear,
  onClickSearchButton,
  searchByButton,
  SearchButtonProps,
  ...props
}) => {
  const {t} = useTranslation();

  const theme = useTheme();
  const [inputValue, setInputValue] = React.useState<unknown>();

  const handleClearInput = () => {
    setInputValue(null);
    onClear?.();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event);
    setInputValue(event.target.value);
  };

  React.useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props]);

  const iconStyle = {
    fill: theme.palette.body.placeholder,
  };

  return (
    <StyledInput
      {...props}
      className={cn({hasValue: inputValue}, props.className)}
      value={inputValue || ''}
      onChange={handleChange}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon style={iconStyle} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {inputValue ? (
            <IconButton edge="end" size="small" onClick={handleClearInput} tabIndex={-1}>
              <CloseIcon style={iconStyle} />
            </IconButton>
          ) : null}

          {searchByButton ? (
            <Button
              {...SearchButtonProps}
              variant="contained"
              size="large"
              onClick={onClickSearchButton}
              sx={{marginLeft: theme.spacing(2)}}
            >
              {t('component_search_input_button')}
            </Button>
          ) : null}
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
