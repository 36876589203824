import qs from 'query-string';

export const getQueryStringParam = (name: string): string => {
  const qsPrms = qs.parse(window.location.search);

  const param: string | null | Array<string | null> = qsPrms[name];

  let paramValue: string = '';

  if (typeof param === 'string') {
    paramValue = param || '';
  } else if (Array.isArray(param)) {
    const paramValueFromArray = param[0] || '';
    paramValue = typeof paramValueFromArray === 'string' ? paramValueFromArray : '';
  }

  return paramValue;
};

export default getQueryStringParam;
