import {Button} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import Typography from '../../components/UI/Typography';
import {BillingPageTab} from './BillingPage';

interface IProps {
  currentTab: BillingPageTab;
  onBack?(): void;
}

export const BillingPageTopBar: React.FC<IProps> = (props) => {
  const {t} = useTranslation();

  const titlesData = {
    [BillingPageTab.BILLING_DETAILS]: t('billing_title'),
    [BillingPageTab.TOP_UP_BALANCE]: t('billing_top_bar_top_up_title'),
  };

  const isFirstTab = props.currentTab === BillingPageTab.BILLING_DETAILS;

  return (
    <div className="billing-page-top-bar">
      {isFirstTab ? null : (
        <div className="billing-page-top-bar__back">
          <Button
            className="billing-page-top-bar__back-btn"
            variant="outlined"
            size="large"
            onClick={props.onBack}
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
      )}

      <div className="billing-page-top-bar__title-wrap">
        {isFirstTab ? null : (
          <Typography variant="body3" color="body.tertiary">
            {t('billing_title')}
          </Typography>
        )}
        <Typography variant="h4">{titlesData[props.currentTab]}</Typography>
      </div>
    </div>
  );
};

export default BillingPageTopBar;
