import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {EventEmitter} from '../../api/EventEmitter';
import {ca2types} from '../../api/proto';
import {t} from '../../i18n';
import PriceFormatter from '../../utils/priceFormatter';

export class BaseInstance extends EventEmitter {
  @observable id: number | null = null;
  @observable planId: string | null = null;
  @observable datacenterId: string | null = null;
  @observable imageId: string | null = null;
  @observable sshKeyIds: number[] = [];
  @observable rootPassword: string | null = null;
  @observable name: string | null = null;
  @observable isImportant: boolean | null = null;
  @observable ipv4: string | null = null;
  @observable ipv6: string | null = null;
  @observable errorDescription: string | null = null;
  @observable enabledAddonIds: string[] | null = null;
  @observable providerId: string | null = null;
  @observable uptime: Long | null = null;
  @observable private totalCharge: ca2types.IAmount | null = null;
  @observable instanceStatus: ca2types.InstanceStatus | null = null;
  @observable initialSetupScript: string | null = null;
  @observable allowDelete: boolean = false;
  @observable allowStart: boolean = false;
  @observable allowStop: boolean = false;
  @observable allowRestart: boolean = false;
  @observable allowReinstall: boolean = false;
  @observable allowUpdateSshKeys: boolean = false;

  @observable totalChargeFormatter: PriceFormatter;

  constructor(public raw: ca2types.IInstance) {
    super();
    makeObservable(this);

    this.totalChargeFormatter = new PriceFormatter(raw.totalCharge);
    this.update_(raw);
  }

  @action protected update_ = (raw: ca2types.IInstance) => {
    Object.assign(this, raw);

    this.totalChargeFormatter.update(raw.totalCharge);
  };

  @computed get allowSomeAction(): boolean {
    return (
      (this.allowDelete || this.allowReinstall || this.allowRestart || this.allowStart || this.allowStop) &&
      !this.isInprogressStatus
    );
  }

  @computed get isActive() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_ACTIVE;
  }

  @computed get isCreating() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_CREATING;
  }

  @computed get isDeleted() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_DELETED;
  }

  @computed get isDeleting() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_DELETING;
  }

  @computed get isError() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_ERROR;
  }

  @computed get isProcessing() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_PROCESSING;
  }

  @computed get isReinstalling() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_REINSTALLING;
  }

  @computed get isResizing() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_RESIZING;
  }

  @computed get isRestarting() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_RESTARTING;
  }

  @computed get isStarting() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_STARTING;
  }

  @computed get isStopped() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_STOPPED;
  }

  @computed get isStopping() {
    return this.instanceStatus === ca2types.InstanceStatus.IS_STOPPING;
  }

  @computed get isInprogressStatus() {
    return (
      this.isCreating ||
      this.isDeleting ||
      this.isProcessing ||
      this.isReinstalling ||
      this.isResizing ||
      this.isRestarting ||
      this.isStarting ||
      this.isStopping
    );
  }

  @computed get statusTitle() {
    switch (this.instanceStatus) {
      case ca2types.InstanceStatus.IS_UNKNOWN:
        return t('server_status_is_unknown');
      case ca2types.InstanceStatus.IS_CREATING:
        return t('server_status_is_creating');
      case ca2types.InstanceStatus.IS_DELETING:
        return t('server_status_is_deleting');
      case ca2types.InstanceStatus.IS_RESTARTING:
        return t('server_status_is_restarting');
      case ca2types.InstanceStatus.IS_STARTING:
        return t('server_status_is_starting');
      case ca2types.InstanceStatus.IS_STOPPING:
        return t('server_status_is_stopping');
      case ca2types.InstanceStatus.IS_REINSTALLING:
        return t('server_status_is_reinstalling');
      case ca2types.InstanceStatus.IS_RESIZING:
        return t('server_status_is_resizing');
      case ca2types.InstanceStatus.IS_ACTIVE:
        return t('server_status_is_active');
      case ca2types.InstanceStatus.IS_PROCESSING:
        return t('server_status_is_processing');
      case ca2types.InstanceStatus.IS_STOPPED:
        return t('server_status_is_stopped');
      case ca2types.InstanceStatus.IS_DELETED:
        return t('server_status_is_deleted');
      case ca2types.InstanceStatus.IS_ERROR:
        return t('server_status_is_error');
      default:
        return t('server_status_unknown');
    }
  }
}

export default BaseInstance;
