import {Box} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Virtuoso} from 'react-virtuoso';
import Loader from '../../../components/UI/Loader';
import Typography from '../../../components/UI/Typography';
import {useStore} from '../../../stores/AppStore';
import {Plan} from '../../../stores/Plan';
import PlanCard from '../PlanCard';

interface IProps {
  onSelectPlan?(plan: Plan): void;
  onChangePlan?(): void;
}

const PlansList: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {plansStore} = useStore();

  return (
    <div className="plans-list">
      <div className="plans-list__head">
        <Typography className="plans-list__head-title" variant="body1">
          {t('create_server_plans_list_count', {count: plansStore.plans.length})}
        </Typography>
      </div>
      <Box className={cn('plans-list__body', {'empty-list': !plansStore.plans.length})}>
        <Virtuoso
          totalCount={plansStore.plans.length}
          className="custom-scroll"
          itemContent={(index) => (
            <PlanCard
              plan={plansStore.plans[index]}
              onSelectPlan={props.onSelectPlan}
              onChangePlan={props.onChangePlan}
            />
          )}
        />

        {!plansStore.loading && !plansStore.plans.length && (
          <Typography className="empty-list-title" variant="body1">
            {t('create_server_plans_list_not_found')}
          </Typography>
        )}

        <Loader loading={plansStore.loading} />
      </Box>
    </div>
  );
});

export default PlansList;
