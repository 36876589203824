import {Box, styled} from '@mui/material';

export const AccessesRow = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.border.primary}`,
}));

export default AccessesRow;
