import {Box, Button, Grid, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import Typography from '../../components/UI/Typography';
import {useStore} from '../../stores/AppStore';
import convertHoursToDaysAndHours from '../../utils/convert/convertHoursToDaysAndHours';
import BalanceOperationsTable from './BalanceOperationsTable';

interface IProps {
  onTopUpBalance?(): void;
}

const BorderBox = styled(Box)(({theme}) => ({
  flex: '1 1 auto',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: theme.spacing(3),
}));

const BillingDetailsViewer: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {billingStore} = useStore();
  const {t} = useTranslation();

  const {days, hours} = React.useMemo(() => convertHoursToDaysAndHours(billingStore.hoursLeft), [billingStore.hoursLeft]);

  return (
    <div className="billing-details-viewer">
      <div className="billing-details-viewer__body">
        <div className="billing-details-viewer__side billing-details-viewer__side--left">
          <div className="billing-details-viewer__row">
            <div>
              <Typography className="user-balance__amount-label" variant="body3">
                {t('billing_balance_title')}
              </Typography>
              <Typography variant="h4">{billingStore.balanceFormatter.formatted}</Typography>
            </div>
            <Button
              variant="contained"
              onClick={props.onTopUpBalance}
              sx={{marginLeft: 'auto', textTransform: 'capitalize'}}
              size="large"
              startIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
            >
              {t('billing_top_bar_button')}
            </Button>
          </div>
          <div className="billing-details-viewer__row billing-details-viewer__billing-title">
            <div>
              <Typography className="user-balance__amount-label" variant="label2">
                {t('billing_remaining_balance_with_last_for')}
              </Typography>
              <Typography variant="h4">{t('billing_balance_days_hours_title', {days, hours})}</Typography>
            </div>
          </div>
          <div className="billing-details-viewer__row">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="user-balance__amount-label" variant="label2" gutterBottom>
                  {t('billing_charges_label')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <BorderBox>
                  <Typography className="user-balance__amount-label" variant="body3">
                    {t('billing_balance_charge_per_hour_label')}
                  </Typography>
                  <Typography variant="subtitle1">{billingStore.chargePerHourFormatter.formatted}</Typography>
                </BorderBox>
              </Grid>
              <Grid item xs={12} lg={4}>
                <BorderBox>
                  <Typography className="user-balance__amount-label" variant="body3">
                    {t('billing_balance_charge_per_day_label')}
                  </Typography>
                  <Typography variant="subtitle1">{billingStore.chargePerDayFormatter.formatted}</Typography>
                </BorderBox>
              </Grid>
              <Grid item xs={12} lg={4}>
                <BorderBox>
                  <Typography className="user-balance__amount-label" variant="body3">
                    {t('billing_balance_charge_per_month_label')}
                  </Typography>
                  <Typography variant="subtitle1">{billingStore.chargePerMonthFormatter.formatted}</Typography>
                </BorderBox>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="billing-details-viewer__side billing-details-viewer__side--right">
          <BalanceOperationsTable />
        </div>
      </div>
    </div>
  );
});

export default BillingDetailsViewer;
