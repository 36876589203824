import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export function isCountryCode(input) {
  const upperInput = input.toUpperCase();
  return countries.isValid(upperInput);
}

const countryNames = countries.getNames('en', {select: 'official'});

export function formattedCountryCode(input) {
  const upperInput = input.toUpperCase();

  const nonStandardCodes = {
    USA: 'US',
    UK: 'GB',
  };

  if (nonStandardCodes[upperInput]) {
    return nonStandardCodes[upperInput];
  }

  if (isCountryCode(upperInput)) {
    return upperInput;
  }

  const countryCode = Object.keys(countryNames).find((code) => countryNames[code].toUpperCase() === upperInput);

  if (countryCode) {
    return countryCode;
  }

  throw new Error('Invalid country name or country code');
}

export function formattedCountryName(input) {
  const upperInput = input.toUpperCase();

  const nonStandardCodes = {
    USA: 'United States of America',
    UK: 'United Kingdom',
  };

  if (nonStandardCodes[upperInput]) {
    return nonStandardCodes[upperInput];
  }

  if (isCountryCode(upperInput)) {
    return countryNames[upperInput];
  }

  const countryCode = Object.keys(countryNames).find((code) => countryNames[code].toUpperCase() === upperInput);

  if (countryCode) {
    return countryNames[countryCode];
  }

  throw new Error('Invalid country name or country code');
}
