import {action, computed, makeAutoObservable, observable} from 'mobx';
import browserStorage from '../../utils/browserStorage';

enum SIDEBAR_MODE {
  COLLAPSED = 'collapsed',
  STATIC = 'static',
}

const SIDEBAR_VARIANT_STORAGE_KEY = '_sidebar';

export class SidebarStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable isStaticMode = true;
  @observable private isMouseHover_ = false;

  @computed get collapsed() {
    if (this.isStaticMode) {
      return false;
    }

    return !this.isMouseHover_;
  }

  @action setIsMouseHover = (isMouseHover: boolean) => {
    this.isMouseHover_ = isMouseHover;
  };

  @action private reset_ = () => {
    this.isStaticMode = true;
    this.isMouseHover_ = false;
  };

  @action private setCurrentMode_ = (mode: SIDEBAR_MODE) => {
    const staticMode = mode !== SIDEBAR_MODE.COLLAPSED;

    if (this.isStaticMode === staticMode) {
      return;
    }

    this.isStaticMode = staticMode;

    try {
      browserStorage.setItem(
        SIDEBAR_VARIANT_STORAGE_KEY,
        mode !== SIDEBAR_MODE.COLLAPSED ? SIDEBAR_MODE.STATIC : SIDEBAR_MODE.COLLAPSED,
      );
    } catch (e) {
      console.warn(e);
    }
  };

  public init = () => {
    this.reset_();

    try {
      const mode =
        browserStorage.getItem(SIDEBAR_VARIANT_STORAGE_KEY) !== 'collapsed'
          ? SIDEBAR_MODE.STATIC
          : SIDEBAR_MODE.COLLAPSED;

      this.setCurrentMode_(mode);
    } catch (e) {
      console.warn(e);
    }
  };

  public toggleCurrentMode = async () => {
    const mode = this.isStaticMode ? SIDEBAR_MODE.COLLAPSED : SIDEBAR_MODE.STATIC;
    this.setCurrentMode_(mode);
  };
}

export default SidebarStore;
