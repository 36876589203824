import {Box, styled} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PremiumDomainDarkIcon} from '../../../../assets/icons/premium-domain-dark.svg';
import {ReactComponent as PremiumDomainHoverIcon} from '../../../../assets/icons/premium-domain-hover.svg';
import {ReactComponent as PremiumDomainIcon} from '../../../../assets/icons/premium-domain.svg';
import Typography from '../../../../components/UI/Typography';
import {useStore} from '../../../../stores/AppStore';

const LabelTypography = styled(Typography)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(13, 13, 13, 0.70)',
  padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
  borderRadius: '4px',
  color: theme.palette.body.tint,
}));

export interface IPremiumDomainLabelRef {
  showLabel?(): void;
  hideLabel?(): void;
}

export const PremiumDomainLabel = observer(
  React.forwardRef<IPremiumDomainLabelRef>((_, ref) => {
    const {t} = useTranslation();
    const {lightThemeChosen} = useStore();
    const [showLabel, setShowLabel] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
      showLabel: () => {
        setShowLabel(true);
      },
      hideLabel: () => {
        setShowLabel(false);
      },
    }));

    return (
      <Box sx={(theme) => ({marginLeft: theme.spacing(2), lineHeight: 0})}>
        {showLabel ? (
          <Box sx={{display: 'flex'}}>
            <PremiumDomainHoverIcon />
            <LabelTypography variant="body2">{t('domains_search_suggestion_premium_label')}</LabelTypography>
          </Box>
        ) : (
          <>{lightThemeChosen ? <PremiumDomainIcon /> : <PremiumDomainDarkIcon />}</>
        )}
      </Box>
    );
  }),
);

export default PremiumDomainLabel;
