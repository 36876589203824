import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {TableVirtuoso} from 'react-virtuoso';
import {ca2types} from '../../../api/proto';
import {t} from '../../../i18n';
import SshKeyTableRow from './SshKeyTableRow';

export enum SshKeyColumnType {
  KeyName = 'key_name',
  KeyPreview = 'key_preview',
  DateAdded = 'date_added',
}

type Column = {
  id: SshKeyColumnType;
  label: string;
  width?: number;
};

const COLUMN_MIN_WIDTH = 150; //px;

export const SSH_KEYS_TABLE_COLUMNS: Column[] = [
  {
    id: SshKeyColumnType.KeyName,
    label: t('ssh_keys_table_key_name_column'),
    width: COLUMN_MIN_WIDTH,
  },
  {
    id: SshKeyColumnType.KeyPreview,
    label: t('ssh_keys_table_key_preview_column'),
    width: COLUMN_MIN_WIDTH * 2,
  },
  {
    id: SshKeyColumnType.DateAdded,
    label: t('ssh_keys_table_date_added_column'),
    width: COLUMN_MIN_WIDTH,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} sx={{border: 'none'}} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate'}} />,
  TableRow: ({...props}) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

interface IProps {
  sshKeys: ca2types.ISSHKey[];
}

export const SshKeysTable: React.FC<IProps> = observer((props) => {
  function fixedHeaderContent() {
    return (
      <TableRow>
        {SSH_KEYS_TABLE_COLUMNS.map((column) => (
          <TableCell key={column.id} align="left" style={{width: column.width}}>
            <Typography variant="body2" color="body.primary">
              {column.label}
            </Typography>
          </TableCell>
        ))}
        <TableCell style={{width: 60, minWidth: 60}}></TableCell>
      </TableRow>
    );
  }

  return (
    <TableVirtuoso
      data={props.sshKeys}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={(index, sshKey) => <SshKeyTableRow key={index} sshKey={sshKey} />}
    />
  );
});

export default SshKeysTable;
