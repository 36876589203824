import {Box, FormHelperText} from '@mui/material';
import FormControl, {FormControlProps} from '@mui/material/FormControl';
import {InputBaseProps} from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Input from './Input';

export interface TextFieldProps extends InputBaseProps {
  label?: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  rightAdornment?: JSX.Element;
  bottomAdornment?: JSX.Element;
  bottomGutter?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  error,
  helperText,
  fullWidth,
  formControlProps,
  rightAdornment,
  bottomAdornment,
  bottomGutter = true,
  ...rest
}) => {
  return (
    <FormControl
      variant="standard"
      sx={(theme) => ({marginBottom: bottomGutter ? theme.spacing(4) : 0})}
      fullWidth={fullWidth}
      {...formControlProps}
    >
      {label ? <InputLabel shrink>{label}</InputLabel> : null}
      <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
        <Input fullWidth={fullWidth} error={error} {...rest} />
        {rightAdornment && (
          <Box
            sx={(theme) => ({flex: '1 1 auto', display: 'flex', alignItems: 'flex-end', marginLeft: theme.spacing(2)})}
          >
            {rightAdornment}
          </Box>
        )}
      </Box>
      {bottomAdornment && <Box sx={{display: 'flex', width: '100%'}}>{bottomAdornment}</Box>}
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TextField;
