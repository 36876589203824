import {Tooltip, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy.svg';
import TextField, {TextFieldProps} from './TextField';

export const CopyableTextField: React.FC<TextFieldProps> = ({onChange, value: initialValue, ...restProps}) => {
  const {t} = useTranslation();
  const {palette} = useTheme();
  const [value, setValue] = React.useState<unknown>(initialValue);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange?.(event);
  };

  const handleCopy = async () => {
    if (typeof value !== 'string') {
      return;
    }

    try {
      await navigator.clipboard.writeText(value);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    } catch (error) {
      console.error('Error on copy to clipboard: ', error);
    }
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      endAdornment={
        <Tooltip
          title={t('component_copyable_text_input_copy_to_clipboard')}
          open={tooltipOpen}
        >
          <CopyIcon style={{fill: palette.body.placeholder, cursor: 'pointer', marginLeft: 8}} onClick={handleCopy} />
        </Tooltip>
      }
      {...restProps}
    />
  );
};

export default CopyableTextField;
