import React from 'react';
import windowInnerHeight from '../utils/windowInnerHeight';

export function useOrigin100vh(): number | null {
  const [height, setHeight] = React.useState<number | null>(windowInnerHeight);

  React.useEffect(() => {
    function setMeasuredHeight() {
      const _originHeight = windowInnerHeight();
      setHeight(_originHeight);
    }

    window.addEventListener('resize', setMeasuredHeight);
    return () => window.removeEventListener('resize', setMeasuredHeight);
  }, []);

  return height;
}

export default useOrigin100vh;
