import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import {formatDateTimeTimestamp} from '../../utils/format/format';
import PriceFormatter from '../../utils/priceFormatter';

export class BalanceOperation {
  @observable id: Long | null = null;
  @observable balanceType: ca2types.BalanceType | null = null;
  @observable type: ca2types.BalanceOperationType | null = null;
  @observable private amount: ca2types.IAmount | null = null;
  @observable private createdAt: Long | null = null;
  @observable description: string | null = null;

  @observable amountFormatter: PriceFormatter;

  constructor(public raw: ca2types.IBalanceOperation) {
    makeObservable(this);
    this.update_(raw);

    this.amountFormatter = new PriceFormatter(raw.amount);
  }

  @computed get createdAtFormatted() {
    return this.createdAt ? formatDateTimeTimestamp(this.createdAt.toNumber() * 1000) : 0;
  }

  @action private update_ = (raw: ca2types.IBalanceOperation) => {
    Object.assign(this, raw);
  };
}

export default BalanceOperation;
