import classNames from 'classnames';
import React from 'react';

interface IProps {
  amountFormatted?: string | null;
}

const BalanceOperationAmount: React.FC<IProps> = ({amountFormatted}) => {
  const isNegative = amountFormatted?.indexOf('-') !== -1;

  return (
    <div className="balance-operation-amount">
      <div className={classNames('balance-operation-amount__icon', {negative: isNegative})}>
        {isNegative ? '-' : '+'}
      </div>
      {amountFormatted?.replace('-', '')}
    </div>
  );
};

export default BalanceOperationAmount;
