import {Box, Button, styled, Tooltip} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../../api/proto';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy.svg';
// import ReallyDeleteButton from '../ReallyDeleteButton';
import Typography from '../UI/Typography';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.backgroundSecondary,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: '12px',
  marginBottom: theme.spacing(2),
  '& svg': {
    fill: theme.palette.body.primary,
  },
  '& .MuiTypography-root': {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(5),
  },
}));

interface IProps {
  onSshKeyDelete?(id: number): void;
  sshKey: ca2types.ISSHKey;
}

const ServerSshKeyItem: React.FC<IProps> = ({sshKey}) => {
  const {t} = useTranslation();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleCopy = async () => {
    if (typeof sshKey.publicKey !== 'string') {
      return;
    }

    try {
      await navigator.clipboard.writeText(sshKey.publicKey);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    } catch (error) {
      console.error('Error on copy to clipboard: ', error);
    }
  };

  // const handleDeleteKey = () => {
  //   sshKey.id && onSshKeyDelete(sshKey.id);
  // };

  return (
    <Container>
      <Typography variant="body2">
        <b>{sshKey.name}</b> [{sshKey.publicKey}]
      </Typography>

      {/* <ReallyDeleteButton
        sx={(theme) => ({marginRight: theme.spacing(2), marginLeft: 'auto'})}
        variant="outlined"
        color="error"
        onClick={handleDeleteKey}
      >
        {t('component_ssh_keys_viewer_delete_from_server_button')}
      </ReallyDeleteButton> */}

      <Tooltip title={t('server_info_accesses_ssh_key_copied_notification')} open={tooltipOpen}>
        <Button variant="outlined" startIcon={<CopyIcon />} onClick={handleCopy}>
          {t('action_button_copy_key')}
        </Button>
      </Tooltip>
    </Container>
  );
};

export default ServerSshKeyItem;
