import {alpha, Button, Popover, styled, useTheme} from '@mui/material';
import {
  DateRange,
  LocalizationProvider,
  MultiInputDateRangeField,
  PickersShortcutsItem,
  StaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CalendarIcon} from '../assets/icons/calendar.svg';
import {t} from '../i18n';

const StyledPopover = styled(Popover)(({theme}) => ({
  display: 'flex',

  '& .MuiDateRangeCalendar-root > div:first-of-type': {
    opacity: 0,
  },

  '& .MuiPaper-root': {
    color: theme.palette.body.primary,
    borderRadius: '8px',
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.backgroundPrimary,

    border: `1px solid  ${theme.palette.border.primary}`,
  },

  '& .MuiList-root': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 320,
    overflowX: 'auto',
    flexWrap: 'nowrap',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}  0 ${theme.spacing(2)}`,
    borderBottom: `1px solid  ${theme.palette.border.primary}`,
  },
  '& .MuiListItem-root': {
    width: 'auto',
    padding: 0,
  },
}));

const StyledMultiInputDateRangeField = styled(MultiInputDateRangeField)(({theme}) => ({
  width: 320,
  padding: theme.spacing(2),
  borderBottom: `1px solid  ${theme.palette.border.primary}`,

  '& .MuiInputBase-root': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    fontSize: theme.size.fontSize.S,
    fontWeight: theme.size.fontWeight.regular,
    lineHeight: theme.size.lineHeight.S,
    letterSpacing: theme.size.letterSpacing.S,
    border: `1px solid ${theme.palette.border.primary}`,
    color: theme.palette.body.primary,
    borderRadius: 8,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
      borderColor: theme.palette.border.tertiary,
    },
    '&.Mui-focused': {
      borderColor: `${theme.palette.brand.primary} !important`,
      boxShadow: `${alpha(theme.palette.body.placeholder, 0.25)} 0 0 0 0.1rem`,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.backgroundQuaternary,
    },
    '& .MuiInputBase-input': {
      padding: 0,
      WebkitTextFillColor: 'unset !important',
      '&::placeholder': {
        color: theme.palette.body.placeholder,
        opacity: 1,
      },
    },
  },
}));

const StyledStaticDateRangePicker = styled(StaticDateRangePicker)(({theme}) => ({
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.backgroundPrimary,
  color: theme.palette.body.primary,

  '& .MuiDialogActions-root': {
    order: 1,
  },

  '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
    backgroundColor: theme.palette.brand.alpha2,
  },

  '& .MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval:hover':
    {
      borderColor: theme.palette.brand.primary,
    },

  '& .MuiDateRangePickerDay-day:not(.Mui-selected)': {
    borderColor: theme.palette.brand.primary,
  },

  '& .MuiDateRangePickerDay-day': {
    borderRadius: '8px',
    color: theme.palette.body.primary,

    '&.Mui-selected': {
      backgroundColor: theme.palette.brand.primary,
      color: theme.palette.body.tint,
    },
    '&.Mui-selected:focus, &.Mui-selected:hover': {
      backgroundColor: theme.palette.brand.secondary,
    },
  },

  '& .MuiPickersCalendarHeader-label': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },

  '& .MuiDayCalendar-weekDayLabel': {
    color: theme.palette.body.primary,
  },

  '& .MuiChip-root': {
    color: theme.palette.body.primary,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    marginRight: theme.spacing(1),
  },

  '& .MuiSvgIcon-root': {
    fill: theme.palette.body.primary,
  },
}));

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: t('component_date_range_this_week'),
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: t('component_date_range_last_week'),
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: t('component_date_range_last_days', {days: 7}),
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: t('component_date_range_current_month'),
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: t('component_date_range_next_month'),
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf('month').add(1, 'day');
      return [startOfNextMonth, startOfNextMonth.endOf('month')];
    },
  },
];

interface IProps {
  onChange?(range: DateRange<Dayjs>): void;
  onReset?(): void;
}

export interface IDateRangeDropdownRef {
  reset(): void;
}

const DateRangeDropdown = React.forwardRef<IDateRangeDropdownRef, IProps>((props, ref) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([null, null]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const isDateRangeSelected = dateRange.every((d) => d !== null);

  React.useImperativeHandle(ref, () => ({
    reset: () => {
      setDateRange([null, null]);
    },
  }));

  const handleOpenCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccept = () => {
    props.onChange?.(dateRange);
    handleClose();
  };

  const handleDateChange = (newValue: DateRange<Dayjs>) => {
    setDateRange(newValue);
  };

  const getButtonTitle = () => {
    if (dateRange[0] && dateRange[1]) {
      return `${dateRange[0].format('DD/MM/YYYY')} - ${dateRange[1].format('DD/MM/YYYY')}`;
    } else {
      return t('component_date_range_button');
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'calendar' : undefined;

  return (
    <>
      <Button
        variant={isDateRangeSelected ? 'contained' : 'outlined'}
        onClick={handleOpenCalendar}
        startIcon={<CalendarIcon style={{fill: theme.palette.body.primary}} />}
        sx={{marginLeft: theme.spacing(2)}}
      >
        {getButtonTitle()}
      </Button>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledMultiInputDateRangeField
            value={dateRange}
            onChange={handleDateChange}
            dateSeparator=""
            slotProps={{
              textField: {
                variant: 'outlined',
                label: null,
              },
            }}
          />
          <StyledStaticDateRangePicker
            value={dateRange}
            onChange={handleDateChange}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
                className: 'custom-scroll-x',
              },
              layout: {
                orientation: 'portrait',
              },
              actionBar: {
                onAccept: handleAccept,
                actions: ['clear', 'accept'],
              },
              toolbar: {
                hidden: true,
              },
            }}
            calendars={1}
          />
        </LocalizationProvider>
      </StyledPopover>
    </>
  );
});

export default DateRangeDropdown;
