import {TableCell} from '@mui/material';
import React from 'react';
import {ca2types} from '../../../api/proto';
import {formatTimestamp} from '../../../utils/format/format';
import SshKeyActionsDropdown from './SshKeyActionsDropdown';
import {SSH_KEYS_TABLE_COLUMNS, SshKeyColumnType} from './SshKeysTable';

interface IProps {
  sshKey: ca2types.ISSHKey;
}

function shortenSshKey(key: string, subStrLength = 35) {
  const start = key.slice(0, subStrLength);
  const end = key.slice(-subStrLength);
  return `${start} *** ${end}`;
}

export const SShKeyTableRow: React.FC<IProps> = ({sshKey}) => {
  const getTableCellComponent = (columnId: SshKeyColumnType) => {
    switch (columnId) {
      case SshKeyColumnType.KeyName:
        return sshKey.name;
      case SshKeyColumnType.KeyPreview:
        return sshKey.publicKey ? shortenSshKey(sshKey.publicKey) : '';
      case SshKeyColumnType.DateAdded:
        return sshKey.createdAt ? formatTimestamp(sshKey.createdAt.toNumber() * 1000, false) : '-';
    }
  };

  return (
    <React.Fragment>
      {SSH_KEYS_TABLE_COLUMNS.map((column) => (
        <TableCell
          align="left"
          key={column.id}
          sx={{
            width: column.width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {getTableCellComponent(column.id)}
        </TableCell>
      ))}
      <TableCell style={{width: 60, minWidth: 60, position: 'relative'}}>
        <SshKeyActionsDropdown sshKey={sshKey} />
      </TableCell>
    </React.Fragment>
  );
};

export default SShKeyTableRow;
