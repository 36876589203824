import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiUpdateSshKeyError} from '../api/getApiError';
import {ca2ssh} from '../api/proto';
import TextField from '../components/UI/TextField';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import extractNameFromSshKey from '../utils/extractNameFromSshKey';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalEditSshKey: React.FC<IProps> = observer((props) => {
  const {sshKeysStore, notification} = useStore();
  const {t} = useTranslation();

  const modalRef = React.useRef<ModalDialogRef>(null);

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: props.modal.data.sshKey?.name || '',
      publicKey: props.modal.data.sshKey?.publicKey || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t('modal_add_ssh_key_name_required')),
      publicKey: Yup.string().required(t('modal_add_ssh_key_public_key_required')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleClose = () => {
    modalRef.current?.close();
  };

  const handleSubmit = async ({name, publicKey}) => {
    if (!props.modal.data.sshKey?.id) {
      return;
    }

    setLoading(true);
    const {res, error} = await sshKeysStore.updateSshKey({name, publicKey, id: props.modal.data.sshKey.id});

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiUpdateSshKeyError(error);

          if (error === ca2ssh.UpdateSSHKeyError.USE_INVALID_PUBLIC_KEY_ERROR) {
            formik.setFieldError('publicKey', errorMessage);
          } else {
            notification.error(errorMessage);
          }
        });

        setLoading(false);
        return;
      }

      notification.success(t('modal_edit_ssh_key_success_notification'));

      handleClose();
      setLoading(false);
    }
  };

  const handleChangePublicKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyName = extractNameFromSshKey(e.target.value.trim());

    if (!formik.values.name.length && keyName) {
      formik.setFieldValue('name', keyName);
    }

    formik.setFieldValue('publicKey', e.target.value);
  };

  return (
    <ModalDialog maxWidth="sm" ref={modalRef} fullWidth modal={props.modal} headerText={t('modal_edit_ssh_key_title')}>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            placeholder={t('modal_add_ssh_key_name_placeholder')}
            label={t('modal_add_ssh_key_name_label')}
            fullWidth
          />
          <TextField
            name="publicKey"
            onChange={handleChangePublicKey}
            onBlur={formik.handleBlur}
            value={formik.values.publicKey}
            error={!!formik.errors.publicKey}
            helperText={formik.errors.publicKey}
            placeholder={t('modal_add_ssh_key_key_placeholder')}
            label={t('modal_add_ssh_key_key_label')}
            fullWidth
            multiline
            minRows={8}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          onClick={() => {
            formik.submitForm();
          }}
          loading={loading}
        >
          {t('action_button_save')}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalEditSshKey;
