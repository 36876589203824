import {ToggleButton as MuiToggleButton, ToggleButtonProps, styled} from '@mui/material';
import {PropsWithChildren} from 'react';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-xl.svg';

export const StyledToggleButton = styled(MuiToggleButton)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: 0,
  borderLeft: '1px',
  backgroundColor: 'transparent',
  color: theme.palette.body.primary,
  fontWeight: theme.size.fontWeight.regular,
  fontSize: theme.size.fontSize.S,
  lineHeight: theme.size.lineHeight.S,
  letterSpacing: '-0.32px',
  whiteSpace: 'nowrap',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

  '& .selected-icon': {
    display: 'none',
    marginLeft: theme.spacing(2),
    path: {
      fill: theme.palette.brand.primary,
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.brand.primary,
  },
  '&:focus': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.brand.primary,
    boxShadow: `${theme.palette.brand.alpha2} 0 0 0 3px`,
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    color: theme.palette.body.primary,
    borderColor: `${theme.palette.brand.primary} !important`,
    '& .selected-icon': {
      display: 'block',
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.body.placeholder,
    backgroundColor: theme.palette.backgroundSecondary,
    borderColor: 'transparent',
  },
}));

export const ToggleButton: React.FC<PropsWithChildren<ToggleButtonProps>> = ({children, ...rest}) => {
  return (
    <StyledToggleButton {...rest}>
      {children} <CheckIcon className="selected-icon" />
    </StyledToggleButton>
  );
};

export default ToggleButton;
