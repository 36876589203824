import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';
import {ca2types} from '../../api/proto';
import Div100vh from '../../components/Div100vh';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {useStore} from '../../stores/AppStore';
import ProfileForm from './ProfileForm';
import ProfilePageTabs from './ProfilePageTabs';
import SetOrChangePasswordForm from './SetOrChangePasswordForm';
import VerifyCurrentAndNewEmailForm from './VerifyCurrentAndNewEmailForm';

export enum ProfilePageTab {
  PROFILE,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
}

export const ProfilePage: React.FC = observer(() => {
  const {userStore, notification} = useStore();
  const [currentTab, setCurrentTab] = React.useState(ProfilePageTab.PROFILE);
  const [prevTab, setPrevTab] = React.useState(ProfilePageTab.CHANGE_PASSWORD);

  const [newEmail, setNewEmail] = React.useState<string | null>(null);
  const [codeParams, setCodeParams] = React.useState<ca2types.ICode | null>(null);

  const switchTab = (tab: ProfilePageTab) => {
    setPrevTab(currentTab);
    setCurrentTab(tab);
  };

  const handleClickBackButton = () => {
    switchTab(ProfilePageTab.PROFILE);
  };

  const transitions = useOrderTransition({
    currentStep: currentTab,
    prevStep: prevTab,
  });

  const switchToProfileTab = () => {
    switchTab(ProfilePageTab.PROFILE);
  };

  const switchToChangePasswordTab = () => {
    switchTab(ProfilePageTab.CHANGE_PASSWORD);
  };

  const handleSubmitUpdateEmail = async (newEmail: string) => {
    if (!userStore.email) {
      return;
    }

    setNewEmail(newEmail);

    const {error, res} = await userStore.updateEmail(userStore.email);

    if (error) {
      notification.error(error?.message);
      return;
    }

    if (res?.code) {
      setCodeParams(res.code);
      switchTab(ProfilePageTab.CHANGE_EMAIL);
    }
  };

  return (
    <Div100vh className="page page--profile">
      <ProfilePageTabs currentTab={currentTab} onClickBackButton={handleClickBackButton} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === ProfilePageTab.PROFILE ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <ProfileForm
                onSubmitUpdateEmail={handleSubmitUpdateEmail}
                onClickChangePassword={switchToChangePasswordTab}
              />
            </animated.div>
          ) : newEmail && codeParams && step === ProfilePageTab.CHANGE_EMAIL ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <VerifyCurrentAndNewEmailForm
                newEmail={newEmail}
                codeParams={codeParams}
                switchToProfileTab={switchToProfileTab}
              />
            </animated.div>
          ) : step === ProfilePageTab.CHANGE_PASSWORD ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <SetOrChangePasswordForm
                hasPassword={!!userStore.profile?.hasPassword}
                switchToProfileTab={switchToProfileTab}
              />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default ProfilePage;
