import {Box, InputLabel, SelectChangeEvent, styled} from '@mui/material';
import React from 'react';
import MenuItem from './MenuItem';
import Selector, {SelectorProps as BaseSelectorProps} from './Selector';
import Slider, {ISliderProps} from './Slider';

type SelectorProps = Omit<BaseSelectorProps, 'renderValue'> & {
  renderValue?(value: number): React.ReactNode;
};

export interface IRangeSliderProps extends Omit<ISliderProps, 'value' | 'defaultValue' | 'onChange'> {
  label?: string;
  valueIdx?: [number, number];
  dataList?: number[];
  leftSelectProps?: SelectorProps;
  rightSelectProps?: SelectorProps;
  renderListItemLabel?(value: number): string;
  onChange?(value: [number, number]): void;
}

const Row = styled(Box)(({theme}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(1),
}));

const RangeSlider: React.FC<IRangeSliderProps> = ({
  dataList = [],
  valueIdx: initialValueIdx,
  label,
  onChange,
  leftSelectProps = {},
  rightSelectProps = {},
  renderListItemLabel,
  ...rest
}) => {
  const minValueIdx = 0;
  const maxValueIdx = dataList?.length - 1 || 0;

  const [valueIdx, setValueIdx] = React.useState<[number, number]>([0, 0]);

  const startValueIdx = valueIdx?.[0] || 0;
  const endValueIdx = valueIdx?.[1] || 0;

  const [startRange, setStartRange] = React.useState<number>(startValueIdx);
  const [endRange, setEndRange] = React.useState<number>(endValueIdx);

  const handleChangeSlider = (e: Event, newValueIdx: number | number[]) => {
    if (Array.isArray(newValueIdx)) {
      setStartRange(newValueIdx[0]);
      setEndRange(newValueIdx[1]);
      setValueIdx(newValueIdx as [number, number]);

      onChange?.(newValueIdx as [number, number]);
    }
  };

  const formatSelectorValue = (value: unknown): number => {
    const val = Number(value);

    if (isNaN(val)) {
      throw new Error('value should be a number');
    }

    return val;
  };

  const handleChangeStartRange = (e: SelectChangeEvent<unknown>) => {
    const startIdx = formatSelectorValue(e.target.value);
    const endIdx = endValueIdx < startIdx ? maxValueIdx : endValueIdx;

    setValueIdx([startIdx, endIdx]);
    onChange?.([startIdx, endIdx]);
  };

  const handleChangeEndRange = (e: SelectChangeEvent<unknown>) => {
    const endIdx = formatSelectorValue(e.target.value);
    const startIdx = startValueIdx > endIdx ? 0 : startValueIdx;

    setValueIdx([startIdx, endIdx]);
    onChange?.([startIdx, endIdx]);
  };

  React.useEffect(() => {
    setStartRange(startValueIdx);
    setEndRange(endValueIdx);
  }, [startValueIdx, endValueIdx]);

  React.useEffect(() => {
    if (initialValueIdx) {
      setStartRange(initialValueIdx[0]);
      setEndRange(initialValueIdx[1]);
      setValueIdx(initialValueIdx);
    }
  }, [initialValueIdx]);

  const {renderValue: renderLeftValue, ...restLeftSelectProps} = leftSelectProps;
  const {renderValue: renderRightValue, ...restRightSelectProps} = rightSelectProps;

  const nativeRenderLeftValue = (valueIdx) => {
    return renderLeftValue?.(dataList[valueIdx]) || renderListItemLabel?.(dataList[valueIdx]) || dataList[valueIdx];
  };

  const nativeRenderRightValue = (valueIdx) => {
    return renderRightValue?.(dataList[valueIdx]) || renderListItemLabel?.(dataList[valueIdx]) || dataList[valueIdx];
  };

  return (
    <Box sx={{lineHeight: 0}}>
      <InputLabel shrink>{label}</InputLabel>
      <Row>
        <Selector
          {...restLeftSelectProps}
          renderValue={nativeRenderLeftValue}
          value={startRange}
          onChange={handleChangeStartRange}
        >
          {dataList.map((value, valueIdx) => (
            <MenuItem key={value} value={valueIdx}>
              {renderListItemLabel?.(value) || value}
            </MenuItem>
          ))}
        </Selector>
        <Selector
          {...restRightSelectProps}
          renderValue={nativeRenderRightValue}
          value={endRange}
          onChange={handleChangeEndRange}
        >
          {dataList.map((value, valueIdx) => (
            <MenuItem key={value} value={valueIdx}>
              {renderListItemLabel?.(value) || value}
            </MenuItem>
          ))}
        </Selector>
      </Row>
      <Slider {...rest} value={valueIdx} onChange={handleChangeSlider} marks min={minValueIdx} max={maxValueIdx} />
    </Box>
  );
};

export default RangeSlider;
