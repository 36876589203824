import React, {useEffect, useState} from 'react';

interface IProps {
  differenceInSeconds: number;
  onTimerEnd?(): void;
}

const CountdownTimer: React.FC<IProps> = ({differenceInSeconds, onTimerEnd}) => {
  const [remainingTime, setRemainingTime] = useState(differenceInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        const newTime = prevTime - 1;
        if (newTime <= 0) {
          clearInterval(interval);
          onTimerEnd?.();
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onTimerEnd]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return <>{formatTime(remainingTime)}</>;
};

export default CountdownTimer;
