import {Box, Button, styled} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '../../../components/UI/Typography';

interface IProps {
  filterUsed?: boolean;
  onResetFilter?(): void;
}

const Container = styled(Box)(({theme}) => ({
  height: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: 360,
  margin: '0 auto',

  '& .MuiTypography-root': {
    marginBottom: theme.spacing(2),
  },
}));

const NoBalanceOperationsFound: React.FC<IProps> = ({filterUsed, onResetFilter}) => {
  const {t} = useTranslation();

  return (
    <Container>
      {filterUsed ? (
        <>
          <Typography variant="h4">{t('billing_balance_operation_no_operations_found_title')}</Typography>
          <Typography variant="body2" color="body.secondary">
            {t('billing_balance_operation_no_operations_found_description')}
          </Typography>
          <Button variant="outlined" onClick={onResetFilter} size="large">
            {t('billing_balance_operation_no_operations_found_reset_filters_button')}
          </Button>
        </>
      ) : (
        <Typography variant="h4">{t('billing_balance_operation_no_operations_title')}</Typography>
      )}
    </Container>
  );
};

export default NoBalanceOperationsFound;
