import Long from 'long';
import {ca2types} from './api/proto';

export const APP_NAME = 'CrypAdvise';

export const SUPPORT_EMAIL = 'info@crypadvise.com';

export const PASSWORD_MIN_LENGTH = 2;
export const SSH_KEY_MAX_LENGTH = 4096;
export const SERVER_NAME_MAX_LENGTH = 63;

export const CURRENCY_FRACTION_MAX_DIGITS = 5;
export const CURRENCY_FRACTION_MIN_DIGITS = 2;

export const PLAN_MONTHLY_PRICE_MIN = 1;
export const PLAN_MONTHLY_PRICE_MAX = 1500;

export const PLAN_MONTHLY_PRICE_FRACTION_DIGITS = 2;

export const CURRENCY_SIGN = '$';

export const PAGINATION_PAGE_SIZE = 50;
export const PAGINATION_DEFAULT_PAGE = 1;

export const ZERO_AMOUNT: ca2types.IAmount = {
  points: Long.fromNumber(0),
  formatted: '$0',
};
