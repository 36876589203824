import {Button} from '@mui/material';
import usePagination from '@mui/material/usePagination';
import React from 'react';

import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeft} from '../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../assets/icons/arrow-right.svg';
import {PAGINATION_DEFAULT_PAGE} from '../constants';
import Input from './UI/Input';
import Typography from './UI/Typography';

interface IProps {
  onChange?: (page: number) => void;
  onGoToPage?: (page: number) => void;
  page?: number;
  pagesCount?: number;
}

const Pagination: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const [goToPage, setGotToPage] = React.useState<number | null>(null);

  const {items} = usePagination({
    page: props.page,
    count: props.pagesCount,
    defaultPage: PAGINATION_DEFAULT_PAGE,
    onChange: (_, page) => props.onChange?.(page),
    boundaryCount: 1,
    showFirstButton: true,
    showLastButton: true,
  });

  const sortedItems = React.useMemo(() => {
    const previousItem = items.find((item) => item.type === 'previous');
    const nextItem = items.find((item) => item.type === 'next');

    const filteredItems = items.filter((item) => item.type !== 'previous' && item.type !== 'next');

    if (previousItem) {
      filteredItems.unshift(previousItem);
    }
    if (nextItem) {
      filteredItems.push(nextItem);
    }

    return filteredItems;
  }, [items]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;

    if (typeof val === 'string') {
      val = val.replace(/[^0-9.]/g, '');
    }

    let numerical = Number(val);

    if (isNaN(numerical)) {
      return;
    }

    if (props.pagesCount && numerical > props.pagesCount) {
      numerical = props.pagesCount;
    }

    setGotToPage(numerical);
  };

  const handleGoToPage = () => {
    goToPage && props.onGoToPage?.(goToPage);
  };

  return (
    <div className="pagination">
      <div className="pagination__pages">
        {sortedItems.map(({page, type, selected, ...item}, index) => {
          let toRender;

          if ((type === 'page' && selected) || type === 'last') {
            toRender = selected ? (
              <>
                <div className="pagination__page" {...item}>
                  {page}
                </div>
                <span className="pagination__page-delimiter">/</span>
              </>
            ) : (
              <div className="pagination__page" {...item}>
                {page}
              </div>
            );
          } else if (type === 'previous' || type === 'next') {
            toRender = (
              <Button
                className="pagination__button pagination__button--prev-next"
                variant="outlined"
                size="large"
                {...item}
              >
                {type === 'next' ? <ArrowRight /> : <ArrowLeft />}
              </Button>
            );
          }

          return <React.Fragment key={index}>{toRender}</React.Fragment>;
        })}
      </div>

      <div className="pagination__to-page">
        <div className="pagination__to-page-label">
          <Typography variant="body1">{t('component_pagination_go_to_label')}</Typography>
        </div>
        <Input
          className="pagination__to-page-input"
          value={goToPage || ''}
          placeholder={t('component_pagination_enter_page_label')}
          onChange={handleChange}
        />
        <Button
          className="pagination__button pagination__to-page-button"
          variant="outlined"
          size="large"
          onClick={handleGoToPage}
        >
          <ArrowRight />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
