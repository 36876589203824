import LoadingButton from '@mui/lab/LoadingButton';
import {Box, styled, Typography, useTheme} from '@mui/material';
import validator from 'email-validator';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getApiAuthStartError} from '../../api/getApiError';
import {ca2auth} from '../../api/proto';
import {TextField} from '../../components/UI/TextField';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import GoogleAuthButton from './GoogleAuthButton';

const OrLabelBox = styled(Box)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  userSelect: 'none',
  margin: `${theme.spacing(4)} 0`,
  '&:before, &:after': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.border.primary,
    top: '50%',
  },
}));

interface IProps {
  onSubmit?(switchToEnterPasswordStep?: boolean): void;
}

type LocationState = {
  email?: string;
};

const EmailVerificationForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const location = useLocation();
  const state = location.state as LocationState;

  const navigate = useNavigate();
  const {authStore, notification} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: state?.email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t('auth_email_verification_form_email_required'))
        .test('is_email', t('auth_email_verification_form_email_incorrect'), validator.validate),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {error, res} = await authStore.emailVerification({email: values.email});

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors, enterCodeStep, enterPasswordStep} = res;

        if (errors?.some((e) => e === ca2auth.StartError.SE_USER_IS_BLOCKED_ERROR)) {
          navigate(Paths.Blocked);
          return;
        }

        if (errors?.length) {
          errors.forEach((key) => {
            notification.error(getApiAuthStartError(key));
          });

          setLoading(false);
          return;
        }

        if (enterCodeStep) {
          navigate(Paths.Authorization, {state: {email: values.email, codeParams: enterCodeStep.code}, replace: true});
          props.onSubmit?.();
          return;
        }

        if (enterPasswordStep) {
          navigate(Paths.Authorization, {state: {email: values.email}, replace: true});
          props.onSubmit?.(true);
          return;
        }
      }

      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_email_verification_form_title')}
      </Typography>
      <Typography variant="h3" sx={{marginBottom: theme.spacing(6), color: theme.palette.body.tertiary}}>
        {t('auth_email_verification_form_subtitle')}
      </Typography>

      <GoogleAuthButton />

      <OrLabelBox>
        <Typography
          variant="body1"
          color="body.placeholder"
          sx={{
            backgroundColor: theme.palette.backgroundPrimary,
            zIndex: 1,
            padding: `0 ${theme.spacing(4)}`,
          }}
        >
          {t('auth_google_verification_or_label')}
        </Typography>
      </OrLabelBox>

      <TextField
        fullWidth
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        placeholder={t('auth_email_verification_form_email_placeholder')}
        label={t('auth_email_verification_form_email_label')}
        error={!!formik.errors.email}
        helperText={formik.errors.email}
        autoComplete="email"
        autoFocus
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_email_verification_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default EmailVerificationForm;
