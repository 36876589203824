import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../../stores/AppStore';

const LogoutPage: React.FC = observer(() => {
  const {authStore} = useStore();

  React.useEffect(() => {
    authStore.logout();
  }, [authStore]);

  return <React.Fragment />;
});

export default LogoutPage;
