import {useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Typography from '../UI/Typography';

interface IProps {
  orderId?: string | null;
}

export const OrderIdTitle: React.FC<IProps> = ({orderId}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  if (!orderId) {
    return null;
  }

  return (
    <Typography
      variant="label3"
      sx={{marginTop: theme.spacing(4), display: 'block', color: theme.palette.body.placeholder}}
    >
      {t('component_payment_viewer_order_id', {id: orderId})}
    </Typography>
  );
};

export default OrderIdTitle;
