import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types, ca2users} from '../../api/proto';
import {formatDateTimeTimestamp} from '../../utils/format/format';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';

export class UserStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @observable profile: ca2types.IUser | null = null;

  @computed get lastPasswordChangeAtFormatted(): string {
    const timestamp = this.profile?.lastPasswordChangeAt?.toNumber();

    if (!timestamp) {
      return '';
    }

    return formatDateTimeTimestamp(timestamp * 1000);
  }

  @computed get fullName(): string {
    return this.profile?.fullName || '';
  }

  @computed get email() {
    return this.profile?.email;
  }

  @computed get displayName(): string {
    return this?.fullName || this.profile?.email || '';
  }

  @action setProfile = (profile: ca2types.IUser | null) => {
    this.profile = profile;
  };

  @action reset = () => {
    this.profile = null;
  };

  savePersonalInfoOnRegistration = async ({
    password,
    passwordConfirmation,
    fullName,
  }: {
    password: string;
    passwordConfirmation: string;
    fullName: string;
  }) => {
    const {res, error} = await this.request({
      users: {
        updatePassword: {
          newPassword: password,
          newPasswordConfirmation: passwordConfirmation,
        },
        updateProfile: {
          newFullName: fullName,
        },
      },
    });

    if (res?.users?.updateProfile) {
      this.processPersonalInfoOnRegistration_(res.users?.updateProfile);
    }

    return {error, res: res?.users?.updateProfile};
  };

  @action private processPersonalInfoOnRegistration_ = (res: ca2users.IProfileResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };

  init = async () => {
    await this.loadUserProfile_();
  };

  createNewPassword = async (updatePassword: ca2users.IUpdatePasswordRequest) => {
    const {res, error} = await this.request({
      users: {
        updatePassword,
      },
    });

    if (res?.users?.updatePassword) {
      this.createNewPasswordProcess_(res?.users?.updatePassword);
    }

    return {error, res: res?.users?.updatePassword};
  };

  @action private createNewPasswordProcess_ = (res: ca2users.IUpdatePasswordResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };

  private loadUserProfile_ = async () => {
    const {error, res} = await this.request({users: {profile: {}}});

    if (res?.users?.profile) {
      this.userProfileProcess_(res.users?.profile);
    }

    return {error, res: res?.users?.profile};
  };

  @action private userProfileProcess_ = (res: ca2users.IProfileResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };

  updateFullName = async (newFullName: string) => {
    const {res, error} = await this.request({
      users: {
        updateProfile: {
          newFullName,
        },
      },
    });

    if (res?.users?.updateProfile) {
      this.updateFullNameProcess_(res.users.updateProfile);
    }

    return {error, res: res?.users?.updateProfile};
  };

  @action private updateFullNameProcess_ = (res: ca2users.IUpdatePasswordResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };

  updateEmail = async (newEmail: string) => {
    const {res, error} = await this.request({
      users: {
        updateEmail: {
          newEmail,
        },
      },
    });

    return {error, res: res?.users?.updateEmail};
  };

  updateEmailConfirmation = async (code: string) => {
    const {res, error} = await this.request({
      users: {
        updateEmailConfirmation: {
          code,
        },
      },
    });

    if (res?.users?.updateEmailConfirmation) {
      this.updateEmailConfirmationProcess_(res.users.updateEmailConfirmation);
    }

    return {error, res: res?.users?.updateEmailConfirmation};
  };

  private updateEmailConfirmationProcess_ = (res: ca2users.IUpdateEmailConfirmationResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };

  updatePassword = async ({
    oldPassword,
    newPassword,
    newPasswordConfirmation,
  }: {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }) => {
    const {res, error} = await this.request({
      users: {
        updatePassword: {
          oldPassword,
          newPassword,
          newPasswordConfirmation,
        },
      },
    });

    if (res?.users?.updatePassword) {
      this.updatePasswordProcess_(res.users?.updatePassword);
    }

    return {error, res: res?.users?.updatePassword};
  };

  @action private updatePasswordProcess_ = (res: ca2users.IUpdatePasswordResponse) => {
    if (res.user) {
      this.setProfile(res.user);
    }
  };
}

export default UserStore;
