import {Box, Button, Typography, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';

import {LoadingButton} from '@mui/lab';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2billing} from '../../../api/proto';
import {ReactComponent as RefreshIcon} from '../../../assets/icons/refresh.svg';
import PriceFormatter from '../../../utils/priceFormatter';
import CurrencyIcon from '../../CurrencyIcon';
import QRCode from '../../QRCode';
import CopyableTextField from '../../UI/CopyableTextField';
import OrderIdTitle from '../OrderIdTitle';
import PaymentRateTimer from './PaymentRateTimer';

const BorderBox = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

interface IProps {
  topUpBalanceResponse: ca2billing.ITopUpBalanceResponse;
  onRefreshRate(): Promise<void>;
  onCancelPaymentOrder(): void;
  onSwitchToAwaitingPayment(): void;
}

export const PaymentDetailsForm: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const [shouldRefreshRate, setShouldRefreshRate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const amountFormatter = new PriceFormatter(props.topUpBalanceResponse.amount);

  const handleTimerEnd = () => {
    setShouldRefreshRate(true);
  };

  const handleRefreshRate = async () => {
    setLoading(true);
    await props.onRefreshRate();

    setLoading(false);
    setShouldRefreshRate(false);
  };

  const handleMarkAsPaid = () => {
    props.onSwitchToAwaitingPayment();
  };

  if (shouldRefreshRate) {
    return (
      <>
        <BorderBox>
          <Typography variant="h4" gutterBottom>
            {t('component_payment_viewer_exchange_rate_expired')}
          </Typography>
          <Typography variant="body3" sx={{marginBottom: theme.spacing(4)}}>
            {t('component_payment_viewer_refresh_rate_to_continue')}
          </Typography>

          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            size="large"
            startIcon={<RefreshIcon />}
            onClick={handleRefreshRate}
          >
            {t('component_payment_viewer_refresh_rate_button')}
          </LoadingButton>
        </BorderBox>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          sx={{marginBottom: theme.spacing(2)}}
          onClick={handleMarkAsPaid}
        >
          {t('component_payment_viewer_mark_as_paid_with_old_rate_button')}
        </Button>
        <Button fullWidth variant="outlined" size="large" onClick={props.onCancelPaymentOrder}>
          {t('component_payment_viewer_cancel_order_button')}
        </Button>

        <OrderIdTitle orderId={props.topUpBalanceResponse.foreignId} />
      </>
    );
  }

  return (
    <>
      {props.topUpBalanceResponse.validUntil ? (
        <PaymentRateTimer
          validUntilTimestamp={props.topUpBalanceResponse.validUntil.toNumber()}
          onTimerEnd={handleTimerEnd}
        />
      ) : null}

      {props.topUpBalanceResponse.address ? (
        <BorderBox sx={{textAlign: 'center'}}>
          <QRCode value={props.topUpBalanceResponse.address} />
        </BorderBox>
      ) : null}

      <CopyableTextField
        fullWidth
        value={props.topUpBalanceResponse.amountToPay}
        label={t('component_payment_viewer_sent_this_amount_label')}
        formControlProps={{sx: {marginBottom: 0}}}
        startAdornment={
          props.topUpBalanceResponse.currencyCode ? (
            <CurrencyIcon style={{marginRight: theme.spacing(2)}} code={props.topUpBalanceResponse.currencyCode} />
          ) : null
        }
      />

      <Typography variant="body3" sx={{marginBottom: theme.spacing(4)}}>
        = {amountFormatter.formatted}
      </Typography>

      <CopyableTextField
        fullWidth
        value={props.topUpBalanceResponse.address}
        label={t('component_payment_viewer_to_address_label')}
      />

      <Button
        fullWidth
        variant="contained"
        size="large"
        sx={{marginBottom: theme.spacing(2)}}
        onClick={handleMarkAsPaid}
      >
        {t('component_payment_viewer_mark_as_paid_button')}
      </Button>

      <Button fullWidth variant="outlined" size="large" onClick={props.onCancelPaymentOrder}>
        {t('component_payment_viewer_cancel_order_button')}
      </Button>

      <OrderIdTitle orderId={props.topUpBalanceResponse.foreignId} />
    </>
  );
});

export default PaymentDetailsForm;
