import {useTheme} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {ca2types} from '../api/proto';
import {ReactComponent as ArrowRightIcon} from '../assets/icons/arrow-right.svg';
import Typography from './UI/Typography';

interface IProps {
  sshKey: ca2types.ISSHKey;
  selected?: boolean;
  onClick?(sshKey: ca2types.ISSHKey): void;
}

export const SshKeyItem: React.FC<IProps> = ({sshKey, selected, onClick}) => {
  const theme = useTheme();

  const handleClick = () => {
    if (!selected) {
      onClick?.(sshKey);
    }
  };

  return (
    <div className={cn('ssh-key-item', {selected})} onClick={handleClick}>
      <div className="ssh-key-item__body">
        <Typography variant="body2" gutterBottom>
          {sshKey.name}
        </Typography>
        <Typography variant="body3" className="ssh-key-item__key">
          {sshKey.publicKey}
        </Typography>
      </div>
      <div className="ssh-key-item__icon">
        <ArrowRightIcon style={{fill: theme.palette.body.placeholder}} />
      </div>
    </div>
  );
};

export default SshKeyItem;
