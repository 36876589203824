import {Box, Button, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2domains} from '../../../api/proto';
import {ReactComponent as CheckRoundIcon} from '../../../assets/icons/check-fill-round-xxl.svg';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import PageContentLayout from '../../../layouts/PageContentLayout';
import {navigateToDomainInfo} from '../../../routes/navigateTo';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import {formatTimestamp} from '../../../utils/format/format';

interface IProps {
  domainSuggestion: DomainSuggestion;
  registerDomainResponse: ca2domains.IRegisterDomainResponse;
  onCancelPaymentOrder?(): void;
}

export const RegisteredDomainConfirmation: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {domainsStore} = useStore();

  const domain = props.registerDomainResponse.domain?.id
    ? domainsStore.findById(props.registerDomainResponse.domain.id.toNumber())
    : null;

  const handleOpenDomainPage = () => {
    domain && navigateToDomainInfo(domain);
  };

  return (
    <PageContentLayout
      sx={{
        margin: '0 auto',
        padding: theme.spacing(4),
        maxWidth: 500,
      }}
    >
      <Box sx={{marginBottom: theme.spacing(4)}}>
        <CheckRoundIcon />
      </Box>

      <Box sx={{marginBottom: theme.spacing(4)}}>
        <Typography variant="h4" gutterBottom>
          {t('domains_order_confirmation_domain_has_been_successfully_registered', {
            domain: domain?.name,
          })}
        </Typography>
        <Typography variant="body1">
          {t('domains_order_confirmation_amount_has_been_charged', {
            amount: props.domainSuggestion.registrationPriceFormatter.formatted,
          })}
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: theme.spacing(4),
          padding: theme.spacing(4),
          border: `1px solid ${theme.palette.border.primary}`,
          borderRadius: '12px',
        }}
      >
        <Typography component="div" variant="label3" color="body.tertiary" gutterBottom>
          {t('domains_order_confirmation_domain_info_title')}
        </Typography>

        <Typography variant="body3" color="body.placeholder">
          {t('domains_order_confirmation_domain_info_name_label')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {domain?.name}
        </Typography>

        <Typography variant="body3" color="body.placeholder">
          {t('domains_order_confirmation_domain_info_registration_period_label')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('domains_order_confirmation_domain_info_registration_period', {amount: domain?.termYears})}
        </Typography>

        {domain?.createdAt ? (
          <>
            <Typography variant="body3" color="body.placeholder">
              {t('domains_order_confirmation_domain_info_registration_date_label')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatTimestamp(domain.createdAt.toNumber() * 1000, false)}
            </Typography>
          </>
        ) : null}

        {domain?.expiresAt ? (
          <>
            <Typography variant="body3" color="body.placeholder">
              {t('domains_order_confirmation_domain_info_expiration_date_label')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatTimestamp(domain.expiresAt.toNumber() * 1000, false)}
            </Typography>
          </>
        ) : null}
      </Box>

      <Button
        variant="contained"
        fullWidth
        size="large"
        onClick={handleOpenDomainPage}
        sx={{marginBottom: theme.spacing(2)}}
      >
        {t('domains_order_confirmation_domain_settings_button')}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        size="large"
        startIcon={<PlusIcon style={{fill: theme.palette.body.primary}} />}
        onClick={props.onCancelPaymentOrder}
      >
        {t('domains_order_confirmation_buy_additional_domain_button')}
      </Button>
    </PageContentLayout>
  );
});

export default RegisteredDomainConfirmation;
