import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';
import {DomainsSearch} from './DomainsSearch';

export class DomainSuggestion {
  @observable id: string | null = null;
  @observable name: string | null = null;
  @observable isReady: boolean = false;
  @observable isAvailable: boolean = false;
  @observable isPremium: boolean = false;
  @observable private oldRegistrationPrice: ca2types.IAmount | null = null;
  @observable private registrationPrice: ca2types.IAmount | null = null;
  @observable private renewalPrice: ca2types.IAmount | null = null;
  @observable whoisUrl: string | null = null;
  @observable siteUrl: string | null = null;

  @observable oldRegistrationPriceFormatter: PriceFormatter;
  @observable registrationPriceFormatter: PriceFormatter;
  @observable renewalPriceFormatter: PriceFormatter;

  constructor(public raw: ca2types.IDomainSuggestion, public domainsSearch?: DomainsSearch) {
    makeObservable(this);

    this.update(raw);

    this.oldRegistrationPriceFormatter = new PriceFormatter(raw.oldRegistrationPrice);
    this.registrationPriceFormatter = new PriceFormatter(raw.registrationPrice);
    this.renewalPriceFormatter = new PriceFormatter(raw.renewalPrice);
  }

  @computed private get tldName_() {
    return this.name?.split('.').pop();
  }

  @computed get domainTld() {
    return this.domainsSearch?.tlds.find(({name}) => name === this.tldName_);
  }

  @computed get termYears(): number[] {
    const maxYears = this.domainTld?.maxRegisterYears || 1;
    const minYears = this.domainTld?.minRegisterYears || 1;
    return Array.from({length: maxYears - minYears + 1}, (_, i) => minYears + i);
  }

  @computed get existWithSearchText() {
    return this.domainsSearch?.searchText === this.name;
  }

  @computed get hasBalanceToOrderDomain(): boolean {
    return this.registrationPriceFormatter.isLessOrEqualPoints(
      this.domainsSearch?.domainsStore.app.billingStore.balanceFormatter.points || 0,
    );
  }

  @action update = (raw: ca2types.IDomainSuggestion) => {
    Object.assign(this, raw);

    this.oldRegistrationPriceFormatter = new PriceFormatter(raw.oldRegistrationPrice);
    this.registrationPriceFormatter = new PriceFormatter(raw.registrationPrice);
    this.renewalPriceFormatter = new PriceFormatter(raw.renewalPrice);
  };
}

export default DomainSuggestion;
