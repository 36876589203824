import {Tab, Tabs, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ServerInfoTab} from './ServerInfoViewer';

interface IProps {
  currentTab: ServerInfoTab;
  onChange?(tab: ServerInfoTab);
}

const ServerInfoTabs: React.FC<IProps> = ({currentTab, onChange}) => {
  const {t} = useTranslation();

  const tabsData = Object.entries({
    [ServerInfoTab.OVERVIEW]: t('server_info_overview_tab'),
    // [ServerInfoTab.USAGE]: t('server_info_usage_tab'),
    [ServerInfoTab.ACCESSES]: t('server_info_accesses_tab'),
  });

  const handleChange = (_: React.SyntheticEvent, tab: ServerInfoTab) => {
    tab && onChange?.(tab);
  };

  return (
    <Tabs className="lineView" value={currentTab} onChange={handleChange}>
      {tabsData.map(([tab, title]) => (
        <Tab className="lineView" key={tab} label={<Typography variant="subtitle1">{title}</Typography>} value={tab} />
      ))}
    </Tabs>
  );
};

export default ServerInfoTabs;
