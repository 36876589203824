import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(advancedFormat);
dayjs.extend(duration);

export function formatTimestamp(timestamp: number, showTime: boolean = true): string {
  if (showTime) {
    return dayjs(timestamp).format('HH:mm, MMMM D, YYYY');
  }

  return dayjs(timestamp).format('DD.MM.YYYY');
}

export function formatDateTimeTimestamp(timestamp: number): string {
  return dayjs(timestamp).format('DD.MM.YYYY, HH:mm:ss');
}

export function currentTimestamp(): number {
  return Date.now();
}

export function calculateSecondsDifference(futureInSeconds: number): number {
  const currentInSeconds = currentTimestamp() / 1000;
  const differenceInSeconds = futureInSeconds - currentInSeconds;

  if (differenceInSeconds < 0) {
    console.error('Future time cannot be earlier than current time');
  }

  return differenceInSeconds;
}

export function formatDuration(seconds: number) {
  const durationObj = dayjs.duration(seconds, 'seconds');

  const units = [
    {label: 'year', value: Math.floor(durationObj.asYears())},
    {label: 'month', value: Math.floor(durationObj.subtract(Math.floor(durationObj.asYears()), 'years').asMonths())},
    {label: 'week', value: Math.floor(durationObj.subtract(Math.floor(durationObj.asMonths()), 'months').asWeeks())},
    {label: 'day', value: Math.floor(durationObj.subtract(Math.floor(durationObj.asWeeks()), 'weeks').asDays())},
    {label: 'hour', value: Math.floor(durationObj.subtract(Math.floor(durationObj.asDays()), 'days').asHours())},
    {label: 'minute', value: Math.floor(durationObj.subtract(Math.floor(durationObj.asHours()), 'hours').asMinutes())},
    {
      label: 'second',
      value: Math.floor(durationObj.subtract(Math.floor(durationObj.asMinutes()), 'minutes').asSeconds()),
    },
  ];

  const parts = units
    .filter((unit) => unit.value > 0)
    .map((unit) => unit.value + ' ' + unit.label + (unit.value > 1 ? 's' : ''));

  const timeParts = [
    units
      .find((unit) => unit.label === 'hour')
      ?.value.toString()
      .padStart(2, '0'),
    units
      .find((unit) => unit.label === 'minute')
      ?.value.toString()
      .padStart(2, '0'),
    units
      .find((unit) => unit.label === 'second')
      ?.value.toString()
      .padStart(2, '0'),
  ].join(':');

  return [...parts.slice(0, -3), timeParts].join(', ');
}
