import {Box, Button, Grid, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning-orange.svg';
import {Switch} from '../../components/UI/Switch';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import {Domain} from '../../stores/Domain';
import {ModalType} from '../../stores/ModalsStore';
import {formatTimestamp} from '../../utils/format/format';

interface IProps {
  domain: Domain;
}

const StyledBox = styled(Box)(({theme}) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.backgroundSecondary,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const DomainOverview: React.FC<IProps> = observer(({domain}) => {
  const {modals} = useStore();
  const {t} = useTranslation();
  const theme = useTheme();

  const handleToggleRenewal = () => {
    if (domain.autoRenew) {
      modals.open(ModalType.CONFIRM, {
        maxWidth: 'sm',
        headerText: t('modal_confirm_disable_domain_auto_renewal_header_text'),
        title: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_title')}</Typography>,
        submitButtonText: t('modal_confirm_disable_domain_auto_renewal_submit'),
        submitButtonColor: 'primary',
        content: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_text')}</Typography>,
        callbacks: {
          submit: domain.toggleAutoRenew,
        },
      });
    } else {
      domain.toggleAutoRenew();
    }
  };

  return (
    <PageContentLayout sx={{padding: theme.spacing(4)}}>
      {domain.daysUntilExpirationLessThanMonth ? (
        <Box
          sx={{
            display: 'flex',
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: '8px',
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
          }}
        >
          <WarningIcon />
          <Box sx={{marginLeft: theme.spacing(1)}}>
            <Typography variant="subtitle1">{t('domain_overview_expiring_warning_message_title')}</Typography>
            <Typography variant="body2" sx={{marginBottom: theme.spacing(2), color: theme.palette.body.secondary}}>
              {t('domain_overview_expiring_warning_message_test')}
            </Typography>
            <Button variant="contained">{t('domain_overview_expiring_warning_message_button')}</Button>
          </Box>
        </Box>
      ) : null}

      <Grid container spacing={{xs: 2}}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledBox>
            <Typography variant="body3" color="body.tertiary">
              {t('domain_overview_name_label')}
            </Typography>
            <Typography variant="h4">{domain.name}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledBox>
            <Typography variant="body3" color="body.tertiary">
              {t('domain_overview_registration_date_label')}
            </Typography>
            <Typography>{formatTimestamp(domain.createdAtTimestamp, false)}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledBox>
            <Typography variant="body3" color="body.tertiary">
              {t('domain_overview_expiration_date_label')}
            </Typography>
            <Typography>{formatTimestamp(domain.createdAtTimestamp, false)}</Typography>
          </StyledBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
              <Typography variant="body3" color="body.tertiary">
                {t('domain_overview_auto_renew_label')}
              </Typography>
              <Typography>
                {domain.autoRenew
                  ? domain.daysUntilExpirationLessThanMonth
                    ? t('domain_overview_auto_renew_days_left', {days: domain.daysUntilExpirationLessThanMonth})
                    : t('domain_overview_auto_renew_enabled')
                  : t('domain_overview_auto_renew_disabled')}
              </Typography>
            </Box>
            <div>
              <Switch checked={!!domain.autoRenew} onChange={handleToggleRenewal} />
            </div>
          </StyledBox>
        </Grid>
      </Grid>
    </PageContentLayout>
  );
});

export default DomainOverview;
