import {ThemeProvider} from '@mui/material/styles';
import {observer} from 'mobx-react';
import React, {PropsWithChildren} from 'react';
import {useStore} from '../stores/AppStore';
import {getTheme} from './theme';

export const AppTheme: React.FC<PropsWithChildren> = observer(({children}) => {
  const {lightThemeChosen} = useStore();

  const theme = React.useMemo(() => getTheme(lightThemeChosen), [lightThemeChosen]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});

export default AppTheme;
