import {Box, Button, styled} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import SearchInput from '../../components/UI/SearchInput';

const Container = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.backgroundPrimary,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
}));

interface IProps {
  searchText?: string;
  onSearch?(searchText: string): void;
  onClickAddSshKey?(): void;
}

export const SshKeysPageHead: React.FC<IProps> = (props) => {
  const {t} = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearch?.(event.target.value);
  };

  const handleClear = () => {
    props.onSearch?.('');
  };

  return (
    <Container>
      <SearchInput
        value={props.searchText}
        onChange={handleChange}
        onClear={handleClear}
        sx={{maxWidth: 410}}
        placeholder={t('ssh_keys_search_input_placeholder')}
      />

      <Button
        startIcon={<PlusIcon style={{fill: 'currentcolor'}} />}
        variant="contained"
        onClick={props.onClickAddSshKey}
        size="large"
      >
        {t('ssh_keys_no_keys_added_add_button')}
      </Button>
    </Container>
  );
};

export default SshKeysPageHead;
