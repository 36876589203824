import {Box, styled, Typography} from '@mui/material';
import Button from '@mui/material/Button/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow-left.svg';
import {RegisterDomainStep} from './RegisterDomain';

interface IProps {
  currentStep: RegisterDomainStep;
  onBack?(): void;
}

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(3)}`,
}));

const RegisterDomainTopBar: React.FC<IProps> = (props) => {
  const {t} = useTranslation();

  const titlesData = Object.entries({
    [RegisterDomainStep.REGISTER_FORM]: t('domains_register_form_step'),
    [RegisterDomainStep.PAYMENT]: t('domains_register_payment_step'),
    [RegisterDomainStep.CONFIRMATION]: t('domains_register_confirmation_step'),
  });

  return (
    <div className="register-domain-top-bar">
      <div className="register-domain-top-bar__back">
        <Button
          className="register-domain-top-bar__back-btn"
          variant="outlined"
          size="large"
          onClick={props.onBack}
          sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
        >
          <ArrowLeftIcon width={24} height={24} />
        </Button>
      </div>
      <div className="register-domain-top-bar__title-wrap">
        <Typography variant="body3" color="body.tertiary">
          {t('domains_register_title')}
        </Typography>
        <div className="register-domain-top-bar__title-bottom">
          {titlesData.map(([step, title], idx) => (
            <React.Fragment key={step}>
              <Typography
                variant="h4"
                component="span"
                sx={(theme) => ({
                  color: props.currentStep === +step ? theme.palette.body.primary : theme.palette.body.tertiary,
                })}
                color={props.currentStep === +step ? 'body.primary' : 'body.tertiary'}
              >
                {title}
              </Typography>
              {idx !== titlesData.length - 1 ? <Delimiter component="span">/</Delimiter> : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegisterDomainTopBar;
