import {Box, styled, Typography} from '@mui/material';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../../stores/AppStore';
import DomainSuggestion from '../../../../stores/Domain/DomainSuggestion';
import DomainSuggestionItem from './DomainSuggestionItem';

const Container = styled(Box)(({theme}) => ({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: theme.spacing(4),
  '&.empty-list': {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  onRegisterDomain?(suggestion: DomainSuggestion): void;
}

export const DomainSuggestions: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {domainsStore, domainsView} = useStore();

  const isNoItemsFound = !domainsStore.search.domainSuggestions.length;

  const sortedDomainSuggestions = domainsStore.search.domainSuggestions.slice().sort((a, b) => {
    if (b.existWithSearchText !== a.existWithSearchText) {
      return Number(b.existWithSearchText) - Number(a.existWithSearchText);
    }
    return Number(b.isAvailable) - Number(a.isAvailable);
  });

  return (
    <Container className={classNames('custom-scroll', {'empty-list': isNoItemsFound})}>
      {sortedDomainSuggestions.map((domainSuggestion) => (
        <DomainSuggestionItem
          key={domainSuggestion.id}
          domainSuggestion={domainSuggestion}
          onRegisterDomain={props.onRegisterDomain}
        />
      ))}

      {domainsView.isSearchingDomains ? (
        <Typography variant="body1" color="body.placeholder">
          {t('domains_search_suggestions_searching')}
        </Typography>
      ) : isNoItemsFound ? (
        <Typography variant="body1" color="body.placeholder">
          {t('domains_search_suggestions_not_found')}
        </Typography>
      ) : null}
    </Container>
  );
});

export default DomainSuggestions;
