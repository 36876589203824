import {Box, Button, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import serversImageBg from '../../assets/images/server-list-bg.png';
import Typography from '../../components/UI/Typography';
import Paths from '../../routes/Paths';

export const NoAvailableServers: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleClickCreateServer = () => {
    navigate(Paths.CreateServer);
  };

  return (
    <Box sx={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center'}}>
      <img src={serversImageBg} style={{marginBottom: theme.spacing(4)}} />
      <Typography variant="h4" gutterBottom>
        {t('servers_page_no_servers_available_title')}
      </Typography>
      <Typography variant="body1" sx={{color: theme.palette.body.tertiary}}>
        {t('servers_page_no_servers_available_description')}
      </Typography>

      <Button
        size="large"
        sx={{marginTop: theme.spacing(5)}}
        endIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
        onClick={handleClickCreateServer}
      >
        {t('servers_page_create_server_button')}
      </Button>
    </Box>
  );
};

export default NoAvailableServers;
