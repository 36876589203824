import {action, makeObservable, observable} from 'mobx';

export abstract class BaseServerOptions<T = string> {
  constructor() {
    makeObservable(this);
  }

  @observable isInit_ = false;
  @observable list: T[] = [];
  @observable selectedId: string | null = null;

  @action setSelectedId = (optionId: string | null) => {
    this.selectedId = optionId;
  };

  @action reset = () => {
    this.list = [];
    this.selectedId = null;
    this.isInit_ = false;
  };

  @action init = (rawList: T[]) => {
    if (this.isInit_) {
      return;
    }

    this.list = rawList;

    this.isInit_ = true;
  };

  //eslint-disable-next-line
  findByIds(ids: string[]): T[] {
    return [];
    // return this.list.filter((item) => item?.id && ids.includes(item.id));
  }
}

export default BaseServerOptions;
