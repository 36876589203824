import {makeObservable} from 'mobx';
import {parse, v4 as uuid} from 'uuid';
import {ca2} from '../api/proto';
import {AppStore} from '../stores/AppStore';
import logger from '../utils/logger';
import wait from '../utils/wait';
import EventEmitter from './EventEmitter';
import {getApiServerError} from './getApiError';
import {ErrorEvent, NetworkResponse} from './types';

export enum NetworkEvent {
  INVALID_TOKEN = 'INVALID_TOKEN',
  API_ERROR = 'API_ERROR',
}

export const API_ENDPOINT = '/exec';
export const MAX_RETRIES = 5;
export const RETRY_DELAY = 1000;

export class Network extends EventEmitter {
  constructor(protected app: AppStore) {
    super();
    makeObservable(this);
  }

  private asyncRequest_ = async <T extends ca2.IServerResponse>(request: ca2.ClientRequest): Promise<T> => {
    const body = ca2.ClientRequest.encode(request).finish();
    const requestInit: RequestInit = {
      body,
      headers: {
        Cache: 'no-cache',
        'Content-Type': 'application/x-protobuf',
      },
      method: 'POST',
    };

    logger.log(`%c---> https Request:(${new Date().toISOString()})`, 'color: orange', request);

    const response = await fetch(API_ENDPOINT, requestInit);

    if (![200, 201].includes(response.status)) {
      throw new ErrorEvent({message: 'Internal server error'});
    }

    const buffer = new Uint8Array(await response.arrayBuffer());
    const res = ca2.ServerResponse.decode(buffer);

    logger.log(`%c<--- https Response:(${new Date().toISOString()})`, 'color: green', res);

    return res as unknown as T;
  };

  private async request_<T extends ca2.IServerResponse>(data: ca2.IClientRequest): Promise<NetworkResponse<T>> {
    try {
      const trx = parse(uuid());
      const res = await this.asyncRequest_<T>(new ca2.ClientRequest({trx, ...data}));

      if (res.errors?.length) {
        this.emit(NetworkEvent.API_ERROR);

        const isInvalidToken = res.errors.some((err) =>
          [ca2.ServerError.SE_EXPIRED_TOKEN_ERROR, ca2.ServerError.SE_INVALID_TOKEN_ERROR].includes(err),
        );

        if (isInvalidToken) {
          this.emit(NetworkEvent.INVALID_TOKEN);
        }

        return {error: new ErrorEvent({message: getApiServerError(res.errors[0])}), res, trx};
      }

      return {error: null, res, trx};
    } catch (error) {
      return {
        error: new ErrorEvent({message: 'Internal server error'}),
        res: null,
      };
    }
  }

  public async request<T extends ca2.IServerResponse>(data: ca2.IClientRequest): Promise<NetworkResponse<T>> {
    let retries = 0;

    while (retries <= MAX_RETRIES) {
      const response = await this.request_<T>(data);

      if (response.res?.errors?.some((err) => err === ca2.ServerError.SE_RATE_LIMIT_EXCEEDED_ERROR)) {
        if (retries < MAX_RETRIES) {
          retries++;
          await wait(RETRY_DELAY);
          continue;
        }
      } else {
        return response;
      }
    }

    return {
      error: new ErrorEvent({message: `Internal server error after ${MAX_RETRIES} retries`}),
      res: null,
    };
  }
}

export default Network;
