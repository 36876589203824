import {Button} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-right-lg.svg';
import Plan from '../../../stores/Plan/Plan';
import HardwareInfo from './HardwareInfo';
import PlanGeneralInfo from './PlanGeneralInfo';
import ServerBenefits from './ServerBenefits';

interface IProps {
  plan: Plan;
  className?: string;
  onSelectPlan?(plan: Plan): void;
  onChangePlan?(): void;
  variant?: 'slim' | 'default';
}

export const PlanCard: React.FC<IProps> = (props) => {
  const {t} = useTranslation();

  const isChangePlanHandler = !!props.onChangePlan;

  const isSlimView = props.variant === 'slim';

  return (
    <div
      data-plan-id={props.plan.id}
      className={cn('plan-card', props.className, {clickable: !isChangePlanHandler, 'plan-card--slim': isSlimView})}
      onClick={() => props.onSelectPlan?.(props.plan)}
    >
      <div className="plan-card__inner">
        <div className="plan-card__content">
          <PlanGeneralInfo plan={props.plan} hideLocations={isSlimView} />

          <HardwareInfo plan={props.plan} />
        </div>

        <div className="plan-card__bottom">
          <ServerBenefits plan={props.plan} />
        </div>
      </div>

      <div className="plan-card__right">
        {isChangePlanHandler ? (
          <Button fullWidth variant="outlined" onClick={props.onChangePlan}>
            {t('create_server_plan_card_change_plan_link')}
          </Button>
        ) : (
          <ArrowRight style={{fill: 'currentcolor'}} />
        )}
      </div>
    </div>
  );
};

export default PlanCard;
