import {Button, useTheme} from '@mui/material';
import {useGoogleLogin} from '@react-oauth/google';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {getApiOAuthError} from '../../api/getApiError';
import {ca2auth} from '../../api/proto';
import {ReactComponent as GoogleIcon} from '../../assets/image-icons/google.svg';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import logger from '../../utils/logger';

const GoogleAuthButton: React.FC = observer(() => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {authStore, notification} = useStore();

  const googleLogin = authStore.allowGoogleAuth
    ? useGoogleLogin({
        scope: 'openid email profile',
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
          logger.debug(`%c GoogleLogin codeResponse:`, 'color: orange', codeResponse);
          const {res, error} = await authStore.googleAuthentication(codeResponse.code);

          if (error) {
            notification.error(error.message);
          }

          if (res) {
            const {errors, successStep} = res;

            if (errors?.some((e) => e === ca2auth.OAuthError.OA_USER_IS_BLOCKED_ERROR)) {
              navigate(Paths.Blocked);
              return;
            }

            if (errors?.length) {
              errors.forEach((error) => {
                notification.error(getApiOAuthError(error));
              });
              return;
            }

            if (successStep) {
              navigate(Paths.Servers);
            }
          }
        },
        onError: (errorResponse) => {
          notification.error(errorResponse.error_description);
        },
      })
    : () => {};

  return (
    <Button
      fullWidth
      variant="outlined"
      size="large"
      sx={{
        backgroundColor: theme.palette.backgroundSecondary,
        border: 'none',
        '&:hover,&:focus,&:active': {
          border: 'none',
        },
      }}
      onClick={() => googleLogin()}
    >
      <GoogleIcon style={{marginRight: theme.spacing(2)}} />
      {t('auth_google_verification_button')}
    </Button>
  );
});

export default GoogleAuthButton;
