import {Button, Tab, Tabs, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {navigateToDomainInfo, navigateToDomains} from '../../routes/navigateTo';
import {Domain} from '../../stores/Domain';
import {DomainsPageTab} from '../DomainsPage/DomainsPage';
import {DomainInfoTabs} from './DomainInfoViewer';

interface IProps {
  domain: Domain;
  currentTab: DomainInfoTabs;
}

export const DomainInfoHead: React.FC<IProps> = ({currentTab, domain}) => {
  const {t} = useTranslation();
  const tabsData = Object.entries({
    [DomainInfoTabs.Overview]: t('domain_info_overview_tab'),
    [DomainInfoTabs.NsRecords]: t('domain_info_ns_records_tab'),
    [DomainInfoTabs.DnsRecords]: t('domain_info_dns_records_tab'),
    [DomainInfoTabs.ContactInfo]: t('domain_info_contact_info_tab'),
    [DomainInfoTabs.Billing]: t('domain_info_billing_tab'),
  });

  const handleChange = (_: React.SyntheticEvent, tab: DomainInfoTabs) => {
    navigateToDomainInfo(domain, tab);
  };

  const handleClickBackButton = () => {
    navigateToDomains(DomainsPageTab.MyDomains);
  };

  return (
    <div className="domain-info-head">
      <div className="domain-info-head__body">
        <div className="domain-info-head__back">
          <Button
            className="domain-info-head__back-btn"
            variant="outlined"
            onClick={handleClickBackButton}
            size="small"
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
        <div className="domain-info-head__head">
          <Typography variant="body2" color="body.tertiary">
            {t('domain_info_head_my_domains_label')}
          </Typography>
          <Typography variant="h4">{domain.name}</Typography>
        </div>
      </div>
      <div className="domain-info-head__tabs">
        <Tabs className="lineView" value={currentTab} onChange={handleChange}>
          {tabsData.map(([tab, title]) => (
            <Tab
              className="lineView"
              key={tab}
              label={<Typography variant="subtitle1">{title}</Typography>}
              value={tab}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default DomainInfoHead;
