import {Box} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {Instance} from '../../../stores/Instance';
import RootPassword from './RootPassword';
import SshKeys from './SshKeys';
import UsageRecommendation from './UsageRecommendation';

interface IProps {
  instance: Instance;
}

export const Accesses: React.FC<IProps> = observer(({instance}) => {
  return (
    <Box sx={{flex: 1, width: '100%'}}>
      <UsageRecommendation />
      <RootPassword instance={instance} />
      <SshKeys instance={instance} />
    </Box>
  );
});

export default Accesses;
