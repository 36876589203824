import React from 'react';

const AuthIllustration: React.FC = () => {
  return (
    <div className="auth-illustration">
      <div className="auth-illustration__inner" />
    </div>
  );
};

export default AuthIllustration;
