import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalConfirm: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const modalRef = React.useRef<ModalDialogRef>(null);

  const handleCloseClick = () => {
    modalRef.current?.close();
  };

  const handleSubmit = async () => {
    setLoading(true);
    await props.modal.trigger('submit');
    setLoading(false);
    handleCloseClick();
  };

  const handleCancelClick = () => {
    if (props.modal.data.callbacks?.cancel) {
      props.modal.trigger('cancel');
    } else {
      handleCloseClick();
    }
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      maxWidth={props.modal.data.maxWidth || 'xs'}
      headerText={props.modal.data.headerText || ''}
      fullWidth
    >
      <DialogContent className="p-6 pt-7 body1-regular custom-scroll-y">
        {props.modal.data.title}

        {props.modal.data.content}
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          variant="outlined"
          color={props.modal.data.cancelButtonColor || 'secondary'}
          onClick={handleCancelClick}
        >
          {props.modal.data.cancelButtonText ? props.modal.data.cancelButtonText : t('action_button_cancel')}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          color={props.modal.data.submitButtonColor || 'error'}
          loading={loading}
          onClick={handleSubmit}
        >
          {props.modal.data.submitButtonText ? props.modal.data.submitButtonText : t('action_button_submit')}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalConfirm;
