import Div100vh from '../../components/Div100vh';

export const TeamPage: React.FC = () => {
  return (
    <Div100vh className="team-page">
      <div>TeamPage</div>
    </Div100vh>
  );
};

export default TeamPage;
