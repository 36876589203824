import {action, computed, makeObservable, observable} from 'mobx';
import {ca2domains, ca2types} from '../../api/proto';
import APILayer from '../APILayer';
import DomainsStore from './DomainsStore';
import DomainSuggestion from './DomainSuggestion';

export class DomainsSearch extends APILayer {
  constructor(public domainsStore: DomainsStore) {
    super(domainsStore.app);
    makeObservable(this);

    this.wsApi.on('domainCheck', this.onUpdateDomainSuggestions_);
  }

  @observable isInit: boolean = false;

  @observable tldGroups: ca2types.ITLDGroup[] = [];
  @observable selectedTldNames: string[] = [];

  @observable hideTakenDomains: boolean = false;
  @observable hidePremiumDomains: boolean = false;
  @observable searchText: string = '';

  @observable tlds: ca2types.ITLD[] = [];
  @observable private topTldNames_: string[] = [];

  @computed get topTlds(): ca2types.ITLD[] {
    const tlds_: ca2types.ITLD[] = [];

    for (const tldName of this.topTldNames_) {
      const foundTld = this.tlds.find(({name}) => tldName === name);

      if (foundTld) {
        tlds_.push(foundTld);
      }
    }

    return tlds_;
  }

  @observable private domainSuggestions_: DomainSuggestion[] = [];

  @computed get domainSuggestions(): DomainSuggestion[] {
    return this.domainSuggestions_.filter(({isReady, isAvailable, isPremium}) => {
      if (!isReady) {
        return true;
      }

      if (this.hideTakenDomains && this.hidePremiumDomains) {
        return isAvailable && !isPremium;
      }

      if (this.hideTakenDomains) {
        return isAvailable;
      }

      if (this.hidePremiumDomains) {
        return !isPremium;
      }

      return true;
    });
  }

  @computed get hasPremiumDomains() {
    return this.domainSuggestions_.some(({isPremium}) => isPremium);
  }

  @action setSelectedTldNames = async (tldNames: string[]) => {
    this.selectedTldNames = tldNames;

    if (this.searchText) {
      await this.searchDomain(this.searchText, tldNames);
    }
  };

  @action addTldName = async (tldName: string) => {
    if (!this.selectedTldNames.includes(tldName)) {
      const selectedTldNames_ = [...this.selectedTldNames];

      selectedTldNames_.push(tldName);
      this.selectedTldNames = selectedTldNames_;

      if (this.searchText) {
        await this.searchDomain(this.searchText, selectedTldNames_);
      }
    }
  };

  @action toggleTldName = async (tldName: string) => {
    const selectedTldNames_ = [...this.selectedTldNames];

    const index = selectedTldNames_.indexOf(tldName);

    if (index > -1) {
      selectedTldNames_.splice(index, 1);
      this.selectedTldNames = selectedTldNames_;
    } else {
      selectedTldNames_.push(tldName);
      this.selectedTldNames = selectedTldNames_;
    }

    if (this.searchText) {
      await this.searchDomain(this.searchText, selectedTldNames_);
    }
  };

  @action setSearchText = (text: string) => {
    this.searchText = text;
  };

  @action toggleHideTakenDomains = () => {
    this.hideTakenDomains = !this.hideTakenDomains;
  };

  @action toggleHidePremiumDomains = () => {
    this.hidePremiumDomains = !this.hidePremiumDomains;
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadTlds_();

    this.isInit = true;
  };

  findDomainSuggestionById = (suggestionId?: string | null) => {
    return this.domainSuggestions.find(({id}) => suggestionId && suggestionId === id) || null;
  };

  findTldsByGroupId = (groupId: string): string[] => {
    const foundGroup = this.tldGroups.find(({id}) => id === groupId);

    return foundGroup?.tldsNames || [];
  };

  findTldNamesBySearch = (text: string): string[] => {
    let tldNames_: string[] = [];

    for (const {tldsNames} of this.tldGroups) {
      const filteredTldNames_ = tldsNames?.filter((tld) => tld.startsWith(text.replace('.', '').toLowerCase()));
      if (filteredTldNames_?.length) {
        tldNames_ = [...tldNames_, ...filteredTldNames_];
      }
    }

    return tldNames_;
  };

  searchDomain = (searchText: string, tldNames?: string[] | null) => {
    this.setSearchText(searchText);

    return this.checkDomain_({
      domain: searchText,
      tldNames,
    });
  };

  private loadTlds_ = async () => {
    const {res} = await this.request({
      domains: {
        tldList: {},
      },
    });

    if (res?.domains?.tldList) {
      this.processLoadTlds_(res.domains.tldList);
    }
  };

  @action private processLoadTlds_ = (res: ca2domains.ITLDListResponse) => {
    if (res.groups) {
      this.tldGroups = res.groups;
    }

    if (res.tlds) {
      this.tlds = res.tlds;
    }

    if (res.topTldNames) {
      this.topTldNames_ = res.topTldNames;
    }
  };

  private checkDomain_ = async (checkDomain: ca2domains.ICheckDomainRequest) => {
    const {res, error} = await this.request({
      domains: {
        checkDomain: {
          domain: this.searchText,
          tldNames: this.selectedTldNames,
          ...checkDomain,
        },
      },
    });

    if (res?.domains?.checkDomain) {
      this.processCheckDomain_(res.domains.checkDomain);
    }

    return {res: res?.domains?.checkDomain, error};
  };

  @action private processCheckDomain_ = async (res: ca2domains.ICheckDomainResponse) => {
    if (res.suggestions) {
      this.domainSuggestions_ = res.suggestions.map((raw) => new DomainSuggestion(raw, this));
    }
  };

  @action clearDomainSuggestions = () => {
    this.domainSuggestions_ = [];
  };

  @action resetFilter = () => {
    this.searchText = '';
    this.selectedTldNames = [];
    this.hideTakenDomains = false;
    this.hidePremiumDomains = false;
    this.clearDomainSuggestions();
  };

  @action reset = () => {
    this.isInit = false;
    this.tldGroups = [];

    this.resetFilter();
  };

  @action private onUpdateDomainSuggestions_ = ({suggestions}: ca2domains.IDomainCheckWsEvent) => {
    if (suggestions) {
      for (const raw of suggestions) {
        const suggestion = this.findDomainSuggestionById(raw.id);
        suggestion?.update(raw);
      }
    }
  };
}
