import LoadingButton from '@mui/lab/LoadingButton';
import {Typography} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getApiAuthStartError} from '../../api/getApiError';
import TextField from '../../components/UI/TextField';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {AuthStepType} from './AuthPage';

import validator from 'email-validator';

type LocationState = {
  email?: string;
};

interface IProps {
  onSubmit?(): void;
}

const ResetPasswordForm: React.FC<IProps> = observer((props) => {
  const location = useLocation();
  const state = location.state as LocationState;

  const navigate = useNavigate();
  const {authStore, notification} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: state?.email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t('auth_email_verification_form_email_required'))
        .test('is_email', t('auth_email_verification_form_email_incorrect'), validator.validate),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {error, res} = await authStore.emailVerification({email: values.email, forceSendCode: true});

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors, enterCodeStep, isNewUser} = res;

        if (errors?.length) {
          errors.forEach((key) => {
            notification.error(getApiAuthStartError(key));
          });
          setLoading(false);
          return;
        }

        if (isNewUser && enterCodeStep) {
          navigate(Paths.Authorization, {
            state: {
              step: AuthStepType.VERIFICATION_CODE,
              email: values.email,
              codeParams: enterCodeStep.code,
              isNewUser,
            },
          });
          return;
        }

        if (enterCodeStep) {
          navigate(Paths.ForgotPassword, {state: {email: values.email, codeParams: enterCodeStep.code}, replace: true});
          props.onSubmit?.();
          return;
        }
      }

      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_reset_password_form_title')}
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({marginBottom: theme.spacing(6), color: theme.palette.body.tertiary})}
      >
        {t('auth_reset_password_form_description')}
      </Typography>

      <TextField
        fullWidth
        name="email"
        autoComplete="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        placeholder={t('auth_email_verification_form_email_placeholder')}
        label={t('auth_email_verification_form_email_label')}
        error={!!formik.errors.email}
        helperText={formik.errors.email}
        autoFocus
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_reset_password_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default ResetPasswordForm;
