import {Box, Button, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2instances} from '../../api/proto';
import {ReactComponent as CheckRoundIcon} from '../../assets/icons/check-fill-round-xxl.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning-white.svg';
import CopyableTextField from '../../components/UI/CopyableTextField';
import PageContentLayout from '../../layouts/PageContentLayout';
import {navigateToServerInfo} from '../../routes/navigateTo';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import ActivatedPlanCard from './ActivatedPlanCard';

interface IProps {
  plan: Plan;
  createServerResponse: ca2instances.ICreateInstanceResponse;
  onCancelPaymentOrder?(): void;
}

const CreateServerConfirmation: React.FC<IProps> = observer((props) => {
  const {instancesStore} = useStore();
  const theme = useTheme();
  const {t} = useTranslation();

  const handleOpenServerInfoPage = () => {
    const instanceId = props.createServerResponse?.instance?.id;
    if (instanceId) {
      const instance = instancesStore.findInstanceById(instanceId);
      if (instance) {
        navigateToServerInfo(instance);
      }
    }
  };

  return (
    <PageContentLayout
      sx={{
        margin: '0 auto',
        padding: theme.spacing(4),
        maxWidth: 500,
      }}
    >
      <Box sx={{marginBottom: theme.spacing(4)}}>
        <CheckRoundIcon />
      </Box>

      <Box sx={{marginBottom: theme.spacing(4)}}>
        <Typography variant="h4" gutterBottom>
          {t('create_server_confirmation_server_has_been_successfully_activated')}
        </Typography>
        <Typography variant="body1">
          {t('create_server_confirmation_amount_has_been_charged', {
            amount: props.plan.firstHoursRateFormatter.formatted,
          })}
        </Typography>
      </Box>

      <ActivatedPlanCard plan={props.plan} />

      {props.createServerResponse.generatedSshKey || props.createServerResponse.instance?.rootPassword ? (
        <>
          <Box
            sx={{
              marginTop: theme.spacing(4),
              backgroundColor: theme.palette.warning.primary,
              padding: theme.spacing(3),
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WarningIcon style={{marginRight: theme.spacing(2)}} />
            <Typography variant="subtitle2" sx={{color: theme.palette.body.tint}}>
              {props.plan.isWindow
                ? t('create_server_confirmation_be_sure_to_save_admin_password')
                : t('create_server_confirmation_be_sure_to_save_password_or_ssh')}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: theme.spacing(4),
              border: `1px solid ${theme.palette.border.primary}`,
              borderTop: 'none',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          >
            {props.createServerResponse.instance?.rootPassword ? (
              <CopyableTextField
                fullWidth
                value={props.createServerResponse.instance.rootPassword}
                label={
                  props.plan.isWindow
                    ? t('create_server_confirmation_server_admin_password_label')
                    : t('create_server_confirmation_server_root_password_label')
                }
              />
            ) : null}

            {props.createServerResponse.generatedSshKey ? (
              <CopyableTextField
                fullWidth
                value={props.createServerResponse.generatedSshKey}
                label={t('create_server_confirmation_server_ssh_key_label')}
              />
            ) : null}
          </Box>
        </>
      ) : null}

      <Button
        variant="contained"
        fullWidth
        size="large"
        onClick={handleOpenServerInfoPage}
        sx={{marginBottom: theme.spacing(2), marginTop: theme.spacing(4)}}
      >
        {t('create_server_confirmation_open_server_page_button')}
      </Button>
      <Button
        variant="outlined"
        fullWidth
        size="large"
        startIcon={<PlusIcon style={{fill: theme.palette.body.primary}} />}
        onClick={props.onCancelPaymentOrder}
      >
        {t('create_server_confirmation_create_additional_server_button')}
      </Button>
    </PageContentLayout>
  );
});

export default CreateServerConfirmation;
