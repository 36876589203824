import {observer} from 'mobx-react';
import React from 'react';

import {useLocation, useNavigate} from 'react-router';
import Paths from '../../routes/Paths';
import getQueryStringParam from '../../utils/getQueryStringParam';
import EmailVerificationForm from './EmailVerificationForm';
import EnterPasswordForm from './EnterPasswordForm';
import PersonalInfoForm from './PersonalInfoForm';
import VerificationCodeForm from './VerificationCodeForm';

export enum AuthStepType {
  EMAIL_VERIFICATION,
  VERIFICATION_CODE,
  PERSONAL_INFO,
  ENTER_PASSWORD,
}

type LocationState = {
  step?: AuthStepType;
};

const debug: string = getQueryStringParam('debug');
const qsStep: string = getQueryStringParam('step');

const INITIAL_STEP = debug && qsStep ? parseFloat(qsStep) : AuthStepType.EMAIL_VERIFICATION;

const AuthPage: React.FC = observer(() => {
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = React.useState<AuthStepType>(state?.step || INITIAL_STEP);
  const [userFullName, setUserFullName] = React.useState<null | string>(null);

  const switchToStep = (step: AuthStepType) => {
    setCurrentStep(step);
  };

  const handleSubmitEmailVerification = (switchToEnterPasswordStep: boolean = false) => {
    switchToStep(switchToEnterPasswordStep ? AuthStepType.ENTER_PASSWORD : AuthStepType.VERIFICATION_CODE);
  };

  const handleSubmitVerificationCode = (userFullName: string | null = null) => {
    setUserFullName(userFullName);
    switchToStep(AuthStepType.PERSONAL_INFO);
  };

  const handleSubmitPersonalInfo = () => {
    navigate(Paths.Servers);
  };

  const handleSubmitEnterPassword = () => {
    navigate(Paths.Servers);
  };

  const handleEditEmail = () => {
    switchToStep(AuthStepType.EMAIL_VERIFICATION);
  };

  const bodyHeight: Record<AuthStepType, number> = {
    [AuthStepType.EMAIL_VERIFICATION]: 250,
    [AuthStepType.VERIFICATION_CODE]: 516,
    [AuthStepType.PERSONAL_INFO]: 452,
    [AuthStepType.ENTER_PASSWORD]: 336,
  };

  const renderForm = () => {
    switch (currentStep) {
      case AuthStepType.EMAIL_VERIFICATION:
        return <EmailVerificationForm onSubmit={handleSubmitEmailVerification} />;
      case AuthStepType.VERIFICATION_CODE:
        return (
          <VerificationCodeForm onSubmit={handleSubmitVerificationCode} onEditEmail={handleEditEmail} isRegistration />
        );
      case AuthStepType.PERSONAL_INFO:
        return <PersonalInfoForm fullName={userFullName} onSubmit={handleSubmitPersonalInfo} />;
      case AuthStepType.ENTER_PASSWORD:
        return <EnterPasswordForm onSubmit={handleSubmitEnterPassword} />;
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-page__body" style={{minHeight: bodyHeight[currentStep] || '100%'}}>
        {renderForm()}
      </div>
    </div>
  );
});

export default AuthPage;
