import {useTransition} from 'react-spring';
import useIsFirstRender from './useIsFirstRender';

interface IParams {
  currentStep: number;
  prevStep: number;
}

export function useOrderTransition({currentStep, prevStep}: IParams) {
  const isFirst = useIsFirstRender();

  return useTransition(currentStep, {
    from: {
      opacity: isFirst ? 1 : 0,
      transform: isFirst
        ? 'translate3d(0px, 0, 0)'
        : prevStep < currentStep
        ? 'translate3d(24px, 0, 0)'
        : 'translate3d(-24px, 0, 0)',
    },
    enter: {opacity: 1, transform: 'translate3d(0px, 0, 0)'},
    leave: {
      opacity: 0,
      transform: prevStep < currentStep ? 'translate3d(-24px, 0, 0)' : 'translate3d(24px, 0, 0)',
    },
    config: {duration: 100},
    immediate: isFirst,
  });
}
