import {Box, BoxProps, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete-tld.svg';
import CheckboxField, {ICheckboxFieldProps} from '../UI/CheckboxField';

export interface ITldItem {
  tldName: string;
  checked: boolean;
}

export function makeTldItem(tldName: string, isChecked?: boolean): ITldItem {
  return {
    tldName,
    checked: isChecked || false,
  };
}

const TldBox = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  paddingRight: theme.spacing(3),
  '&.checked': {
    backgroundColor: theme.palette.brand.primary,
    borderColor: theme.palette.brand.primary,
    color: theme.palette.body.tint,

    '&:hover': {
      backgroundColor: theme.palette.brand.tertiary,
    },

    '& svg.delete-icon rect': {
      fill: theme.palette.brand.primary,
    },
  },
}));

interface IProps extends BoxProps {
  tldItem: ITldItem;
  onToggleTld?(tld: string): void;
}

const TldItem: React.FC<IProps> = ({tldItem, onToggleTld, ...restProps}) => {
  const theme = useTheme();
  const [isHover, setIsHover] = React.useState<boolean>(false);

  const handleChange = (tldName: string) => {
    onToggleTld?.(tldName);
  };

  let checkBoxFieldProps: ICheckboxFieldProps = {};

  if (tldItem.checked && isHover) {
    checkBoxFieldProps = {
      checkedIcon: <DeleteIcon className="delete-icon" style={{fill: theme.palette.body.tint}} />,
    };
  }

  return (
    <TldBox
      className={cn({checked: tldItem.checked})}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...restProps}
    >
      <CheckboxField
        label={`.${tldItem.tldName}`}
        FormLabelProps={{
          labelPlacement: 'start',
        }}
        onChange={() => handleChange(tldItem.tldName)}
        checked={!!tldItem.checked}
        {...checkBoxFieldProps}
      />
    </TldBox>
  );
};

export default TldItem;
