import {MenuItemProps, MenuItem as MuiMenuItem, styled} from '@mui/material';
import React from 'react';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-xl.svg';

const StyledMenuItem = styled(MuiMenuItem)(({theme}) => ({
  display: 'flex',
  justifyContent: 'start',
  alignContent: 'center',
  backgroundColor: theme.palette.backgroundSecondary,
  color: theme.palette.body.primary,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  '& .selected-icon': {
    display: 'none',
    marginLeft: 'auto',
    path: {
      fill: theme.palette.brand.primary,
    },
  },
  '&:not(.Mui-selected):hover': {
    backgroundColor: theme.palette.backgroundTertiary,
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',

    '& .selected-icon': {
      display: 'block',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.backgroundSecondary,
    color: theme.palette.body.placeholder,
  },
}));

const MenuItem: React.FC<MenuItemProps> = ({children, ...rest}) => {
  return (
    <StyledMenuItem {...rest}>
      {children} <CheckIcon className="selected-icon" />
    </StyledMenuItem>
  );
};

export default MenuItem;
