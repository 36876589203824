import {Box, styled, useTheme} from '@mui/material';
import Button from '@mui/material/Button/Button';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow-left.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg';
import {ReactComponent as ListMarkIcon} from '../../../assets/icons/list-mark.svg';
import {ReactComponent as RestartIcon} from '../../../assets/icons/restart.svg';
import {ReactComponent as StartIcon} from '../../../assets/icons/start.svg';
import {ReactComponent as StopIcon} from '../../../assets/icons/stop.svg';
import Typography from '../../../components/UI/Typography';
import {useInstanceStatusColor} from '../../../hooks/useInstanceStatusColor';
import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalsStore';
import EditableSeverName from './EditableSeverName';

const StatusLabel = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  marginRight: theme.spacing(1),
  '& svg': {
    marginRight: theme.spacing(2),
  },
}));

const HEAD_HEIGHT = 41;

const ActionsBox = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px 12px 12px 8px',
  marginLeft: 'auto',
  maxHeight: HEAD_HEIGHT,
}));

interface IServerInfoHeadProps {
  onBack?(): void;
}

export const ServerInfoHead: React.FC<React.PropsWithChildren<IServerInfoHeadProps>> = observer(
  ({children, onBack}) => {
    const {instanceView, modals} = useStore();
    const theme = useTheme();
    const {t} = useTranslation();

    const {activeInstance} = instanceView;
    const {statusBackground, statusColor} = useInstanceStatusColor(activeInstance?.instanceStatus);

    const handleDeleteServer = () => {
      modals.open(ModalType.CONFIRM, {
        maxWidth: 'sm',
        headerText: t('modal_confirm_delete_server_header_text', {serverName: instanceView.activeInstance?.name}),
        title: (
          <Typography variant="body1" gutterBottom>
            {t('modal_confirm_delete_server_title')}
          </Typography>
        ),
        submitButtonText: t('modal_confirm_delete_server_submit_button'),
        content: <Typography variant="body1">{t('modal_confirm_delete_server_content')}</Typography>,
        callbacks: {
          submit: async () => {
            await instanceView.deleteActiveInstance();
          },
        },
      });
    };

    const handleRestartServer = () => {
      activeInstance?.restart();
    };

    const handleStopServer = () => {
      activeInstance?.stop();
    };

    const handleStartServer = () => {
      activeInstance?.start();
    };

    if (!activeInstance) {
      return null;
    }

    return (
      <div className="server-info-head">
        <div className="server-info-head__body">
          <div className="server-info-head__back">
            <Button
              className="server-info-head__back-btn"
              variant="outlined"
              onClick={onBack}
              size="small"
              sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
            >
              <ArrowLeftIcon width={24} height={24} />
            </Button>
          </div>
          <div className="server-info-head__head">
            <EditableSeverName />

            <ActionsBox>
              <StatusLabel
                sx={{
                  color: statusColor,
                  borderColor: statusColor,
                  backgroundColor: statusBackground,
                  height: HEAD_HEIGHT,
                }}
              >
                <ListMarkIcon style={{fill: statusColor}} />

                <Typography variant="subtitle1">{activeInstance.statusTitle}</Typography>
              </StatusLabel>

              <Box sx={{display: 'flex', paddingRight: '3px'}}>
                {activeInstance.isActive ? (
                  <Button
                    variant="outlined"
                    disabled={!activeInstance?.allowStop}
                    startIcon={<StopIcon style={{fill: theme.palette.error.primary}} />}
                    onClick={handleStopServer}
                  >
                    {t('server_action_stop')}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    disabled={!activeInstance?.allowStart}
                    startIcon={<StartIcon style={{fill: theme.palette.success.primary}} />}
                    onClick={handleStartServer}
                  >
                    {t('server_action_start')}
                  </Button>
                )}

                <Button
                  sx={{marginLeft: theme.spacing(1)}}
                  variant="outlined"
                  disabled={!activeInstance?.allowRestart}
                  startIcon={<RestartIcon style={{fill: theme.palette.body.primary}} />}
                  onClick={handleRestartServer}
                >
                  {t('server_action_restart')}
                </Button>
              </Box>
            </ActionsBox>
            <Button
              sx={{marginLeft: theme.spacing(2), height: HEAD_HEIGHT}}
              variant="outlined"
              size="large"
              startIcon={<DeleteIcon style={{fill: theme.palette.error.primary}} />}
              disabled={!activeInstance?.allowDelete}
              onClick={handleDeleteServer}
            >
              {t('server_action_destroy')}
            </Button>
          </div>
        </div>
        <div className="server-info-head__tabs">{children}</div>
      </div>
    );
  },
);

export default ServerInfoHead;
