import {Collapse, ListItemIcon, ListItemText, MenuItem, MenuList, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {ReactComponent as ArrowUpIcon} from '../../assets/icons/arrow-up-xs.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {ReactComponent as ThemeIcon} from '../../assets/icons/theme.svg';
import {ReactComponent as UserIcon} from '../../assets/icons/user.svg';
import Typography from '../../components/UI/Typography';
import {UIThemeSwitch} from '../../components/UI/UIThemeSwitch';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import extractInitials from '../../utils/extractInitials';

const StyledMenu = styled(MenuList)(({theme}) => ({
  border: `1px solid ${theme.palette.border.primary}`,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  borderBottom: 'none',
  marginTop: theme.spacing(4),
  overflow: 'hidden',

  '& .MuiMenuItem-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    color: theme.palette.body.primary,

    '.sidebarCollapsed &': {
      '& .MuiTypography-root, .active-item-icon': {
        display: 'none !important',
      },
    },
  },
}));

export const ProfileMenu: React.FC = observer(() => {
  const {t} = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    layoutStore,
    layoutStore: {sidebar},
    userStore,
  } = useStore();

  const [open, setOpen] = React.useState(false);

  const handleClickToggleMenu = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (sidebar.collapsed && open) {
      setOpen(false);
    }
  }, [sidebar.collapsed, open]);

  const handleClickProfile = () => {
    navigate(Paths.Profile);
  };

  const handleClickLogout = () => {
    navigate(Paths.Logout);
  };

  const iconStyle = {
    fill: theme.palette.body.primary,
  };

  return (
    <div className={cn('profile-menu', {open})}>
      <Collapse in={open}>
        <StyledMenu sx={{border: 'none', margin: 0}}>
          <MenuItem onClick={handleClickProfile}>
            <ListItemIcon>
              <UserIcon style={iconStyle} />
            </ListItemIcon>
            <ListItemText>{t('sidebar_profile_menu_profile')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={layoutStore.uiTheme.toggleCurrentTheme}>
            <ListItemIcon>
              <ThemeIcon style={iconStyle} />
            </ListItemIcon>
            <ListItemText>{t('sidebar_profile_menu_appearance')}</ListItemText>
            {!layoutStore.sidebar.collapsed && <UIThemeSwitch checked={!layoutStore.uiTheme.lightThemeChosen} />}
          </MenuItem>
          <MenuItem onClick={handleClickLogout}>
            <ListItemIcon>
              <LogoutIcon style={iconStyle} />
            </ListItemIcon>
            <ListItemText>{t('sidebar_profile_menu_logout')}</ListItemText>
          </MenuItem>
        </StyledMenu>
      </Collapse>

      <div className="profile-menu__bottom" onClick={handleClickToggleMenu}>
        <div className="profile-menu__initials">{extractInitials(userStore.displayName)}</div>
        <Typography className="profile-menu__name" variant="subtitle1">
          {userStore.displayName}
        </Typography>
        <ArrowUpIcon width={16} height={16} className="profile-menu__arrow" />
      </div>
    </div>
  );
});

export default ProfileMenu;
