import {action, computed, makeObservable, observable} from 'mobx';
import {ca2auth, ca2types} from '../../api/proto';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {authPageOpen} from '../../routes/routes';
import browserStorage from '../../utils/browserStorage';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';

export const TOKEN_STORE_KEY = '_tkn';

const logoutBroadcastChannel = new BroadcastChannel('logout');

export class AuthStore extends APILayer {
  @observable private token_: string | null = null;

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    logoutBroadcastChannel.addEventListener('message', () => {
      this.logoutRequest_();
    });
  }

  @computed get googleAuthClientId() {
    return this.app.utils.oauthProviders.find((provider) => provider.type === ca2types.OAuthProviderType.OP_GOOGLE)
      ?.clientId;
  }

  @computed get allowGoogleAuth() {
    return !!this.googleAuthClientId;
  }

  getToken = (): string | null => {
    const stored = browserStorage.getItem(TOKEN_STORE_KEY);
    return this.token_ || stored;
  };

  @computed get isLoggedIn(): boolean {
    return !!this.token_;
  }

  @action private setToken_ = (token: string | null) => {
    if (token) {
      browserStorage.setItem(TOKEN_STORE_KEY, token);
    }

    this.token_ = token;
  };

  init = () => {
    this.token_ = this.getToken();
  };

  @action reset = () => {
    browserStorage.removeItem(TOKEN_STORE_KEY);
    this.token_ = null;
  };

  emailVerification = async (start: ca2auth.IStartRequest) => {
    const {error, res} = await this.request({auth: {start}});
    return {error, res: res?.auth?.start};
  };

  enterCode = async (enterCode: ca2auth.IEnterCodeRequest, skipEnterCodeProcess = false) => {
    const {error, res} = await this.request({auth: {enterCode}});

    if (res?.auth?.enterCode && !skipEnterCodeProcess) {
      await this.processEnterCode_(res.auth.enterCode);
    }

    return {error, res: res?.auth?.enterCode};
  };

  @action private processEnterCode_ = async (res: ca2auth.IEnterCodeResponse) => {
    if (res.successStep) {
      const {token, user} = res.successStep;

      if (token) {
        this.setToken_(token);
        this.app.wsApi.connect();
      }

      user && this.app.userStore.setProfile(user);

      if (token && user) {
        await this.app.authenticatedInit();
      }
    }
  };

  enterPassword = async (enterPassword: ca2auth.IEnterPasswordRequest) => {
    const {error, res} = await this.request({auth: {enterPassword}});

    if (res?.auth?.enterPassword?.successStep) {
      this.processSuccessAuth_(res.auth.enterPassword.successStep);
    }

    return {error, res: res?.auth?.enterPassword};
  };

  googleAuthentication = async (bearerJwtToken: string) => {
    const {res, error} = await this.request({
      auth: {
        oauth: {
          bearerJwtToken,
          oauthProviderType: ca2types.OAuthProviderType.OP_GOOGLE,
        },
      },
    });

    if (res?.auth?.oauth?.successStep) {
      this.processSuccessAuth_(res.auth.oauth.successStep);
    }

    return {error, res: res?.auth?.oauth};
  };

  @action private processSuccessAuth_ = (res: ca2auth.ISuccessStep) => {
    const {token, user} = res;

    if (token) {
      this.setToken_(token);
      this.app.wsApi.connect();
    }

    user && this.app.userStore.setProfile(user);

    if (token && user) {
      this.app.authenticatedInit();
    }
  };

  logout = () => {
    logoutBroadcastChannel.postMessage('logout');

    if (this.isLoggedIn) {
      this.logoutRequest_();
    }
  };

  private logoutRequest_ = () => {
    this.request({auth: {logout: {}}});
    this.resetLoginSession_();
  };

  private resetLoginSession_ = () => {
    this.app.reset();
    this.app.wsApi.disconnect();

    if (!authPageOpen()) {
      browserHistory.push(Paths.Authorization);
    }
  };
}

export default AuthStore;
