import {Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import DropdownMenu from '../../components/UI/DropdownMenu';
import MenuItem from '../../components/UI/MenuItem';
import {useStore} from '../../stores/AppStore';

interface IProps {
  onClickAddNewSshKey?(): void;
  onClickAddExistSshKey?(): void;
}

const AddSshKeyDropdown: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {sshKeysStore} = useStore();

  const [sshKeyMenuEl, setSshKeyMenuEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(sshKeyMenuEl);

  const handleClickAddSshKeyBtnMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (sshKeysStore.list.length) {
      setSshKeyMenuEl(e.currentTarget);
    } else {
      props.onClickAddNewSshKey?.();
    }
  };

  const handleCloseMenu = () => {
    setSshKeyMenuEl(null);
  };

  const handleAddNewSsh = () => {
    props.onClickAddNewSshKey?.();
    handleCloseMenu();
  };

  const handleAddExist = () => {
    props.onClickAddExistSshKey?.();
    handleCloseMenu();
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={
          <PlusIcon width={16} height={16} style={{marginLeft: theme.spacing(1), fill: theme.palette.body.primary}} />
        }
        onClick={handleClickAddSshKeyBtnMenu}
      >
        {t('component_ssh_keys_viewer_add_ssh_key_button')}
      </Button>
      <DropdownMenu MenuListProps={{sx: {padding: 0}}} anchorEl={sshKeyMenuEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={handleAddNewSsh}>{t('component_ssh_keys_viewer_add_new_button')}</MenuItem>
        <MenuItem onClick={handleAddExist}>{t('component_ssh_keys_viewer_select_existing_button')}</MenuItem>
      </DropdownMenu>
    </>
  );
});

export default AddSshKeyDropdown;
