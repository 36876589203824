import React from 'react';

import AuthIllustration from '../pages/Auth/AuthIllustration';
import BaseAuthLayout from './BaseAuthLayout';

const AuthLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <BaseAuthLayout>
      <div className="auth-layout__inner">
        {children}
        <AuthIllustration />
      </div>
    </BaseAuthLayout>
  );
};

export default AuthLayout;
