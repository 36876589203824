import {Box, styled, useTheme} from '@mui/material';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/arrow-down.svg';
import {ReactComponent as ListMarkIcon} from '../../assets/icons/list-mark.svg';
import {ReactComponent as PowerOffIcon} from '../../assets/icons/power-off.svg';
import {ReactComponent as RestartIcon} from '../../assets/icons/restart.svg';
import {ReactComponent as RunICon} from '../../assets/icons/run-server.svg';
import DropdownMenu from '../../components/UI/DropdownMenu';
import MenuItem from '../../components/UI/MenuItem';
import {useInstanceStatusColor} from '../../hooks/useInstanceStatusColor';
import {Instance} from '../../stores/Instance';

const StatusLabelBox = styled(Box)(({theme}) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  border: '1px solid transparent',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: 33,
  '& svg': {
    marginRight: theme.spacing(2),
  },
  '& .arrow-icon': {
    display: 'none',
    marginLeft: 'auto',
  },
  '&.withDropdown': {
    backgroundColor: theme.palette.backgroundPrimary,
    borderRadius: '8px',
    '& .arrow-icon': {
      display: 'inherit',
    },
  },
  '&.active': {
    borderColor: theme.palette.brand.primary,
    zIndex: theme.zIndex.tooltip,
  },
}));

const Menu = styled(DropdownMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    marginTop: 0,
  },
  '& .MuiMenuItem-root': {
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
}));

interface IProps {
  instance: Instance;
}

export const ServerStatusDropdown: React.FC<IProps> = observer(({instance}) => {
  const {t} = useTranslation();
  const theme = useTheme();

  const {instanceStatus} = instance;

  const labelRef = React.useRef<HTMLDivElement>(null);
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);

  const {statusColor} = useInstanceStatusColor(instanceStatus);

  const handleCloseMenu = () => {
    setMenuEl(null);
  };

  const isAllowDropdown = instance.allowSomeAction;

  React.useEffect(() => {
    if (!isAllowDropdown) {
      setMenuEl(null);
    }
  }, [isAllowDropdown]);

  const handleToggleMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isAllowDropdown) {
      return;
    }

    setMenuEl(menuEl ? null : e.currentTarget);
  };

  const handleActionClick = async (action: 'stop' | 'start' | 'restart') => {
    switch (action) {
      case 'stop':
        await instance.stop();
        break;
      case 'start':
        await instance.start();
        break;
      case 'restart':
        await instance.restart();
        break;
    }

    handleCloseMenu();
  };

  return (
    <>
      <StatusLabelBox
        ref={labelRef}
        className={classNames({
          withDropdown: isAllowDropdown,
          active: !!menuEl && isAllowDropdown,
        })}
        onClick={handleToggleMenu}
      >
        <ListMarkIcon style={{fill: statusColor, marginRight: theme.spacing(2)}} />
        <div>{instance.statusTitle}</div>
        <ArrowDownIcon style={{fill: theme.palette.body.placeholder, marginRight: 0}} className="arrow-icon" />
      </StatusLabelBox>

      <Menu
        slotProps={{paper: {sx: {width: labelRef.current?.clientWidth || 100}}}}
        anchorEl={menuEl}
        open={!!menuEl}
        onClose={handleCloseMenu}
      >
        {instance.allowStart && !(instance.isActive || instance.isStarting) ? (
          <MenuItem onClick={() => handleActionClick('start')}>
            <RunICon />
            {t('server_action_start')}
          </MenuItem>
        ) : null}

        {instance.allowRestart && !instance.isRestarting ? (
          <MenuItem onClick={() => handleActionClick('restart')}>
            <RestartIcon style={{fill: 'currentcolor'}} />
            {t('server_action_restart')}
          </MenuItem>
        ) : null}

        {instance.allowStop && !(instance.isStopping || instance.isStopped) ? (
          <MenuItem onClick={() => handleActionClick('stop')} disabled={instance.isStopping}>
            <PowerOffIcon />
            {t('server_action_stop')}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
});

export default ServerStatusDropdown;
