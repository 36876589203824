import {Box, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {TableVirtuoso} from 'react-virtuoso';
import {ReactComponent as TableSortDefaultIcon} from '../../../assets/icons/table-sort-default.svg';
import {ReactComponent as TableSortIcon} from '../../../assets/icons/table-sort.svg';
import {Instance} from '../../../stores/Instance';
import {SortOrder, getComparator, tableSort} from '../../../utils/tableSort';
import {ACTIONS_COLUMN_WIDTH, Column, ColumnType} from '../useServersTableColumns';
import ServerTableRow from './ServerTableRow';

interface IProps {
  instances: Instance[];
  columns: Column[];
}

const VirtuosoTableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer {...props} ref={ref} />),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}} />,
  TableRow: ({...props}) => <TableRow {...props} hover />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

export const ServersTable: React.FC<IProps> = observer((props) => {
  const [order, setOrder] = React.useState<SortOrder>(SortOrder.desc);
  const [orderBy, setOrderBy] = React.useState<ColumnType>(ColumnType.Hostname);

  const columnPropsDictionary: Record<ColumnType, keyof Instance> = {
    [ColumnType.Hostname]: 'name',
    [ColumnType.Datacenter]: 'datacenterCity',
    [ColumnType.IPv4]: 'ipv4',
    [ColumnType.IPv6]: 'ipv6',
    [ColumnType.Image]: 'imageName',
    [ColumnType.Status]: 'instanceStatus',
  };

  const handleRequestSort = (_, property: ColumnType) => {
    const isAsc = orderBy === property && order === SortOrder.asc;
    setOrder(isAsc ? SortOrder.desc : SortOrder.asc);
    setOrderBy(property);
  };

  const sortedInstances = tableSort(
    props.instances,
    getComparator<Instance>(order, columnPropsDictionary[orderBy]),
  ).sort((a, b) => Number(b.isImportant) - Number(a.isImportant));

  function fixedHeaderContent() {
    return (
      <TableRow>
        {props.columns.map((column) => {
          const active = orderBy === column.id;
          const direction = orderBy === column.id ? order : SortOrder.asc;

          return column.visible ? (
            <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth, width: column.width}}>
              <TableSortLabel
                active={active}
                direction={direction}
                onClick={(event) => handleRequestSort(event, column.id)}
                IconComponent={active ? TableSortIcon : TableSortDefaultIcon}
                sx={{justifyContent: 'space-between'}}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ) : null;
        })}
        <TableCell style={{width: ACTIONS_COLUMN_WIDTH, borderRight: 'none'}} />
      </TableRow>
    );
  }

  return (
    <Box sx={(theme) => ({padding: theme.spacing(4), height: '100%'})}>
      <TableVirtuoso
        data={sortedInstances}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, instance) => <ServerTableRow columns={props.columns} instance={instance} key={index} />}
      />
    </Box>
  );
});

export default ServersTable;
