import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning.svg';
import {useStore} from '../../stores/AppStore';
import PasswordInput from './PasswordInput';
import {TextFieldProps} from './TextField';

export enum PasswordValidationType {
  minLength = 'min_length',
  numberChar = 'number_char',
  capitalLetter = 'capital_letter',
}

export const PASSWORD_VALIDATIONS = {
  capitalLetterRegexp: /(?=.*[A-Z])/,
  numberCharRegexp: /(?=.*\d)/,
};

export const PasswordSmartInput: React.FC<TextFieldProps> = observer(({onChange, ...restProps}) => {
  const {utils} = useStore();
  const {t} = useTranslation();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [touched, setTouched] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Record<PasswordValidationType, boolean>>({
    [PasswordValidationType.capitalLetter]: false,
    [PasswordValidationType.numberChar]: false,
    [PasswordValidationType.minLength]: false,
  });

  const translations = {
    [PasswordValidationType.minLength]: t('component_password_smart_input_min_symbols', {
      minLength: utils.minPasswordLength,
    }),
    [PasswordValidationType.capitalLetter]: t('component_password_smart_input_one_capital_letter'),
    [PasswordValidationType.numberChar]: t('component_password_smart_input_one_number'),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);

    onChange?.(event);
    setInputValue(event.target.value);
    validation(event.target.value);
  };

  const validation = (value: string) => {
    setValidationErrors({
      [PasswordValidationType.minLength]: value.length < utils.minPasswordLength,
      [PasswordValidationType.capitalLetter]: !PASSWORD_VALIDATIONS.capitalLetterRegexp.test(value),
      [PasswordValidationType.numberChar]: !PASSWORD_VALIDATIONS.numberCharRegexp.test(value),
    });
  };

  return (
    <div className="password-smart-input">
      <PasswordInput
        {...restProps}
        helperText=""
        value={inputValue}
        onChange={handleChange}
        formControlProps={{sx: {marginBottom: 0}}}
      />
      <div className="password-smart-input__validations">
        {Object.entries(validationErrors).map(([validationType, error]) => (
          <div
            key={validationType}
            className={cn('password-smart-input__validation', {error, success: touched && !error})}
          >
            {error ? <WarningIcon /> : <CheckIcon />}
            {translations[validationType]}
          </div>
        ))}
      </div>
    </div>
  );
});

export default PasswordSmartInput;
