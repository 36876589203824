import {Box, Button, styled, TypographyProps} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CopyableBox from '../../../components/UI/CopyableBox';
import Typography from '../../../components/UI/Typography';

const ItemBox = styled(Box)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  backgroundColor: theme.palette.backgroundSecondary,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  borderRadius: '12px',
  height: '100%',
  width: '100%',
  '& .copy-button': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: theme.spacing(2),
  },
  '& .border-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0),
    backgroundColor: theme.palette.backgroundSecondary,
    width: '32px',
    height: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    marginRight: theme.spacing(2),

    '&--success svg': {
      fill: theme.palette.success.primary,
    },
  },
  '& svg': {
    maxHeight: 24,
    width: 'auto',
    fill: theme.palette.body.primary,
  },
}));

interface IProps {
  value?: React.ReactNode | string;
  label?: string;
  icon?: React.ReactNode;
  copyable?: boolean;
  onManageBtnClick?(): void;
  manageButtonTitle?: string;
  ValueTypographyProps?: TypographyProps;
}

const OverviewItem: React.FC<IProps> = ({
  value,
  label,
  icon,
  copyable,
  onManageBtnClick,
  manageButtonTitle,
  ValueTypographyProps,
}) => {
  const {t} = useTranslation();
  const isStringValue = typeof value === 'string';

  const valueNode =
    copyable && isStringValue && !onManageBtnClick ? <CopyableBox value={value} className="copyable-box" /> : value;

  return (
    <ItemBox>
      {icon}
      <Box sx={{width: '100%'}}>
        {label ? (
          <Typography variant="body3" color="body.placeholder">
            {label}
          </Typography>
        ) : null}

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          {typeof value === 'string' ? (
            <Typography variant="body1" color="body.primary" sx={{width: '100%'}} {...ValueTypographyProps}>
              {valueNode}
            </Typography>
          ) : (
            <Box sx={{display: 'flex', alignItems: 'center'}}>{valueNode}</Box>
          )}

          {!copyable && onManageBtnClick ? (
            <Button variant="outlined">
              {manageButtonTitle || t('server_info_overview_manage_configuration_btn')}
            </Button>
          ) : null}
        </Box>
      </Box>
    </ItemBox>
  );
};

export default OverviewItem;
