import Div100vh from '../../components/Div100vh';

export const NotificationsPage: React.FC = () => {
  return (
    <Div100vh className="notifications-page">
      <div>NotificationsPage</div>
    </Div100vh>
  );
};

export default NotificationsPage;
