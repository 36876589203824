import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';
import {DateRange} from '@mui/x-date-pickers-pro';
import cn from 'classnames';
import {Dayjs} from 'dayjs';
import {observer} from 'mobx-react';
import React from 'react';
import {TableVirtuoso} from 'react-virtuoso';
import {ca2billing, ca2types} from '../../../api/proto';
import {ReactComponent as TableSortDefaultIcon} from '../../../assets/icons/table-sort-default.svg';
import {ReactComponent as TableSortIcon} from '../../../assets/icons/table-sort.svg';
import DateRangeDropdown, {IDateRangeDropdownRef} from '../../../components/DateRangeDropdown';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/UI/Loader';
import Typography from '../../../components/UI/Typography';
import {PAGINATION_PAGE_SIZE} from '../../../constants';
import {t} from '../../../i18n';
import {useStore} from '../../../stores/AppStore';
import {SortOrder} from '../../../utils/tableSort';
import OperationTypeFilter from '../OperationTypeFilter';
import BillingOperationTableRow from './BalanceOperationsTableRow';
import NoBalanceOperationsFound from './NoBalanceOperationsFound';

export enum BalanceOperationColumnType {
  Id = 'id',
  Type = 'type',
  Date = 'date',
  Description = 'description',
  Amount = 'amount',
}

type Column = {
  id: BalanceOperationColumnType;
  label: string;
  sortable?: boolean;
  width?: number;
  align?: 'left' | 'right';
};

const COLUMN_MIN_WIDTH = 150; //px;

export const BALANCE_OPERATIONS_TABLE_COLUMNS: Column[] = [
  {
    id: BalanceOperationColumnType.Date,
    label: t('billing_balance_operation_date_column'),
    sortable: true,
  },
  {
    id: BalanceOperationColumnType.Id,
    label: t('billing_balance_operation_id_column'),
    width: COLUMN_MIN_WIDTH,
  },
  {
    id: BalanceOperationColumnType.Type,
    label: t('billing_balance_operation_type_column'),
  },
  {
    id: BalanceOperationColumnType.Description,
    label: t('billing_balance_operation_description_column'),
  },
  {
    id: BalanceOperationColumnType.Amount,
    label: t('billing_balance_operation_amount_column'),
    sortable: true,
    width: COLUMN_MIN_WIDTH,
    align: 'right',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} sx={{borderRadius: 0}} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}} />,
  TableRow: ({...props}) => <TableRow {...props} hover />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

const BorderBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.backgroundSecondary,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  border: `1px solid ${theme.palette.border.primary}`,
  borderBottom: 'none',
  '&.bottom': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
  },
}));

const TableBox = styled(Box)(({theme}) => ({
  flex: '1 1 100%',

  '&.table-hidden': {
    border: `1px solid ${theme.palette.border.primary}`,
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
  },

  '&.loading .MuiTableContainer-root': {
    opacity: '0.5 !important',
  },

  '& .table-loader': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const BalanceOperationsTable: React.FC = observer(() => {
  const theme = useTheme();
  const {billingStore} = useStore();
  const {balanceOperations} = billingStore;

  const dateRangeDropdownRef = React.useRef<IDateRangeDropdownRef>(null);

  const [orderBy, setOrderBy] = React.useState<BalanceOperationColumnType>(BalanceOperationColumnType.Date);

  const handleResetFilters = () => {
    setOrderBy(BalanceOperationColumnType.Date);
    dateRangeDropdownRef.current?.reset();
    balanceOperations.resetFilters();
  };

  const handleRequestSort = (_, property: BalanceOperationColumnType) => {
    let order_ = balanceOperations.sortOrder;

    if (property === BalanceOperationColumnType.Amount) {
      const isAsc = balanceOperations.sortOrder === ca2billing.BalanceOperationSort.BOS_AMOUNT_ASC;
      order_ = isAsc ? ca2billing.BalanceOperationSort.BOS_AMOUNT_DESC : ca2billing.BalanceOperationSort.BOS_AMOUNT_ASC;
    }

    if (property === BalanceOperationColumnType.Date) {
      const isAsc = balanceOperations.sortOrder === ca2billing.BalanceOperationSort.BOS_CREATED_AT_ASC;
      order_ = isAsc
        ? ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC
        : ca2billing.BalanceOperationSort.BOS_CREATED_AT_ASC;
    }

    setOrderBy(property);
    balanceOperations.setFilter({sortOrder: order_});
  };

  const handleChangePage = (page: number) => {
    balanceOperations.setFilter({currentPage: page});
  };

  const handleToggleOperationType = (operationTypes: ca2types.BalanceOperationType[]) => {
    balanceOperations.setFilter({operationTypes});
  };

  const handleChangeRange = (dateRange: DateRange<Dayjs>) => {
    balanceOperations.setFilter({dateRange});
  };

  const pagesCount = Math.round(balanceOperations.total / PAGINATION_PAGE_SIZE);

  function fixedHeaderContent() {
    return (
      <TableRow>
        {BALANCE_OPERATIONS_TABLE_COLUMNS.map((column) => {
          const active = orderBy === column.id;
          const direction =
            [
              ca2billing.BalanceOperationSort.BOS_AMOUNT_DESC,
              ca2billing.BalanceOperationSort.BOS_CREATED_AT_DESC,
            ].includes(balanceOperations.sortOrder) && orderBy === column.id
              ? SortOrder.desc
              : SortOrder.asc;

          const label = (
            <Typography variant="label2" color="body.primary">
              {column.label}
            </Typography>
          );

          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{width: column.width, borderLeft: 'none', borderRight: 'none'}}
            >
              {column.sortable ? (
                <TableSortLabel
                  active={active}
                  direction={direction}
                  onClick={(event) => handleRequestSort(event, column.id)}
                  IconComponent={active ? TableSortIcon : TableSortDefaultIcon}
                >
                  {label}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  return (
    <Box
      sx={{
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        width: '100%',
        position: 'relative',
      }}
    >
      <BorderBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="label2" color="body.placeholder">
          {t('billing_balance_operation_table_title')}
        </Typography>

        <div>
          <OperationTypeFilter
            availableTypes={balanceOperations.operationTypes}
            onToggleTypes={handleToggleOperationType}
          />
          <DateRangeDropdown ref={dateRangeDropdownRef} onChange={handleChangeRange} />
        </div>
      </BorderBox>

      <TableBox className={cn({'table-hidden': !balanceOperations.list.length, loading: balanceOperations.loading})}>
        {balanceOperations.list.length || balanceOperations.loading ? (
          <TableVirtuoso
            style={{opacity: balanceOperations.loading ? 0.5 : 1}}
            data={balanceOperations.list}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={(index, billingOperation) => (
              <BillingOperationTableRow billingOperation={billingOperation} key={index} />
            )}
          />
        ) : !balanceOperations.loading ? (
          <NoBalanceOperationsFound filterUsed={balanceOperations.isFilterUsed} onResetFilter={handleResetFilters} />
        ) : null}

        <Loader className="table-loader" loading={balanceOperations.loading} />
      </TableBox>

      {pagesCount > 1 ? (
        <BorderBox className="bottom">
          <Pagination
            pagesCount={pagesCount}
            page={balanceOperations.currentPage}
            onChange={handleChangePage}
            onGoToPage={handleChangePage}
          />
        </BorderBox>
      ) : null}

      <Loader
        style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}
        loading={balanceOperations.loading}
      />
    </Box>
  );
});

export default BalanceOperationsTable;
