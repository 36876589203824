import {createBrowserHistory} from 'history';

export interface IBrowserHistory {
  from?: {
    pathname?: string;
  };
}

export const browserHistory = createBrowserHistory();

export default browserHistory;
