import {action, makeObservable, observable} from 'mobx';
import {ca2domains, ca2types} from '../../api/proto';
import APILayer from '../APILayer';
import DomainsStore from './DomainsStore';

export class DomainContactsStore extends APILayer {
  constructor(public domainsStore: DomainsStore) {
    super(domainsStore.app);
    makeObservable(this);
  }

  @observable isInit: boolean = false;

  @observable list: ca2types.IDomainContact[] = [];

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadContacts_();

    this.isInit = true;
  };

  private loadContacts_ = async () => {
    const {res, error} = await this.request({
      domains: {
        contactsList: {},
      },
    });

    if (res?.domains?.contactsList) {
      this.processLoadContacts_(res.domains.contactsList);
    }

    return {error, res: res?.domains?.contactsList};
  };

  private processLoadContacts_ = (res: ca2domains.IContactsListResponse) => {
    if (res.contacts?.length) {
      this.list = res.contacts;
    }
  };

  createOrUpdateContact = async (contact: ca2types.IDomainContact) => {
    const {res, error} = await this.request({
      domains: {
        createOrUpdateContact: {
          contact,
        },
      },
    });

    if (res?.domains?.createOrUpdateContact) {
      this.processCreateOrUpdateContact_(res.domains.createOrUpdateContact);
    }

    return {error, res: res?.domains?.createOrUpdateContact};
  };

  private processCreateOrUpdateContact_ = (res: ca2domains.ICreateOrUpdateContactResponse) => {
    if (res.contact) {
      this.list.unshift(res.contact);
      this.list = this.list.slice();
    }
  };

  @action reset = () => {
    this.list = [];

    this.isInit = false;
  };
}

export default DomainContactsStore;
