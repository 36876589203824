import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types, ca2utils} from '../api/proto';
import {PASSWORD_MIN_LENGTH, SERVER_NAME_MAX_LENGTH, SSH_KEY_MAX_LENGTH} from '../constants';
import APILayer from './APILayer';
import {AppStore} from './AppStore';

export class UtilsStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);
  }

  @observable isInit = false;
  @observable private options_: ca2utils.IOptionsResponse | null = null;

  @computed get oauthProviders(): ca2types.IOAuthProvider[] {
    return this.options_?.oauthProviders || [];
  }

  @computed get recaptchaEnabled(): boolean {
    return this.options_?.recaptchaEnabled || false;
  }

  @computed get referralEnabled(): boolean {
    return this.options_?.referralEnabled || false;
  }

  @computed get minPasswordLength(): number {
    return this.options_?.minPasswordLength || PASSWORD_MIN_LENGTH;
  }

  @computed get maxSshKeyLength(): number {
    return this.options_?.maxSshKeyLength || SSH_KEY_MAX_LENGTH;
  }

  @computed get maxServerNameLength(): number {
    return this.options_?.maxServerNameLength || SERVER_NAME_MAX_LENGTH;
  }

  init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadOptions_();

    this.isInit = true;
  };

  private loadOptions_ = async (): Promise<void> => {
    const {res} = await this.request({
      utils: {
        options: {},
      },
    });

    if (res?.utils) {
      this.processLoadOptions_(res.utils);
    }
  };

  @action private processLoadOptions_ = ({options}: ca2utils.IResponse) => {
    if (options) {
      this.options_ = options;
    }
  };

  @action reset = () => {
    this.isInit = false;
    this.options_ = null;
  };
}

export default UtilsStore;
