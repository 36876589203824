import React from 'react';
import {ReactComponent as CpuIcon} from '../../../assets/icons/cpu.svg';
import {ReactComponent as RamIcon} from '../../../assets/icons/ram.svg';
import {ReactComponent as SpeedIcon} from '../../../assets/icons/speed.svg';
import {ReactComponent as StorageIcon} from '../../../assets/icons/storage.svg';
import Typography from '../../../components/UI/Typography';
import {Plan} from '../../../stores/Plan';

interface IProps {
  plan: Plan;
}

const HardwareInfo: React.FC<IProps> = ({plan}) => {
  return (
    <div className="plan-card__hardware-info">
      <div className="plan-card__hardware-info-item">
        <div className="plan-card__hardware-info-icon">
          <CpuIcon />
        </div>
        <Typography variant="body2">{plan.cpuInfo}</Typography>
      </div>
      <div className="plan-card__hardware-info-item">
        <div className="plan-card__hardware-info-icon">
          <RamIcon />
        </div>
        <Typography variant="body2">{plan.ramSizeFormatted}</Typography>
      </div>
      <div className="plan-card__hardware-info-item">
        <div className="plan-card__hardware-info-icon">
          <StorageIcon />
        </div>
        <Typography variant="body2">{plan.storageSizeFormatted}</Typography>
      </div>
      <div className="plan-card__hardware-info-item">
        <div className="plan-card__hardware-info-icon">
          <SpeedIcon />
        </div>
        <Typography variant="body2">{plan.transferFormatted}</Typography>
      </div>
    </div>
  );
};

export default HardwareInfo;
