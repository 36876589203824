import {LoadingButton} from '@mui/lab';
import {Typography} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import PasswordInput from '../../components/UI/PasswordInput';
import PasswordSmartInput, {PASSWORD_VALIDATIONS} from '../../components/UI/PasswordSmartInput';
import {TextField} from '../../components/UI/TextField';
import {useStore} from '../../stores/AppStore';

interface IProps {
  fullName: string | null;
  onSubmit?(): void;
}

const PersonalInfoForm: React.FC<IProps> = observer((props) => {
  const {userStore, notification, utils} = useStore();
  const {t} = useTranslation();

  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      fullName: props.fullName || '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string(),
      password: Yup.string()
        .required()
        .min(utils.minPasswordLength)
        .matches(PASSWORD_VALIDATIONS.capitalLetterRegexp)
        .matches(PASSWORD_VALIDATIONS.numberCharRegexp),
      passwordConfirmation: Yup.string()
        .required(t('auth_personal_info_form_password_confirm_required'))
        .nullable()
        .oneOf([Yup.ref('password'), null], t('auth_personal_info_form_password_confirm_validation')),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {res, error} = await userStore.savePersonalInfoOnRegistration(values);

      if (error) {
        notification.error(error.message);
        setLoading(false);
        return;
      }

      if (res?.user) {
        props.onSubmit && props.onSubmit();
      }
      setLoading(false);
    },
    onReset: () => {
      setLoading(false);
    },
  });

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_personal_info_form_title')}
      </Typography>
      <Typography variant="h3" sx={(theme) => ({marginBottom: theme.spacing(6), color: theme.palette.body.tertiary})}>
        {t('auth_personal_info_form_subtitle')}
      </Typography>

      <TextField
        fullWidth
        name="fullName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fullName}
        placeholder={t('auth_personal_info_form_name_placeholder')}
        label={t('auth_personal_info_form_name_label')}
        error={!!formik.errors.fullName}
        helperText={formik.errors.fullName}
        autoFocus={!props.fullName}
      />

      <PasswordSmartInput
        fullWidth
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        placeholder={t('auth_personal_info_form_password_placeholder')}
        label={t('auth_personal_info_form_password_label')}
        error={!!formik.errors.password}
        helperText={formik.errors.password}
        autoFocus={!!props.fullName}
      />

      <PasswordInput
        fullWidth
        name="passwordConfirmation"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.passwordConfirmation}
        placeholder={t('auth_personal_info_form_password_confirm_placeholder')}
        label={t('auth_personal_info_form_password_confirm_label')}
        error={!!formik.errors.passwordConfirmation}
        helperText={formik.errors.passwordConfirmation}
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_personal_info_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default PersonalInfoForm;
