import {action, computed, makeObservable, observable} from 'mobx';
import {ca2instances, ca2types} from '../../api/proto';
import logger from '../../utils/logger';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Instance from './Instance';
import {InstanceFilter} from './InstanceFilter';

export class InstancesStore extends APILayer {
  @observable filter: InstanceFilter = new InstanceFilter(this);

  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.wsApi.on('instances.updatedInstances', this.onUpdateInstances_);
  }

  @observable instances: Instance[] = [];
  @observable isInit: boolean = false;

  @computed get filteredInstances() {
    return this.filter.filteredInstances;
  }

  @computed get hasInstances(): boolean {
    return !!this.instances.length;
  }

  @action reset = () => {
    this.instances = [];
    this.isInit = false;
  };

  @action clear = () => {
    this.filter.clearSearch();
  };

  findInstanceById = (instanceId?: number | null) => {
    return this.instances.find(({id}) => instanceId && instanceId === id) || null;
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadInstances_();

    this.isInit = true;
  };

  private loadInstances_ = async () => {
    const {res} = await this.request({
      instances: {
        instanceList: {
          sortOrder: ca2instances.InstanceSortOrder.ISO_CREATED_AT_DESC,
        },
      },
    });

    if (res?.instances?.instanceList) {
      logger.debug(`%c<--- Response [instanceList]`, 'color: gray', res?.instances?.instanceList.items);
      this.processLoad_(res.instances.instanceList);
    }
  };

  reloadInstances = () => {
    this.loadInstances_();
  };

  @action private processLoad_ = (res: ca2instances.IInstanceListResponse) => {
    if (res.items) {
      this.instances = res.items.map((rawInstance) => new Instance(rawInstance, this));
    }
  };

  private createInstanceRequest_ = (data: ca2instances.ICreateInstanceRequest) => {
    return this.request({
      instances: {
        createInstance: data,
      },
    });
  };

  validateInstanceRequestData = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data, dryRun: true});
    return {error, res: res?.instances?.createInstance};
  };

  createInstance = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data});

    if (res?.instances?.createInstance) {
      this.processCreateInstance_(res.instances.createInstance);
    }

    return {error, res: res?.instances?.createInstance};
  };

  @action private processCreateInstance_ = (res: ca2instances.ICreateInstanceResponse) => {
    if (res.instance) {
      this.instances = [...this.instances, new Instance(res.instance, this)];
    }
  };

  @action deleteInstanceProcess_ = (instanceId: number | null) => {
    this.instances = this.instances.filter(({id}) => id !== instanceId);
  };

  private onUpdateInstances_ = (rawInstances: ca2types.IInstance[]) => {
    for (const raw of rawInstances) {
      const foundInstance = this.findInstanceById(raw.id);
      foundInstance?.update(raw);
    }
  };
}

export default InstancesStore;
