export function convertStringToNumber(value: string | number): number {
  let val = value;
  if (typeof val === 'string') {
    val = val.replace(/[^0-9.]/g, '');
  }

  const numerical = Number(val);
  if (isNaN(numerical)) {
    return 0;
  }

  return numerical;
}

export default convertStringToNumber;
