
interface EventEmitterObservers {
  [event: string]: EventEmitterObserver;
}

type EventEmitterObserver = EventEmitterListener[];

type EventEmitterListenerSync = (...args: any[]) => void;
type EventEmitterListenerAsync = (...args: any[]) => Promise<void>;
export type EventEmitterListener = EventEmitterListenerSync | EventEmitterListenerAsync;


export interface IEventEmitter {
  on(events: string, listener: EventEmitterListener): IEventEmitter;

  once(events: string, listener: EventEmitterListener): IEventEmitter;

  off(event: string, listener: EventEmitterListener): void;
}

export class EventEmitter implements IEventEmitter {
  protected observers: EventEmitterObservers = {};

  constructor() {
    this.observers = {};
  }

  public on(events: string, listener: EventEmitterListener) {
    events.split(' ').forEach((event) => {
      this.observers[event] = this.observers[event] || [];
      this.observers[event].push(listener);
    });
    return this;
  }

  public once(events: string, listener: EventEmitterListener) {
    events.split(' ').forEach((event) => {
      this.observers[event] = this.observers[event] || [];
      this.observers[event].push(listener);
    });

    this.off(events, listener);

    return this;
  }

  public off(event: string, listener: EventEmitterListener) {
    if (!this.observers[event]) return;
    if (!listener) {
      delete this.observers[event];
      return;
    }

    this.observers[event] = this.observers[event].filter((l) => l !== listener);
  }

  protected emit = async (event: string, ...args: any[]) => {
    if (this.observers[event]) {
      const cloned = ([] as EventEmitterObserver).concat(this.observers[event]);

      for (const observer of cloned) {
        await observer(...args);
      }
      /*
      cloned.forEach((observer) => {
        observer(...args);
      });
      */
    }

    if (this.observers['*']) {
      const cloned = ([] as EventEmitterObserver).concat(this.observers['*']);
      cloned.forEach((observer) => {
        observer.apply(observer, [event, ...args]);
      });
    }
  };
}

export default EventEmitter;
