import {PaletteOptions} from '@mui/material/styles';
import semanticColors from './semanticColors';

const {light: lightColors, dark: darkColors} = semanticColors;

export const buildPalette = (isLightTheme: boolean): PaletteOptions => {
  const palette = isLightTheme ? lightColors : darkColors;

  return {
    mode: isLightTheme ? 'light' : 'dark',
    backgroundPrimary: palette.background.primary,
    backgroundSecondary: palette.background.secondary,
    backgroundTertiary: palette.background.tertiary,
    backgroundQuaternary: palette.background.quaternary,

    body: palette.body,
    border: palette.border,
    brand: palette.brand,
    shadow: palette.shadow,
    error: {
      main: palette.error.primary,
      ...palette.error,
    },
    success: {
      main: palette.success.primary,
      ...palette.success,
    },
    warning: {
      main: palette.warning.primary,
      ...palette.warning,
    },
  };
};

export default buildPalette;
