
function logsLevel(): string {
  try {
    return window.logsLevel || '';
  } catch (e) {
    console.log('Error getting logsLevel', e);
  }
  return '';
}

function logsEnabled(): boolean {
  return logsLevel() !== 'hidden';
}

function allowedLogsLevel(level: string): boolean {
  return logsEnabled() && (!logsLevel() || logsLevel() === level);
}

export class Logger {
  static log = (...args: any) => {
    if (logsEnabled()) {
      console.log.apply(console.log, args);
    }
  };

  static debug = (...args: any) => {
    if (logsEnabled()) {
      console.debug.apply(console.debug, args);
    }
  };

  static error = (...args: any) => {
    if (logsEnabled()) {
      console.error.apply(console.error, args);
    }
  };

  static warn = (...args: any) => {
    if (logsEnabled()) {
      console.warn.apply(console.warn, args);
    }
  };

  constructor(protected namespace?: string) {
  }

  protected formatArgs_ = (args: any) => {
    if (this.namespace) {
      return [this.namespace, ...args];
    }
    return args;
  };

  public log = (...args: any) => {
    if (allowedLogsLevel('log')) {
      Logger.log.apply(Logger.log, this.formatArgs_(args));
    }
  };

  public debug = (...args: any) => {
    if (allowedLogsLevel('debug')) {
      Logger.debug.apply(Logger.debug, this.formatArgs_(args));
    }
  };

  public error = (...args: any) => {
    if (allowedLogsLevel('error')) {
      Logger.error.apply(Logger.error, this.formatArgs_(args));
    }
  };

  public warn = (...args: any) => {
    if (allowedLogsLevel('warn')) {
      Logger.warn.apply(Logger.warn, this.formatArgs_(args));
    }
  };
}

export default Logger;
