import {action, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';

export class PaymentOption {
  @observable title: string | null = null;
  @observable private amount: ca2types.IAmount | null = null;

  @observable amountFormatter: PriceFormatter;

  constructor(public raw: ca2types.IPaymentOption) {
    makeObservable(this);
    this.update_(raw);

    this.amountFormatter = new PriceFormatter(raw.amount);
  }

  @action private update_ = (raw: ca2types.ICurrency) => {
    Object.assign(this, raw);
  };
}

export default PaymentOption;
