import {LoadingButton} from '@mui/lab';
import {Button, DialogActions, DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDeleteSshKeyError} from '../api/getApiError';
import Typography from '../components/UI/Typography';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalDeleteSshKey: React.FC<IProps> = observer((props) => {
  const {sshKeysStore, notification} = useStore();
  const {t} = useTranslation();

  const modalRef = React.useRef<ModalDialogRef>(null);

  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    modalRef.current?.close();
  };

  const handleSubmit = async () => {
    if (!props.modal.data.sshKey?.id) {
      return;
    }

    setLoading(true);

    const {res, error} = await sshKeysStore.deleteSshKey({id: props.modal.data.sshKey.id});

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiDeleteSshKeyError(error);
          notification.error(errorMessage);
        });

        setLoading(false);
        return;
      }

      notification.success(t('modal_delete_ssh_key_success_notification'));
      handleClose();
    }

    setLoading(false);
  };

  return (
    <ModalDialog
      modal={props.modal}
      ref={modalRef}
      maxWidth="sm"
      fullWidth
      headerText={t('modal_delete_ssh_key_title')}
    >
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {t('modal_delete_ssh_key_subtitle')}
        </Typography>
        <Typography variant="body1">{t('modal_delete_ssh_key_description')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <LoadingButton size="large" variant="contained" color="error" onClick={handleSubmit} loading={loading}>
          {t('action_button_delete')}
        </LoadingButton>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalDeleteSshKey;
