import {Box, styled} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '../../components/UI/Typography';

const StyledBox = styled(Box)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  margin: theme.spacing(4),
  marginBottom: 0,
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  color: theme.palette.body.tertiary,
}));

interface IProps {
  searchValue: string;
  foundAmount: number;
}

export const SearchResultsBar: React.FC<IProps> = ({searchValue, foundAmount}) => {
  const {t} = useTranslation();

  if (!searchValue) {
    return null;
  }

  return (
    <StyledBox>
      <Typography variant="body2">
        {foundAmount ? t('page_search_found_items', {amount: foundAmount}) : t('page_search_not_found_items')}
      </Typography>
    </StyledBox>
  );
};

export default SearchResultsBar;
