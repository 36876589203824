import {Box, BoxProps, Typography, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';

type IProps = BoxProps & {
  balanceFormatted: string;
};

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  textAlign: 'center',
  '& .MuiTypography-body3': {
    marginBottom: theme.spacing(1),
  },
}));

export const BalanceTitle: React.FC<IProps> = ({balanceFormatted, ...restProps}) => {
  const {t} = useTranslation();
  return (
    <Container {...restProps}>
      <Typography variant="body3">{t('component_payment_viewer_balance')}</Typography>
      <Typography variant="h4">{balanceFormatted}</Typography>
    </Container>
  );
};

export default BalanceTitle;
