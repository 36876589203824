import {LoadingButton} from '@mui/lab';
import {Box, Button, FormHelperText, InputLabel, styled} from '@mui/material';
import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {matchPath, useLocation, useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getApiAuthEnterCodeError} from '../../api/getApiError';
import {ca2types} from '../../api/proto';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning-orange.svg';
import CheckboxField from '../../components/UI/CheckboxField';
import PinInput from '../../components/UI/PinInput';
import Typography from '../../components/UI/Typography';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {ForgotPasswordPageStep} from './ForgotPasswordPage';

const YourEmailBox = styled(Box)(({theme}) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(6),
  '& .MuiButton-root': {
    marginLeft: 'auto',
  },
  '& .MuiTypography-body3': {
    color: theme.palette.body.tertiary,
  },
  '& .MuiTypography-body2': {
    fontWeight: theme.size.fontWeight.bold,
  },
}));

const NewUserInfoBox = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  backgroundColor: theme.palette.backgroundSecondary,
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(6),
  '& .warning-icon': {
    marginRight: theme.spacing(3),
  },
  '& .MuiTypography-body3': {
    color: theme.palette.body.tertiary,
  },
  '& .MuiTypography-body2': {
    fontWeight: theme.size.fontWeight.bold,
  },
}));

type LocationState = {
  email?: string;
  isNewUser?: boolean;
  codeParams?: ca2types.ICode | null;
};

interface IProps {
  isRegistration?: boolean;
  onSubmit?(userFullName?: string | null): void;
  onEditEmail?(): void;
}

const DEFAULT_CODE_LENGTH = 6;

const VerificationCodeForm: React.FC<IProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const {notification, authStore} = useStore();
  const {t} = useTranslation();

  const isAuthPath = matchPath(Paths.Authorization, location.pathname);

  const [hasApiError, setHasApiError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isAgreeWithTermsOfUse, setIsAgreeWithTermsOfUse] = React.useState(false);

  const codeLength = state?.codeParams?.length || DEFAULT_CODE_LENGTH;
  const email = state?.email || '';
  const isNewUser = state?.isNewUser || false;

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required(t('auth_verification_code_form_code_required'))
        .min(codeLength, t('auth_verification_code_form_code_validation', {length: codeLength})),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      setHasApiError(false);
      const {error, res} = await authStore.enterCode({code: values.code, email: email});

      if (error) {
        notification.error(error.message);
        setHasApiError(true);
      }

      if (res) {
        const {errors, successStep, enterPasswordStep} = res;

        if (errors?.length) {
          errors.forEach((key) => {
            notification.error(getApiAuthEnterCodeError(key));
            setHasApiError(true);
          });
          setLoading(false);
          return;
        }

        if (!successStep?.token && !enterPasswordStep) {
          notification.error(t('auth_verification_code_form_notification_access_token_not_found'));
          setLoading(false);
          return;
        }

        props.onSubmit?.(successStep?.user?.fullName);

        if (!props.isRegistration) {
          navigate(Paths.ForgotPassword, {state: {step: ForgotPasswordPageStep.CREATE_PASSWORD}, replace: true});
        }
      }

      setLoading(false);
    },
    onReset: () => {
      setHasApiError(false);
      setLoading(false);
    },
  });

  const handleChangeCode = async (value: string) => {
    await formik.setFieldValue('code', value, false);

    if (!isAgreeWithTermsOfUse && props.isRegistration) {
      return;
    }

    if (value.length === codeLength) {
      formik.submitForm();
    }
  };

  const handleEditEmail = () => {
    navigate(isAuthPath ? Paths.Authorization : Paths.ForgotPassword, {state: {email: email}, replace: true});
    props.onEditEmail?.();
  };

  const handleChangeAgreeWithRemsOfUse = () => {
    setIsAgreeWithTermsOfUse(!isAgreeWithTermsOfUse);
  };

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      {isNewUser && (
        <NewUserInfoBox>
          <WarningIcon className="warning-icon" />
          <Box>
            <Typography variant="body2">{t('auth_verification_code_form_new_user_notification_title')}</Typography>
            <Typography variant="body3">{t('auth_verification_code_form_new_user_notification_subtitle')}</Typography>
          </Box>
        </NewUserInfoBox>
      )}
      <Typography variant="h1" gutterBottom>
        {t('auth_verification_code_form_title')}
      </Typography>
      <Typography variant="h3" sx={(theme) => ({marginBottom: theme.spacing(5), color: theme.palette.body.tertiary})}>
        {t('auth_verification_code_form_subtitle')}
      </Typography>

      <YourEmailBox>
        <Box>
          <Typography variant="body3">{t('auth_verification_code_form_email_label')}</Typography>
          <Typography variant="body2">{email}</Typography>
        </Box>
        <Button variant="outlined" size="large" sx={{marginLeft: 'auto'}} onClick={handleEditEmail}>
          {t('auth_verification_code_form_email_edit_button')}
        </Button>
      </YourEmailBox>

      <InputLabel shrink>{t('auth_verification_code_form_code_label')}</InputLabel>
      <PinInput
        className={cn({
          error: formik.errors.code || hasApiError,
        })}
        name="code"
        onChange={handleChangeCode}
        value={formik.values.code}
        fields={codeLength}
        autoFocus
      />
      <FormHelperText error>{formik.errors.code || ' '}</FormHelperText>

      {props.isRegistration && (
        <>
          <Box sx={{a: {textDecoration: 'underline'}}}>
            <CheckboxField
              label={
                <Trans
                  i18nKey="auth_verification_code_form_terms_label"
                  values={{termsOfUseUrl: '#'}}
                  components={{a: <a />}}
                />
              }
              onChange={handleChangeAgreeWithRemsOfUse}
              checked={isAgreeWithTermsOfUse}
            />
          </Box>

          <Typography
            variant="body2"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              color: theme.palette.body.placeholder,
              a: {textDecoration: 'underline'},
            })}
          >
            <Trans
              i18nKey="auth_verification_code_form_terms"
              values={{termsOfUseUrl: '#', privacyNotesUrl: '#', cookieNoticeUrl: '#'}}
              components={{a: <a />}}
            />
          </Typography>
        </>
      )}

      <LoadingButton
        fullWidth
        variant="contained"
        type="submit"
        sx={(theme) => ({marginTop: theme.spacing(4)})}
        size="large"
        loading={loading}
        disabled={!isAgreeWithTermsOfUse && props.isRegistration}
      >
        {t(
          props.isRegistration
            ? 'auth_verification_code_form_submit'
            : 'auth_verification_code_form_on_reset_password_submit',
        )}
      </LoadingButton>
    </form>
  );
});

export default VerificationCodeForm;
