import {generatePath} from 'react-router';
import {DomainInfoTabs} from '../pages/DomainInfoPage/DomainInfoViewer';
import {DomainsPageTab} from '../pages/DomainsPage/DomainsPage';
import {ServerInfoTab} from '../pages/ServerInfoPage/ServerInfoViewer';
import {Domain} from '../stores/Domain';
import {Instance} from '../stores/Instance';
import Paths from './Paths';
import browserHistory from './browserHistory';

export const navigateToServerInfo = (instance: Instance) => {
  browserHistory.push(
    generatePath(Paths.ServerInfo, {
      instanceId: instance.id ? `${instance.id}` : '',
      tabType: ServerInfoTab.OVERVIEW,
    }),
  );
};

export const navigateToDomains = (tab?: DomainsPageTab) => {
  browserHistory.push(
    generatePath(Paths.Domains, {
      tabType: tab || DomainsPageTab.Search,
    }),
  );
};

export const navigateToDomainInfo = (domain: Domain, infoTabType = DomainInfoTabs.Overview) => {
  browserHistory.push(
    generatePath(Paths.DomainInfo, {
      domainId: domain.domainId ? `${domain.domainId}` : '',
      tabType: DomainsPageTab.MyDomains,
      infoTabType,
    }),
  );
};
