import {useTheme} from '@mui/material';
import React from 'react';
import {ca2types} from '../api/proto';

export function useInstanceStatusColor(status?: ca2types.InstanceStatus | null): {
  statusColor: string | undefined;
  statusBackground: string | undefined;
} {
  const {palette} = useTheme();

  return React.useMemo(() => {
    switch (status) {
      case ca2types.InstanceStatus.IS_UNKNOWN:
      case ca2types.InstanceStatus.IS_ERROR:
        return {
          statusColor: palette.error.primary,
          statusBackground: palette.error.alpha2,
        };
      case ca2types.InstanceStatus.IS_ACTIVE:
        return {
          statusColor: palette.success.primary,
          statusBackground: palette.success.alpha2,
        };
      case ca2types.InstanceStatus.IS_STOPPED:
      case ca2types.InstanceStatus.IS_STOPPING:
      case ca2types.InstanceStatus.IS_STARTING:
      case ca2types.InstanceStatus.IS_PROCESSING:
      case ca2types.InstanceStatus.IS_CREATING:
      case ca2types.InstanceStatus.IS_REINSTALLING:
      case ca2types.InstanceStatus.IS_RESTARTING:
      case ca2types.InstanceStatus.IS_DELETING:
      case ca2types.InstanceStatus.IS_RESIZING:
      case ca2types.InstanceStatus.IS_DELETED:
        return {
          statusColor: palette.warning.primary,
          statusBackground: palette.warning.alpha2,
        };

      default:
        return {
          statusColor: palette.error.primary,
          statusBackground: palette.error.alpha2,
        };
    }
  }, [palette, status]);
}
