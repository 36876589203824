import {Box, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {getName} from 'country-list';
import React from 'react';
import {ca2types} from '../../../api/proto';
import CheckboxField from '../../../components/UI/CheckboxField';
import RadioField from '../../../components/UI/RadioField';
import Typography from '../../../components/UI/Typography';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',

  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },

  '&.selectable:hover': {
    backgroundColor: theme.palette.backgroundSecondary,
    cursor: 'pointer',
  },
}));

interface IProps {
  contact: ca2types.IDomainContact;
  editable?: boolean;
  selectable?: boolean;
  checked?: boolean;
  onSelect?(id: number);
}

export const DomainContact: React.FC<IProps> = ({contact, selectable, editable, checked, onSelect}) => {
  const theme = useTheme();
  const handleSelect = () => {
    if (selectable && contact.id) {
      onSelect?.(contact.id);
    }
  };

  const addressString = [
    getName(contact.mailingCountryCode),
    contact.mailingCity,
    contact.mailingAddress,
    contact.mailingState,
    contact.mailingZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return (
    <Container className={cn({selectable})} onClick={handleSelect}>
      <Box>
        {selectable && editable ? (
          <RadioField checked={checked} sx={{padding: 0}} disableRipple />
        ) : editable ? (
          <CheckboxField sx={{padding: 0}} checked disabled />
        ) : null}
      </Box>

      <Box
        sx={{
          flex: '1 1 auto',
          paddingLeft: theme.spacing(2),
          width: selectable || editable ? 'calc(100% - 30px)' : '100%',
        }}
      >
        <Typography variant="body1" sx={{marginTop: theme.spacing(0), marginBottom: theme.spacing(1)}}>
          <Box
            sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
          >{`${contact.firstName} ${contact.lastName}`}</Box>
        </Typography>

        <Typography
          variant="body2"
          color="body.tertiary"
          sx={{
            marginBottom: theme.spacing(0),
          }}
        >
          <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{addressString}</Box>
        </Typography>
        <Typography variant="body2" color="body.tertiary">
          <Box
            sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
          >{`${contact.emailAddress}, ${contact.phoneNumber}`}</Box>
        </Typography>
      </Box>
    </Container>
  );
};

export default DomainContact;
