import {observer} from 'mobx-react';
import React from 'react';

import {useStore} from '../stores/AppStore';
import ModalWrapper from './ModalWrapper';

export const Modals: React.FC<React.PropsWithChildren> = observer(() => {
  const {modals} = useStore();

  return (
    <>
      {modals.list.map((modal) => (
        <ModalWrapper key={modal.type} modal={modal} />
      ))}
    </>
  );
});

export default Modals;
