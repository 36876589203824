import {observer} from 'mobx-react';
import React from 'react';
import {ReactComponent as LogoImage} from '../../assets/image-icons/logo.svg';
import {UIThemeSwitch} from '../../components/UI/UIThemeSwitch';
import {APP_NAME} from '../../constants';
import {useStore} from '../../stores/AppStore';

const AuthHead: React.FC = observer(() => {
  const {layoutStore} = useStore();

  return (
    <div className="auth-head">
      <div className="auth-head__logo">
        <LogoImage className="auth-head__logo-image" />
        <span className="auth-head__app-name">{APP_NAME}</span>
      </div>

      <UIThemeSwitch
        onChange={layoutStore.uiTheme.toggleCurrentTheme}
        checked={!layoutStore.uiTheme.lightThemeChosen}
      />
    </div>
  );
});

export default AuthHead;
