import {Switch as MuiSwitch, SwitchProps as MuiSwitchProps} from '@mui/material';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

const StyledSwitch = styled(MuiSwitch)(({theme}) => ({
  width: 36,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.backgroundPrimary,
      },
      '& .MuiSwitch-thumb::before': {
        backgroundPosition: '4px 3px',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M8.46973 0.530258C8.81036 1.28347 8.99998 2.11959 8.99998 3C8.99998 6.31371 6.31369 9 2.99999 9C2.11958 9 1.28346 8.81038 0.530243 8.46974C1.4717 10.5515 3.56668 12 5.99999 12C9.31369 12 12 9.31371 12 6C12 3.5667 10.5515 1.47171 8.46973 0.530258Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.backgroundQuaternary,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.body.tint,

    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0px 0px',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        `${theme.palette.warning.primary}`,
      )}" d="M10 3.5C10.2761 3.5 10.5 3.72386 10.5 4V6C10.5 6.27614 10.2761 6.5 10 6.5C9.72386 6.5 9.5 6.27614 9.5 6V4C9.5 3.72386 9.72386 3.5 10 3.5ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10ZM14.5962 6.11093C14.7914 5.91567 14.7914 5.59909 14.5962 5.40383C14.4009 5.20857 14.0843 5.20857 13.8891 5.40383L12.3917 6.90123C12.1964 7.09649 12.1964 7.41308 12.3917 7.60834C12.5869 7.8036 12.9035 7.8036 13.0988 7.60834L14.5962 6.11093ZM16.5 10C16.5 10.2761 16.2761 10.5 16 10.5H14C13.7239 10.5 13.5 10.2761 13.5 10C13.5 9.72386 13.7239 9.5 14 9.5H16C16.2761 9.5 16.5 9.72386 16.5 10ZM13.8891 14.5962C14.0843 14.7914 14.4009 14.7914 14.5962 14.5962C14.7914 14.4009 14.7914 14.0843 14.5962 13.8891L13.0988 12.3917C12.9035 12.1964 12.5869 12.1964 12.3917 12.3917C12.1964 12.5869 12.1964 12.9035 12.3917 13.0988L13.8891 14.5962ZM10 13.5C10.2761 13.5 10.5 13.7239 10.5 14V16C10.5 16.2761 10.2761 16.5 10 16.5C9.72386 16.5 9.5 16.2761 9.5 16V14C9.5 13.7239 9.72386 13.5 10 13.5ZM7.60831 13.0988C7.80357 12.9035 7.80357 12.5869 7.60831 12.3916C7.41304 12.1964 7.09646 12.1964 6.9012 12.3916L5.4038 13.8891C5.20854 14.0843 5.20854 14.4009 5.4038 14.5962C5.59906 14.7914 5.91564 14.7914 6.1109 14.5962L7.60831 13.0988ZM6.5 10C6.5 10.2761 6.27614 10.5 6 10.5H4C3.72386 10.5 3.5 10.2761 3.5 10C3.5 9.72386 3.72386 9.5 4 9.5H6C6.27614 9.5 6.5 9.72386 6.5 10ZM6.9012 7.60835C7.09646 7.80361 7.41304 7.80361 7.60831 7.60835C7.80357 7.41309 7.80357 7.09651 7.60831 6.90124L6.1109 5.40384C5.91564 5.20858 5.59906 5.20858 5.4038 5.40384C5.20854 5.5991 5.20854 5.91569 5.4038 6.11095L6.9012 7.60835Z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.warning.primary,
    boxSizing: 'border-box',
  },
}));

export const UIThemeSwitch: React.FC<MuiSwitchProps> = (props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <StyledSwitch {...props} />
    </Stack>
  );
};
