import {Box, InputLabel, styled} from '@mui/material';
import React from 'react';
import convertStringToNumber from '../../utils/convert/convertStringToNumber';
import {formatCurrency} from '../../utils/format/formatCurrency';
import Slider, {ISliderProps} from './Slider';
import TextField from './TextField';

export interface IPriceRangeSliderProps extends Omit<ISliderProps, 'value' | 'onChange'> {
  label?: string;
  value?: [number, number];
  onChange?(value: [number, number]): void;
}

const Row = styled(Box)(({theme}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(1),
}));

const PriceRangeSlider: React.FC<IPriceRangeSliderProps> = ({value: initialValue, label, onChange, ...rest}) => {
  const [priceRange, setPriceRange] = React.useState<[number, number]>([0, 0]);

  const [startPrice, setStartPrice] = React.useState<string>('');
  const [endPrice, setEndPrice] = React.useState<string>('');

  const handleChange = (e: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setStartPrice(formatCurrency(newValue[0]));
      setEndPrice(formatCurrency(newValue[1]));
      setPriceRange(newValue as [number, number]);
    }

    onChange?.(newValue as [number, number]);
  };

  const formatToCurrency = React.useCallback((value: number | string) => {
    return formatCurrency(convertStringToNumber(value), {
      minimumFractionDigits: 0,
    });
  }, []);

  const handleChangeStartRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartPrice(formatToCurrency(e.target.value));

    const startPrice = convertStringToNumber(e.target.value);

    setPriceRange([startPrice, priceRange[1]]);
    onChange?.([startPrice, priceRange[1]]);
  };

  const handleChangeEndRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndPrice(formatToCurrency(e.target.value));

    const numberValue = convertStringToNumber(e.target.value);
    setPriceRange([priceRange[0], numberValue]);
    onChange?.([priceRange[0], numberValue]);
  };

  React.useEffect(() => {
    setStartPrice(formatToCurrency(priceRange[0]));
    setEndPrice(formatToCurrency(priceRange[1]));
  }, [priceRange, formatToCurrency]);

  React.useEffect(() => {
    if (initialValue) {
      setPriceRange(initialValue);
    }
  }, [initialValue]);

  return (
    <Box sx={{lineHeight: 0}}>
      <InputLabel shrink>{label}</InputLabel>
      <Row>
        <TextField value={startPrice} onChange={handleChangeStartRange} bottomGutter={false} />
        <TextField value={endPrice} onChange={handleChangeEndRange} bottomGutter={false} />
      </Row>
      <Slider {...rest} value={priceRange} onChange={handleChange} />
    </Box>
  );
};

export default PriceRangeSlider;
