import {Box, Button, MenuItem, useTheme} from '@mui/material';
import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowDownIcon} from '../../../assets/icons/arrow-down.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import CpuOrGpuLogo from '../../../components/CpuOrGpuLogo';
import ProviderLogo from '../../../components/ProviderLogo';
import RegionLabel from '../../../components/RegionLabel';
import DropdownMenu from '../../../components/UI/DropdownMenu';
import Typography from '../../../components/UI/Typography';
import {Plan} from '../../../stores/Plan';

interface IProps {
  plan: Plan;
  hideLocations?: boolean;
}

const PlanGeneralInfo: React.FC<IProps> = ({plan, hideLocations}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [regionsMenuEl, setRegionsMenuEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(regionsMenuEl);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setRegionsMenuEl(e.currentTarget);
  };
  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setRegionsMenuEl(null);
  };

  const onClickMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="plan-card__general-info">
      <div className="plan-card__general-info-item">
        <Typography variant="subtitle1">{plan.monthlyRateFormatter.formatted}</Typography>
        <Typography variant="body3">{t('create_server_plan_card_per_month_label')}</Typography>
      </div>
      <div className="plan-card__general-info-item">
        <Typography variant="subtitle1">{plan.hourlyRateFormatter.formatted}</Typography>
        <Typography variant="body3">{t('create_server_plan_card_per_hour_label')}</Typography>
      </div>
      {plan.isGpuServer ? (
        <>
          {plan.gpu?.model && (
            <div className="plan-card__general-info-item plan-card__general-info-item--cpu">
              <CpuOrGpuLogo modelName={plan.gpu?.model} />
              <Typography variant="body1">{plan.gpu?.model}</Typography>
            </div>
          )}
        </>
      ) : (
        <>
          {plan.cpu?.model && (
            <div className="plan-card__general-info-item plan-card__general-info-item--cpu">
              <CpuOrGpuLogo modelName={plan.cpu?.model} />
              <Typography variant="body1">{plan.cpu?.model}</Typography>
            </div>
          )}
        </>
      )}

      {plan.provider && (
        <div className="plan-card__general-info-item plan-card__general-info-item--provider">
          <ProviderLogo providerId={plan.providerId} />
          <Typography variant="body1">{plan.provider.title}</Typography>
        </div>
      )}
      {!hideLocations && plan.regions.length ? (
        <div className="plan-card__general-info-item" style={{padding: 0}}>
          {plan.regions.length === 1 ? (
            <Box sx={{display: 'flex', alignItems: 'center', padding: `0 ${theme.spacing(4)}`, height: '100%'}}>
              <RegionLabel regionOrDatacenter={plan.regions[0]} />
            </Box>
          ) : plan.regions.length > 1 ? (
            <>
              <Button
                variant="text"
                onClick={handleOpenMenu}
                className={cn('plan-card__general-info-location-btn', {open})}
                sx={{padding: `0 ${theme.spacing(4)}!important`, height: '100%'}}
              >
                <LocationIcon width={30} height={30} className="location-icon" />
                {t('create_server_plan_card_region_label', {count: plan.regions.length})}
                <ArrowDownIcon className="arrow-icon" />
              </Button>
              <DropdownMenu anchorEl={regionsMenuEl} open={open} onClose={handleCloseMenu} onClick={onClickMenu}>
                {plan.regions.map((region) => (
                  <MenuItem
                    onClick={handleCloseMenu}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'inherit',
                        cursor: 'inherit',
                      },
                    }}
                    key={region.id}
                  >
                    <RegionLabel regionOrDatacenter={region} />
                  </MenuItem>
                ))}
              </DropdownMenu>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PlanGeneralInfo;
