import {action} from 'mobx';
import {getApiInstanceOperationError, getApiInstanceUpdateError} from '../../api/getApiError';
import {ca2instances} from '../../api/proto';
import APILayer from '../APILayer';
import Instance, {InstanceEvent} from './Instance';

export class InstanceStore extends APILayer {
  constructor(public instance: Instance) {
    super(instance.instancesStore.app);

    instance.on(InstanceEvent.DELETED, this.onDeletedInstance);
  }

  deleteInstance = async () => {
    const {res} = await this.request({
      instances: {
        deleteInstance: {
          instanceId: this.instance.id,
        },
      },
    });

    this.instanceActionErrorsProcess_(res?.instances?.deleteInstance?.errors);
  };

  onDeletedInstance = (instance: Instance) => {
    this.instance.instancesStore.deleteInstanceProcess_(instance.id);
  };

  startInstance = async () => {
    const {res} = await this.request({
      instances: {
        startInstance: {
          instanceId: this.instance.id,
        },
      },
    });

    this.instanceActionErrorsProcess_(res?.instances?.startInstance?.errors);
  };

  restartInstance = async () => {
    const {res} = await this.request({
      instances: {
        restartInstance: {
          instanceId: this.instance.id,
        },
      },
    });

    this.instanceActionErrorsProcess_(res?.instances?.restartInstance?.errors);
  };

  stopInstance = async () => {
    const {res} = await this.request({
      instances: {
        stopInstance: {
          instanceId: this.instance.id,
        },
      },
    });

    this.instanceActionErrorsProcess_(res?.instances?.stopInstance?.errors);
  };

  private instanceActionErrorsProcess_ = (errors?: ca2instances.InstanceOperationError[] | null) => {
    if (errors?.length) {
      errors.forEach((key) => {
        this.instance.instancesStore.app.notification.error(getApiInstanceOperationError(key));
      });
    }
  };

  updateInstance = async (data: ca2instances.IUpdateInstanceRequest) => {
    const {res, error} = await this.request({
      instances: {
        updateInstance: {
          instanceId: this.instance.id,
          ...data,
        },
      },
    });

    const errors = res?.instances?.updateInstance?.errors;

    if (errors) {
      errors.forEach((key) => {
        this.instance.instancesStore.app.notification.error(getApiInstanceUpdateError(key));
      });
    } else if (res?.instances?.updateInstance) {
      this.processInstanceUpdate_(res.instances.updateInstance);
    }

    return {error, res: res?.instances?.updateInstance};
  };

  @action processInstanceUpdate_ = (res: ca2instances.IUpdateInstanceResponse) => {
    if (res.instance) {
      this.instance.update(res.instance);
    }
  };
}

export default InstanceStore;
