import {Box, styled, useTheme} from '@mui/material';
import {getCode, getNames} from 'country-list';
import React, {useMemo, useState} from 'react';
import ReactCountryFlag from 'react-country-flag';
import {useTranslation} from 'react-i18next';
import {formattedCountryCode} from '../utils/regions';
import MenuItem from './UI/MenuItem';
import SearchInput from './UI/SearchInput';
import Selector, {SelectorProps} from './UI/Selector';
import Typography from './UI/Typography';

export const INITIAL_COUNTRY_CODE = 'null';

const SearchBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(2),
  position: 'sticky',
  zIndex: 10,
  top: 0,
  backgroundColor: theme.palette.backgroundSecondary,
}));

export const CountryCodeSelector: React.FC<SelectorProps> = (props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const countries: {label: string; code: string; hidden: boolean}[] = useMemo(() => {
    return getNames().map((name) => ({
      label: name,
      code: getCode(name),
      hidden: false,
    }));
  }, []);

  const filteredCountries = useMemo(() => {
    return countries.map((country) => {
      if (country.label.toLowerCase().startsWith(searchTerm.toLowerCase())) {
        return country;
      } else {
        return {...country, hidden: true};
      }
    });
  }, [searchTerm, countries]);

  const isNothingFound = filteredCountries.every(({hidden}) => hidden);

  return (
    <Selector
      MenuProps={{
        PaperProps: {
          className: 'custom-scroll',
          style: {
            position: 'relative',
            maxHeight: 350,
            maxWidth: 320,
            overflowY: 'auto',
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: '8px',
          },
        },
        disableScrollLock: true,
      }}
      onClose={() => setIsDropdownOpen(false)}
      onOpen={() => {
        setSearchTerm('');
        setIsDropdownOpen(true);
      }}
      {...props}
    >
      <SearchBox>
        <SearchInput
          placeholder={t('component_country_code_selector_search_placeholder')}
          value={searchTerm}
          onClear={() => setSearchTerm('')}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoComplete="off"
          onFocus={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        />
      </SearchBox>
      <MenuItem value={INITIAL_COUNTRY_CODE} sx={{display: isDropdownOpen ? 'none' : 'flex'}}>
        <span style={{color: theme.palette.body.placeholder}}>{t('component_country_code_selector_placeholder')}</span>
      </MenuItem>

      {filteredCountries.map((option) => (
        <MenuItem key={option.code} value={option.code} sx={{display: option.hidden ? 'none' : 'flex'}}>
          <ReactCountryFlag
            countryCode={formattedCountryCode(option.code)}
            className="emojiFlag"
            svg
            style={{
              fontSize: '1.5em',
              lineHeight: '18px',
              marginRight: theme.spacing(2),
              height: '0.9rem',
            }}
          />
          {option.label}
        </MenuItem>
      ))}
      {isNothingFound ? (
        <Typography
          variant="body2"
          color="body.secondary"
          sx={{padding: `${theme.spacing(2)} 0 ${theme.spacing(4)}`, textAlign: 'center'}}
        >
          {t('component_country_code_selector_not_found')}
        </Typography>
      ) : null}
    </Selector>
  );
};

export default CountryCodeSelector;
