import {Box, Grid, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {Instance} from '../../../stores/Instance';
import ServerBenefits from './Benefits';
import Configuration from './Configuration';
import GeneralInfo from './GeneralInfo';

const ContentBox = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(4),
  flex: 1,
}));

interface IProps {
  instance: Instance;
}

export const Overview: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  return (
    <Box sx={{display: 'flex', flex: 1, flexDirection: 'column'}}>
      <Grid item xs={12}>
        <ContentBox sx={{borderBottom: `1px solid ${theme.palette.border.primary}`}}>
          <GeneralInfo instance={props.instance} />
        </ContentBox>
      </Grid>
      <Grid container spacing={0} sx={{flex: 1}}>
        <Grid item xs={12} lg={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <ContentBox sx={{borderRight: `1px solid ${theme.palette.border.primary}`}}>
            <Configuration instance={props.instance} />
          </ContentBox>
        </Grid>
        <Grid item xs={12} lg={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <ContentBox>
            <ServerBenefits instance={props.instance} />
          </ContentBox>
        </Grid>
      </Grid>
    </Box>
  );
});

export default Overview;
