import {Box, useTheme} from '@mui/material';
import validator from 'email-validator';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiDomainContactError} from '../../../api/getApiError';
import {ca2domains, ca2types} from '../../../api/proto';
import CountryCodeSelector, {INITIAL_COUNTRY_CODE} from '../../../components/CountryCodeSelector';
import TextField from '../../../components/UI/TextField';
import {useStore} from '../../../stores/AppStore';
import {ContactsGridBox} from './DomainContacts';

export interface IDomainContactFormRef {
  getFormFields(): Promise<ca2types.IDomainContact | null>;
  setApiErrors(errors?: ca2domains.ContactError[] | null): void;
  setFormValues(newContactFormValues: ca2types.IDomainContact);
  submitForm(): Promise<number | null>;
}

export const DomainContactForm = observer(
  React.forwardRef<IDomainContactFormRef>((_, ref) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {
      notification,
      domainsStore: {contacts},
    } = useStore();

    const setApiErrors = (errors?: ca2domains.ContactError[] | null) => {
      if (!errors) {
        return;
      }

      for (const error of errors) {
        const errorMessage = getApiDomainContactError(error);
        switch (error) {
          case ca2domains.ContactError.COCE_EMAIL_ADDRESS_REQUIRED:
          case ca2domains.ContactError.COCE_EMAIL_ADDRESS_INVALID:
            formik.setFieldError('emailAddress', errorMessage);
            break;
          case ca2domains.ContactError.COCE_FIRST_NAME_REQUIRED:
          case ca2domains.ContactError.COCE_FIRST_NAME_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS:
            formik.setFieldError('firstName', errorMessage);
            break;
          case ca2domains.ContactError.COCE_LAST_NAME_REQUIRED:
          case ca2domains.ContactError.COCE_LAST_NAME_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS:
            formik.setFieldError('lastName', errorMessage);
            break;
          case ca2domains.ContactError.COCE_MAILING_ADDRESS_REQUIRED:
          case ca2domains.ContactError.COCE_MAILING_ADDRESS_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS:
            formik.setFieldError('mailingAddress', errorMessage);
            break;
          case ca2domains.ContactError.COCE_MAILING_CITY_REQUIRED:
          case ca2domains.ContactError.COCE_MAILING_CITY_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS:
            formik.setFieldError('mailingCity', errorMessage);
            break;
          case ca2domains.ContactError.COCE_MAILING_COUNTRY_CODE_REQUIRED:
            formik.setFieldError('mailingCountryCode', errorMessage);
            break;
          case ca2domains.ContactError.COCE_MAILING_STATE_REQUIRED:
          case ca2domains.ContactError.COCE_MAILING_STATE_SHOULD_CONTAIN_ONLY_ASCII_SYMBOLS:
            formik.setFieldError('mailingState', errorMessage);
            break;
          case ca2domains.ContactError.COCE_MAILING_ZIP_REQUIRED:
            formik.setFieldError('mailingZip', errorMessage);
            break;
          case ca2domains.ContactError.COCE_PHONE_NUMBER_REQUIRED:
          case ca2domains.ContactError.COCE_PHONE_NUMBER_INVALID:
            formik.setFieldError('phoneNumber', errorMessage);
            break;
          case ca2domains.ContactError.COCE_INVALID_CONTACT_ID:
          case ca2domains.ContactError.COCE_UNKNOWN:
            notification.error(errorMessage);
            break;
        }
      }
    };

    const formik = useFormik<ca2types.IDomainContact>({
      initialValues: {
        firstName: '',
        lastName: '',
        mailingAddress: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        mailingCountryCode: INITIAL_COUNTRY_CODE,
        emailAddress: '',
        phoneNumber: '',
      },
      validationSchema: Yup.object({
        firstName: Yup.string().required(t('domain_contact_form_first_name_required')),
        lastName: Yup.string().required(t('domain_contact_form_last_name_required')),
        mailingAddress: Yup.string().required(t('domain_contact_form_mailing_address_required')),
        mailingCity: Yup.string().required(t('domain_contact_form_mailing_city_required')),
        mailingState: Yup.string().required(t('domain_contact_form_mailing_state_required')),
        mailingZip: Yup.string().required(t('domain_contact_form_mailing_zip_required')),
        mailingCountryCode: Yup.string()
          .required(t('domain_contact_form_mailing_country_required'))
          .notOneOf([INITIAL_COUNTRY_CODE], t('domain_contact_form_mailing_country_required')),
        emailAddress: Yup.string()
          .required(t('domain_contact_form_email_required'))
          .test('is_email', t('domain_contact_form_email_invalid'), validator.validate),
        phoneNumber: Yup.string().required(t('domain_contact_form_phone_number_required')),
      }),
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (values): Promise<number | null> => {
        const {error, res} = await contacts.createOrUpdateContact(values);

        if (error) {
          notification.error(error.message);
        }

        if (res) {
          if (res.errors?.length) {
            setApiErrors(res.errors);

            return null;
          }

          return res.contact?.id || null;
        }

        return null;
      },
    });

    const getFormFields = async (): Promise<ca2types.IDomainContact | null> => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length) {
        return null;
      }
      return formik.values;
    };

    const setFormValues = (newContactFormValues: ca2types.IDomainContact) => {
      formik.setValues(newContactFormValues);
    };

    const submitForm = async () => {
      const res: number | null = await formik.submitForm();
      return res;
    };

    React.useImperativeHandle(ref, () => ({
      getFormFields,
      setApiErrors,
      setFormValues,
      submitForm,
    }));

    return (
      <Box sx={{marginTop: theme.spacing(4)}}>
        <ContactsGridBox sx={{gridTemplateColumns: '1fr 1fr'}}>
          <TextField
            fullWidth
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            placeholder={t('domain_contact_form_first_name_placeholder')}
            label={t('domain_contact_form_first_name_label')}
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName}
          />

          <TextField
            fullWidth
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            placeholder={t('domain_contact_form_last_name_placeholder')}
            label={t('domain_contact_form_last_name_label')}
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName}
          />

          <CountryCodeSelector
            name="mailingCountryCode"
            label={t('domain_contact_form_mailing_country_label')}
            value={formik.values.mailingCountryCode}
            onChange={formik.handleChange}
            error={!!formik.errors.mailingCountryCode}
            helperText={formik.errors.mailingCountryCode}
            bottomGutter
          />

          <TextField
            fullWidth
            name="mailingCity"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mailingCity}
            placeholder={t('domain_contact_form_mailing_city_placeholder')}
            label={t('domain_contact_form_mailing_city_label')}
            error={!!formik.errors.mailingCity}
            helperText={formik.errors.mailingCity}
          />
        </ContactsGridBox>

        <ContactsGridBox>
          <TextField
            fullWidth
            name="mailingAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mailingAddress}
            placeholder={t('domain_contact_form_mailing_address_placeholder')}
            label={t('domain_contact_form_mailing_address_label')}
            error={!!formik.errors.mailingAddress}
            helperText={formik.errors.mailingAddress}
          />
        </ContactsGridBox>

        <ContactsGridBox sx={{gridTemplateColumns: '1fr 1fr'}}>
          <TextField
            fullWidth
            name="mailingState"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mailingState}
            placeholder={t('domain_contact_form_mailing_state_placeholder')}
            label={t('domain_contact_form_mailing_state_label')}
            error={!!formik.errors.mailingState}
            helperText={formik.errors.mailingState}
          />

          <TextField
            fullWidth
            name="mailingZip"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mailingZip}
            placeholder={t('domain_contact_form_mailing_zip_placeholder')}
            label={t('domain_contact_form_mailing_zip_label')}
            error={!!formik.errors.mailingZip}
            helperText={formik.errors.mailingZip}
          />

          <TextField
            fullWidth
            name="emailAddress"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            placeholder={t('domain_contact_form_email_placeholder')}
            label={t('domain_contact_form_email_label')}
            error={!!formik.errors.emailAddress}
            helperText={formik.errors.emailAddress}
          />

          <TextField
            fullWidth
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            placeholder={t('domain_contact_form_phone_number_placeholder')}
            label={t('domain_contact_form_phone_number_label')}
            error={!!formik.errors.phoneNumber}
            helperText={formik.errors.phoneNumber}
          />
        </ContactsGridBox>
      </Box>
    );
  }),
);

export default DomainContactForm;
