import {QRCodeCanvas} from 'qrcode.react';
import React from 'react';

const QR_CODE_SIZE = 230; //px;

interface IProps {
  value: string;
}

const QRCode: React.FC<IProps> = ({value}) => {
  return <QRCodeCanvas value={value} size={QR_CODE_SIZE} />;
};

export default QRCode;
