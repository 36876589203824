import React from 'react';
import {Navigate} from 'react-router-dom';

import {Paths} from '../routes/Paths';

import AuthLayout from '../layouts/AuthLayout';
import AuthorizedLayout from '../layouts/AuthorizedLayout';
import BaseAuthLayout from '../layouts/BaseAuthLayout';
import AuthPage from '../pages/Auth/AuthPage';
import BlockedAccount from '../pages/Auth/BlockedAccountPage';
import ForgotPasswordPage from '../pages/Auth/ForgotPasswordPage';
import LogoutPage from '../pages/Auth/LogoutPage';
import BillingPage from '../pages/BillingPage';
import CreateServerPage from '../pages/CreateServerPage';
import DomainInfoPage from '../pages/DomainInfoPage';
import DomainsPage from '../pages/DomainsPage';
import NotificationsPage from '../pages/NotificationsPage';
import ProfilePage from '../pages/ProfilePage';
import ServerInfoPage from '../pages/ServerInfoPage';
import ServersPage from '../pages/ServersPage';
import SshKeysPage from '../pages/SshKeysPage';
import TeamPage from '../pages/TeamPage';
import UITheme from '../pages/UITheme';

interface ReactPropTypes extends React.ReactPropTypes {
  children?: React.ReactNode;
}

export type RouteLayoutType = React.ComponentType<ReactPropTypes>;

export type RouteComponentType = React.ComponentType<ReactPropTypes>;

export interface IRoutePathProps {
  children?: React.ReactNode;
  path: string;
  isAuthProtected?: boolean;
  caseSensitive?: boolean;
  strict?: boolean;
}

export interface IRouteProps extends IRoutePathProps {
  layout: React.FC<React.PropsWithChildren>;
  component: React.FC<IRoutePathProps>;
}

const LocalRedirect: React.FunctionComponent = () => {
  console.debug('-->LocalRedirect');

  return <Navigate to={Paths.Default} replace />;
};

export const authProtectedRoutes: IRouteProps[] = [
  {path: Paths.Profile, component: ProfilePage, layout: AuthorizedLayout},
  {path: Paths.CreateServer, component: CreateServerPage, layout: AuthorizedLayout},
  {path: Paths.Servers, component: ServersPage, layout: AuthorizedLayout},
  {path: Paths.ServerInfo, component: ServerInfoPage, layout: AuthorizedLayout},
  {path: Paths.Domains, component: DomainsPage, layout: AuthorizedLayout},
  {path: Paths.DomainInfo, component: DomainInfoPage, layout: AuthorizedLayout},
  {path: Paths.SshKeys, component: SshKeysPage, layout: AuthorizedLayout},
  {path: Paths.Team, component: TeamPage, layout: AuthorizedLayout},
  {path: Paths.Billing, component: BillingPage, layout: AuthorizedLayout},
  {path: Paths.Notifications, component: NotificationsPage, layout: AuthorizedLayout},
  {path: Paths.Default, component: LocalRedirect, layout: AuthorizedLayout},
  {path: '*', component: LocalRedirect, layout: AuthorizedLayout},
].map((item) => ({...item, isAuthProtected: true}));

export const publicRoutes: IRouteProps[] = [
  {path: Paths.Blocked, component: BlockedAccount, layout: BaseAuthLayout},
  {path: Paths.Logout, component: LogoutPage, layout: AuthLayout},
  {path: Paths.Authorization, component: AuthPage, layout: AuthLayout},
  {path: Paths.ForgotPassword, component: ForgotPasswordPage, layout: AuthLayout},
  {path: '/ui-theme', component: UITheme, layout: AuthorizedLayout},
];

export const routes = [...publicRoutes, ...authProtectedRoutes];

export const authPageOpen = (): boolean => {
  return window.location.pathname === Paths.Authorization;
};

export const pageIsOpen = (path: string): boolean => {
  return window.location.pathname === path;
};
