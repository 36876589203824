import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useParams} from 'react-router';
import {animated} from 'react-spring';
import {getApiRenewDomainError} from '../../api/getApiError';
import {ca2domains} from '../../api/proto';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToDomainInfo} from '../../routes/navigateTo';
import {DomainRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {Domain} from '../../stores/Domain';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import DomainBilling from './DomainBilling';
import DomainContactInfo from './DomainContactInfo';
import DomainDnsRecords from './DomainDnsRecords';
import DomainInfoHead from './DomainInfoHead';
import DomainNameServers from './DomainNameServers';
import DomainOverview from './DomainOverview';
import RenewDomainConfirmation from './RenewDomainConfirmation';

export enum DomainInfoTabs {
  Overview = 'overview',
  NsRecords = 'nsRecords',
  DnsRecords = 'dnsRecords',
  // Services,
  ContactInfo = 'contactInfo',
  Billing = 'billing',
  Renew = 'renew',
  RenewConfirmation = 'renewConfirmation',
}

const tabsOrder = {
  [DomainInfoTabs.Overview]: 1,
  [DomainInfoTabs.NsRecords]: 2,
  [DomainInfoTabs.DnsRecords]: 3,
  [DomainInfoTabs.ContactInfo]: 4,
  [DomainInfoTabs.Billing]: 5,
  [DomainInfoTabs.Renew]: 6,
  [DomainInfoTabs.RenewConfirmation]: 7,
};

interface IProps {
  domain: Domain;
}

function isTabValid(value: string): value is DomainInfoTabs {
  return Object.values(DomainInfoTabs).includes(value as DomainInfoTabs);
}

const DomainInfoViewer: React.FC<IProps> = observer((props) => {
  const {notification} = useStore();
  const params = useParams<DomainRouteParams>();

  const [currentTab, setCurrentTab] = React.useState<DomainInfoTabs>(DomainInfoTabs.Overview);
  const [prevTab, setPrevTab] = React.useState<DomainInfoTabs>(DomainInfoTabs.Billing);

  React.useEffect(() => {
    if (params.infoTabType && isTabValid(params.infoTabType)) {
      setPrevTab(currentTab);
      setCurrentTab(params.infoTabType);
    }
  }, [params, currentTab]);

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  const [renewDomainResponse, setRenewDomainResponse] = React.useState<ca2domains.IRenewDomainResponse | null>(null);

  const domainSuggestion = new DomainSuggestion({
    name: props.domain.name,
    renewalPrice: {
      points: Long.fromNumber(40000),
      formatted: '$4',
    },
  });

  const handleCancelPaymentOrder = async () => {};

  const handleRenewDomain = async () => {
    const {res, error} = await props.domain.renewDomain();

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiRenewDomainError(error);
          notification.error(errorMessage);
        });

        return;
      }

      setRenewDomainResponse(res);

      navigateToDomainInfo(props.domain, DomainInfoTabs.RenewConfirmation);
    }
  };

  const renderTab = (step: number) => {
    switch (step) {
      case tabsOrder[DomainInfoTabs.Overview]:
        return <DomainOverview domain={props.domain} />;
      case tabsOrder[DomainInfoTabs.NsRecords]:
        return <DomainNameServers domain={props.domain} />;
      case tabsOrder[DomainInfoTabs.DnsRecords]:
        return <DomainDnsRecords domain={props.domain} />;
      case tabsOrder[DomainInfoTabs.ContactInfo]:
        return <DomainContactInfo domain={props.domain} />;
      case tabsOrder[DomainInfoTabs.Billing]:
        return <DomainBilling domain={props.domain} />;
      case tabsOrder[DomainInfoTabs.Renew]:
        return (
          <PaymentViewer
            orderInstance={domainSuggestion}
            onCancelPaymentOrder={handleCancelPaymentOrder}
            onClickPayButton={handleRenewDomain}
            renewDomain
          />
        );
      case tabsOrder[DomainInfoTabs.RenewConfirmation]:
        return renewDomainResponse ? (
          <RenewDomainConfirmation domainSuggestion={domainSuggestion} renewDomainResponse={renewDomainResponse} />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <DomainInfoHead currentTab={currentTab} domain={props.domain} />

      <div className="page__inner">
        {transitions((styles, step) => (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            {renderTab(step)}
          </animated.div>
        ))}
      </div>
    </>
  );
});

export default DomainInfoViewer;
