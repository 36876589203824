import {observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import Div100vh from '../components/Div100vh';
import AuthHead from '../pages/Auth/AuthHeader';
import {capitalizeFirstLetter} from '../utils/capitalizeFirstLetter';

const BaseAuthLayout: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const location = useLocation();

  useEffect(() => {
    const currentPage =
      location.pathname === '/forgotPassword' ? 'Forgot Password' : capitalizeFirstLetter(location.pathname.slice(1));
    document.title = currentPage + ' | crypadvise';
  }, [location.pathname]);

  return (
    <Div100vh className="auth-layout">
      <AuthHead />
      {children}
    </Div100vh>
  );
});

export default BaseAuthLayout;
