import {ButtonProps} from '@mui/material';
import {action, computed, makeAutoObservable, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import {Breakpoint} from '../../modals/components/ModalDialog';
import {ModalType} from './ModalType';

export interface ICallback {
  [name: string]: ((data?: IModalData) => void) | (<T>(data?: IModalData) => Promise<T>);
}

export interface IModalData {
  selectedSshKeysIds?: number[] | null;
  callbacks?: ICallback | null;
  syncCallbacks?: boolean;
  sshKey?: ca2types.ISSHKey | null;
  tldNames?: string[] | null;
  submitButtonColor?: ButtonProps['color'] | null;
  cancelButtonColor?: ButtonProps['color'] | null;
  cancelButtonText?: string | null;
  submitButtonText?: string | null;
  maxWidth?: Breakpoint | null;
  headerText?: string | null;
  title?: JSX.Element | null;
  content?: JSX.Element | null;
}

export class ModalData {
  @observable selectedSshKeysIds?: number[] | null;
  @observable callbacks?: ICallback | null;
  @observable syncCallbacks?: boolean;
  @observable sshKey?: ca2types.ISSHKey | null;
  @observable tldNames?: string[] | null;
  @observable submitButtonColor?: ButtonProps['color'] | null;
  @observable cancelButtonColor?: ButtonProps['color'] | null;
  @observable cancelButtonText?: string | null;
  @observable submitButtonText?: string | null;
  @observable maxWidth?: Breakpoint | null;
  @observable headerText?: string | null;
  @observable title?: JSX.Element | null;
  @observable content?: JSX.Element | null;

  constructor(props: IModalData) {
    Object.assign(this, props);
    makeObservable(this);
  }
}

export interface IModalItem {
  type: ModalType;
  data: ModalData;
}

export class ModalItem implements IModalItem {
  type: ModalType;
  @observable data: IModalData;

  constructor(props: IModalItem, private modals: ModalsStore) {
    makeObservable(this);

    this.type = props.type;
    this.data = props.data;

    Object.assign(this, props);
  }

  close = () => {
    this.modals.close(this.type);
  };

  open = (type: ModalType, data: IModalData) => {
    this.modals.open(type, data);
  };

  @action updateData = (newData: ModalData) => {
    this.data = newData;
  };

  async trigger(name, data?: IModalData) {
    if (this.data?.callbacks && this.data.callbacks[name] instanceof Function) {
      if (this.data.syncCallbacks) {
        await this.data.callbacks[name](data || this.data);
      } else {
        this.data.callbacks[name](data || this.data);
      }
      this.modals.close(this.type);
    }
  }
}

export class ModalsStore {
  @observable private modals_ = new Map<ModalType, ModalItem>();

  @computed get list(): ModalItem[] {
    return Array.from(this.modals_.values());
  }

  constructor() {
    makeAutoObservable(this);
  }

  open(type: ModalType, data: IModalData = {}) {
    this.modals_.set(type, new ModalItem({type, data: new ModalData(data)}, this));
  }

  close(type?: ModalType | null) {
    type && this.modals_.delete(type);
  }

  getModalState(type: ModalType): {isOpen: boolean; data?: IModalData | null; modalItem: ModalItem | null} {
    const modalItem = this.modals_.get(type);
    return modalItem ? {isOpen: true, data: modalItem.data, modalItem} : {isOpen: false, data: null, modalItem: null};
  }
}

export default ModalsStore;
