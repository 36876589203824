import {useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as AmdLogo} from '../assets/image-icons/amd.svg';
import {ReactComponent as DellLogo} from '../assets/image-icons/dell.svg';
import {ReactComponent as FujitsuLogo} from '../assets/image-icons/fujitsu.svg';
import {ReactComponent as IntelLogo} from '../assets/image-icons/intel.svg';
import {ReactComponent as LenovoLogo} from '../assets/image-icons/lenovo.svg';
import {ReactComponent as NvidiaLogo} from '../assets/image-icons/nvidia.svg';

interface IProps {
  modelName?: string | null;
}

export const CpuOrGpuLogo: React.FC<IProps> = ({modelName}) => {
  const theme = useTheme();

  const amdLogo = <AmdLogo style={{fill: theme.palette.body.primary}} />;

  const cpuLogoByModel = {
    ['amd']: amdLogo,
    ['amd epyc']: amdLogo,
    ['dell']: <DellLogo />,
    ['fujitsu']: <FujitsuLogo />,
    ['intel']: <IntelLogo />,
    ['intel xeon']: <IntelLogo />,
    ['lenovo']: <LenovoLogo />,
    ['nvidia']: <NvidiaLogo />,
  };

  if (!modelName) {
    return;
  }

  const lowerCaseName = modelName.toLowerCase();
  const formattedName = lowerCaseName.includes('nvidia') ? 'nvidia' : lowerCaseName;

  return cpuLogoByModel[formattedName] || null;
};

export default CpuOrGpuLogo;
