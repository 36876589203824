import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SshKeysViewer from '../../../components/SshKeysViewer';
import {Instance} from '../../../stores/Instance';
import AccessesRow from './AccessesRow';

const Container = styled(AccessesRow)(() => ({
  flexDirection: 'column',
  alignItems: 'start',
}));

interface IProps {
  instance: Instance;
}

export const SshKeys: React.FC<IProps> = observer(({instance}) => {
  const {t} = useTranslation();

  const handleAddSshKey = (id: number) => {
    instance.update({
      sshKeyIds: [...instance.sshKeyIds, id],
    });
  };

  if (!instance.sshKeyIds.length) {
    return null;
  }

  return (
    <Container>
      <Typography variant="body3" color="body.placeholder" gutterBottom>
        {t('server_info_accesses_ssh_keys_label')}
      </Typography>

      <SshKeysViewer
        listItemVariation="server"
        dropdownPosition="bottom"
        sshKeyIds={instance.sshKeyIds}
        onAddSshKey={handleAddSshKey}
        hideAddNewKeyButton
      />
    </Container>
  );
});

export default SshKeys;
