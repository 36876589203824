import React from 'react';
import {ModalType} from '../stores/ModalsStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalAddSshKey from './ModalAddSshKey';
import ModalConfirm from './ModalConfirm';
import ModalDeleteSshKey from './ModalDeleteSshKey';
import ModalEditSshKey from './ModalEditSshKey';
import ModalSelectSshKey from './ModalSelectSshKey';
import ModalTldFilter from './ModalTldFilter';

interface IProps {
  modal: ModalItem;
}

const ModalWrapper: React.FC<IProps> = (props) => {
  switch (props.modal.type) {
    case ModalType.CONFIRM:
      return <ModalConfirm modal={props.modal} />;
    case ModalType.SELECT_SSH_KEY:
      return <ModalSelectSshKey modal={props.modal} />;
    case ModalType.TLD_FILTER:
      return <ModalTldFilter modal={props.modal} />;
    case ModalType.ADD_SSH_KEY:
      return <ModalAddSshKey modal={props.modal} />;
    case ModalType.EDIT_SSH_KEY:
      return <ModalEditSshKey modal={props.modal} />;
    case ModalType.DELETE_SSH_KEY:
      return <ModalDeleteSshKey modal={props.modal} />;
    default:
      return null;
  }
};

export default ModalWrapper;
