import {observer} from 'mobx-react';
import React from 'react';
import {useLocation, useNavigate} from 'react-router';
import {animated} from 'react-spring';
import Div100vh from '../../components/Div100vh';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import BillingDetailsViewer from './BillingDetailsViewer';
import BillingPageTopBar from './BillingPageTopBar';

export enum BillingPageTab {
  BILLING_DETAILS,
  TOP_UP_BALANCE,
}

export const BillingPage: React.FC = observer(() => {
  const location = useLocation();
  const state = location.state as {currentTab: BillingPageTab};
  const {billingStore} = useStore();

  const navigate = useNavigate();

  const [prevTab, setPrevTab] = React.useState<BillingPageTab>(BillingPageTab.TOP_UP_BALANCE);
  const [currentTab, setCurrentTab] = React.useState<BillingPageTab>(BillingPageTab.BILLING_DETAILS);

  React.useEffect(() => {
    setCurrentTab(state?.currentTab || BillingPageTab.BILLING_DETAILS);
  }, [state]);

  React.useEffect(() => {
    billingStore.initBillingPageState();

    return () => {
      billingStore.resetBillingPageState();
    };
  }, [billingStore]);

  const handleCancelPaymentOrder = () => {
    navigate(Paths.Billing);
    switchToTab(BillingPageTab.BILLING_DETAILS);
  };

  const switchToTab = (tab: BillingPageTab) => {
    setPrevTab(currentTab);
    setCurrentTab(tab);
  };

  const switchToPrevTab = () => {
    switch (currentTab) {
      case BillingPageTab.TOP_UP_BALANCE:
        switchToTab(BillingPageTab.BILLING_DETAILS);
        break;
      case BillingPageTab.BILLING_DETAILS:
        switchToTab(BillingPageTab.TOP_UP_BALANCE);
        break;
      default:
        switchToTab(BillingPageTab.BILLING_DETAILS);
    }
  };

  const switchToTopUp = () => {
    switchToTab(BillingPageTab.TOP_UP_BALANCE);
  };

  const transitions = useOrderTransition({
    prevStep: prevTab,
    currentStep: currentTab,
  });

  return (
    <Div100vh className="page page--billing">
      <BillingPageTopBar currentTab={currentTab} onBack={switchToPrevTab} />

      <div className="page__inner">
        {transitions((styles, step) =>
          step === BillingPageTab.TOP_UP_BALANCE ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <PaymentViewer onCancelPaymentOrder={handleCancelPaymentOrder} />
            </animated.div>
          ) : step === BillingPageTab.BILLING_DETAILS ? (
            <animated.div style={styles} className="animated-tab animated-tab--height100">
              <BillingDetailsViewer onTopUpBalance={switchToTopUp} />
            </animated.div>
          ) : null,
        )}
      </div>
    </Div100vh>
  );
});

export default BillingPage;
