import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {ca2billing} from '../../api/proto';
import {ReactComponent as CheckRoundIcon} from '../../assets/icons/check-fill-round-xxl.svg';
import Paths from '../../routes/Paths';
import DomainSuggestion from '../../stores/Domain/DomainSuggestion';
import {Plan} from '../../stores/Plan';
import PriceFormatter from '../../utils/priceFormatter';
import Typography from '../UI/Typography';
import {OrderInstance} from './PaymentViewer';

interface IProps {
  orderInstance?: OrderInstance;
  topUpBalanceResponse: ca2billing.ITopUpBalanceResponse;
  onClickPayButton?(): void;
  renewDomain?: boolean;
}

export const PaymentConfirmedForm: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  const amountFormatter = new PriceFormatter(props.topUpBalanceResponse.amount);

  const handleClickPayButton = async () => {
    setLoading(true);
    await props.onClickPayButton?.();
    setLoading(false);
  };

  const handleGoHome = () => {
    navigate(Paths.Servers);
  };

  return (
    <>
      <Box sx={{marginBottom: theme.spacing(4)}}>
        <CheckRoundIcon />
      </Box>

      <Typography variant="h4" gutterBottom>
        {t('component_payment_viewer_added_to_balance_amount', {
          amount: amountFormatter.formatted,
        })}
      </Typography>

      <Typography variant="body1" sx={{marginBottom: theme.spacing(4)}}>
        {t('component_payment_viewer_payment_for_amount_crypto_confirmed', {
          amountCrypto: `${props.topUpBalanceResponse.amountToPay} ${props.topUpBalanceResponse.currencyCode}`,
        })}
      </Typography>

      {props.orderInstance instanceof Plan ? (
        <>
          <LoadingButton variant="contained" loading={loading} fullWidth size="large" onClick={handleClickPayButton}>
            {t('component_payment_viewer_pay_and_launch_server_button', {
              amount: props.orderInstance.firstHoursRateFormatter.formatted,
            })}
          </LoadingButton>

          <Typography
            variant="body3"
            sx={{marginTop: theme.spacing(2), color: theme.palette.body.tertiary, textAlign: 'center'}}
          >
            {t('component_payment_viewer_minimum_payment_duration_for_server', {
              hours: props.orderInstance.firstPaymentHours,
            })}
          </Typography>
        </>
      ) : props.orderInstance instanceof DomainSuggestion ? (
        <LoadingButton variant="contained" loading={loading} fullWidth size="large" onClick={handleClickPayButton}>
          {t('component_payment_viewer_pay_and_order_domain_button', {
            amount: props.renewDomain
              ? props.orderInstance.renewalPriceFormatter.formatted
              : props.orderInstance.registrationPriceFormatter.formatted,
          })}
        </LoadingButton>
      ) : (
        <Button variant="contained" fullWidth size="large" onClick={handleGoHome}>
          {t('component_payment_viewer_go_home_button')}
        </Button>
      )}
    </>
  );
});

export default PaymentConfirmedForm;
