import {observer} from 'mobx-react';
import React from 'react';
import {useParams} from 'react-router-dom';
import Div100vh from '../../components/Div100vh';
import {InstanceRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import ServerInfoViewer from './ServerInfoViewer';

export const ServerInfoPage: React.FC = observer(() => {
  const {instanceView} = useStore();
  const params = useParams<InstanceRouteParams>();

  React.useEffect(() => {
    instanceView.openInstanceByParams(params);
  }, [params, instanceView]);

  if (!instanceView.activeInstance) {
    return null;
  }

  return (
    <Div100vh className="page page--server-info">
      <ServerInfoViewer instance={instanceView.activeInstance} />
    </Div100vh>
  );
});

export default ServerInfoPage;
