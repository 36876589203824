import {action, observable} from 'mobx';
import {ca2domains} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Domain from './Domain';
import DomainContactsStore from './DomainContactsStore';
import {DomainsSearch} from './DomainsSearch';

export class DomainsStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
  }

  search: DomainsSearch = new DomainsSearch(this);
  contacts: DomainContactsStore = new DomainContactsStore(this);

  @observable isInit: boolean = false;

  @observable domains: Domain[] = [];

  @observable registerDomainFormData: ca2domains.IRegisterDomainRequest | null = null;

  @action setRegisterDomainFormData = (data: ca2domains.IRegisterDomainRequest | null) => {
    this.registerDomainFormData = data;
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadDomains_();

    await this.search.init();
    await this.contacts.init();

    this.isInit = true;
  };

  private loadDomains_ = async () => {
    const {res} = await this.request({
      domains: {
        domainsList: {},
      },
    });

    if (res?.domains?.domainsList) {
      this.loadDomainsProcess_(res.domains.domainsList);
    }
  };

  private loadDomainsProcess_ = (res: ca2domains.IDomainsListResponse) => {
    if (res.domains) {
      this.domains = res.domains.map((raw) => new Domain(raw, this));
    }
  };

  private registerDomainRequest_ = async (registerDomain: ca2domains.IRegisterDomainRequest) => {
    const {res, error} = await this.request({
      domains: {
        registerDomain,
      },
    });

    return {error, res: res?.domains?.registerDomain};
  };

  validateRegisterDomainRequestData = async (registerDomain: ca2domains.IRegisterDomainRequest) => {
    return await this.registerDomainRequest_({...registerDomain, dryRun: true});
  };

  registerDomain = async (registerDomain: ca2domains.IRegisterDomainRequest) => {
    const {res, error} = await this.registerDomainRequest_(registerDomain);

    if (res) {
      this.processRegisterDomain_(res);
    }

    return {error, res};
  };

  private processRegisterDomain_ = (res: ca2domains.IRegisterDomainResponse) => {
    if (res.domain) {
      this.domains.push(new Domain(res.domain, this));
    }
  };

  findById = (id?: number | null) => {
    return this.domains.find(({domainId}) => id && domainId === id) || null;
  };

  @action reset = () => {
    this.registerDomainFormData = null;

    this.search.reset();
    this.contacts.reset();

    this.isInit = false;
  };
}

export default DomainsStore;
