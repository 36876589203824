import {useTheme} from '@mui/material';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {LoaderSizeMarginProps} from 'react-spinners/helpers/props';

export const Loader: React.FC<LoaderSizeMarginProps> = (props) => {
  const {palette} = useTheme();

  return <BeatLoader color={palette.brand.primary} size={15} aria-label="Loading Spinner" {...props} />;
};

export default Loader;
