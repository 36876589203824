import React from 'react';
import {ReactComponent as BackupIcon} from '../../../assets/icons/backup.svg';
import {ReactComponent as OptionIcon} from '../../../assets/icons/server-option.svg';
import Typography from '../../../components/UI/Typography';
import {Plan} from '../../../stores/Plan';
import {BENEFIT_BACKUP_ID} from '../../../stores/Server';

interface IProps {
  plan: Plan;
}

const ServerBenefits: React.FC<IProps> = ({plan}) => {
  return (
    <div className="plan-card__server-benefits">
      {plan.benefits.map(({id, title}) => (
        <div key={id} className="plan-card__server-benefits-item">
          <div className="plan-card__server-benefits-icon">
            {id === BENEFIT_BACKUP_ID ? <BackupIcon /> : <OptionIcon />}
          </div>
          <Typography variant="body2">{title}</Typography>
        </div>
      ))}
    </div>
  );
};

export default ServerBenefits;
