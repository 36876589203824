import {Typography as MuiTypography, TypographyProps, useTheme} from '@mui/material';

export const Typography: React.FC<TypographyProps> = ({variant, children, component, ...props}) => {
  const theme = useTheme();
  const Component = component || (variant ? theme.typography.componentsMap[variant] : null) || 'div';
  return (
    <MuiTypography component={Component} variant={variant} {...props}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
