import React from 'react';

import {useOrigin100vh} from '../../hooks/useOrigin100vh';

export function use100vh() {
  const originHeight = useOrigin100vh();

  const [height, setHeight] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (originHeight) {
      setHeight(originHeight);
    }
  }, [originHeight]);

  return height || originHeight;
}

export default use100vh;
