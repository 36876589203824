import {Box, BoxProps, Button, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import TldItem, {ITldItem} from './TldItem';

const ListBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));

interface IProps extends BoxProps {
  tldItems: ITldItem[];
  onToggleTld?(tld: string): void;
  onClear?(): void;
}

const TldList: React.FC<IProps> = ({tldItems, onToggleTld, onClear, ...restProps}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <ListBox {...restProps}>
      {tldItems.map((item) => (
        <TldItem key={item.tldName} tldItem={item} onToggleTld={onToggleTld} />
      ))}

      {onClear ? (
        <Button
          variant="outlined"
          size="large"
          onClick={onClear}
          startIcon={<DeleteIcon style={{fill: theme.palette.body.secondary}} />}
        >
          {t('action_button_clear')}
        </Button>
      ) : null}
    </ListBox>
  );
};

export default TldList;
