import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Plan from './Plan';
import PlansFilterStore from './PlansFilterStore';

export class PlansStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.filter = new PlansFilterStore(app, this);
  }

  @observable filter: PlansFilterStore;

  @observable private plans_: Plan[] = [];

  @computed get plans() {
    return this.plans_
      .slice()
      .sort((planA, planB) => planA.monthlyRateFormatter.points - planB.monthlyRateFormatter.points);
  }

  findById = (planId: string | null): Plan | null => {
    return this.plans.find(({id}) => planId && id === planId) || null;
  };

  reload = async () => {
    this.clear();

    const {res} = await this.request({
      servers: {
        plansList: this.filter.requestData,
      },
    });

    if (res?.servers?.plansList) {
      this.processLoadPlans_(res?.servers?.plansList);
    }
  };

  @action private processLoadPlans_ = (res: ca2servers.IPlansListResponse) => {
    if (res?.items?.length) {
      this.plans_ = res.items.map((raw) => new Plan(raw, this));
    }
  };

  @action clear = () => {
    this.plans_ = [];
  };

  @action reset = () => {
    this.clear();
  };
}

export default PlansStore;
