import cn from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '../UI/Typography';
import {PaymentStep} from './PaymentViewer';

interface IProps {
  currentStep: PaymentStep;
}

const PaymentStepsBar: React.FC<IProps> = ({currentStep}) => {
  const {t} = useTranslation();

  const stepsData = {
    [PaymentStep.TOP_UP_BALANCE]: t('component_payment_viewer_title_transaction_details'),
    [PaymentStep.PAYMENT_DETAILS]: t('component_payment_viewer_title_payment_details'),
    [PaymentStep.AWAITING_PAYMENT]: t('component_payment_viewer_title_confirmation'),
  };

  const hasStepToShow = [...Object.keys(stepsData), PaymentStep.PAYMENT_CONFIRMED].findIndex(
    (step) => +step === currentStep,
  );

  if (hasStepToShow === -1) {
    return null;
  }

  return (
    <div className="payment-steps-bar">
      {Object.entries(stepsData).map(([stringifyStep, title]) => {
        const step = +stringifyStep;
        const isActiveStep = currentStep === step;
        return (
          <div
            key={step}
            className={cn('payment-steps-bar__item', {
              active: isActiveStep,
              success: currentStep > step,
              awaiting: step === PaymentStep.AWAITING_PAYMENT && currentStep === PaymentStep.AWAITING_PAYMENT,
            })}
          >
            <Typography
              variant="body3"
              sx={({palette}) => ({color: isActiveStep ? palette.body.primary : palette.body.placeholder})}
            >
              {title}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default PaymentStepsBar;
