import cn from 'classnames';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from './stores/AppStore';

const AppContainer: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {lightThemeChosen} = useStore();

  return (
    <div
      className={cn('app', {
        'app--light': lightThemeChosen,
        'app--dark': !lightThemeChosen,
      })}
    >
      {children}
    </div>
  );
});

export default AppContainer;
