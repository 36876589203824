import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translationENG from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

const DEFAULT_LANGUAGE = 'en';
const initialLanguage: string = DEFAULT_LANGUAGE;

const resources = {
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationENG,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    lng: initialLanguage,
    fallbackLng: DEFAULT_LANGUAGE,
    returnEmptyString: false,

    nsSeparator: false,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export const t = i18n.t;

export default i18n;
