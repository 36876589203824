import {LoadingButton} from '@mui/lab';
import {Box, FormHelperText, InputLabel, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {
  getApiAuthEnterCodeError,
  getUserUpdateEmailConfirmationError,
  getUserUpdateEmailError,
} from '../../api/getApiError';
import {ca2types} from '../../api/proto';
import PinInput, {IPinInputRef} from '../../components/UI/PinInput';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';

const YourEmailBox = styled(Box)(({theme}) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  '& .MuiTypography-body3': {
    color: theme.palette.body.tertiary,
  },
  '& .MuiTypography-body2': {
    fontWeight: theme.size.fontWeight.bold,
  },
}));

const VerifyEmailTypography = styled(Typography)(({theme}) => ({
  borderBottom: `2px solid ${theme.palette.border.primary}`,
  paddingBottom: theme.spacing(2),
}));

interface IProps {
  newEmail: string;
  codeParams: ca2types.ICode;
  switchToProfileTab(): void;
}

const DEFAULT_CODE_LENGTH = 6;

const VerifyCurrentAndNewEmailForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {notification, authStore, userStore} = useStore();
  const {t} = useTranslation();

  const [hasApiError, setHasApiError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [codeParams, setCodeParams] = React.useState<ca2types.ICode>(props.codeParams);
  const [verificationEmail, setVerificationEmail] = React.useState<string | null>(userStore.email || null);
  const [allowVerifyNewEmail, setAllowVerifyNewEmail] = React.useState<boolean>(false);

  const pinInputRef = React.useRef<IPinInputRef>(null);

  const codeLength = codeParams.length || DEFAULT_CODE_LENGTH;

  const requestToVerifyNewEmail = async () => {
    const {error, res} = await userStore.updateEmail(props.newEmail);

    if (error) {
      notification.error(error?.message);
      return;
    }

    if (res) {
      if (res.errors?.length) {
        res.errors.forEach((error) => {
          notification.error(getUserUpdateEmailError(error));
        });
        setLoading(false);
        return;
      }

      if (res?.code) {
        setCodeParams(res?.code);
        setVerificationEmail(props.newEmail);

        setAllowVerifyNewEmail(true);
      }
    }
  };

  const verifyCurrentEmail = async (code: string) => {
    setLoading(true);
    setHasApiError(false);
    const {error, res} = await authStore.enterCode({code, email: userStore.email}, true);

    if (error) {
      notification.error(error.message);
      setHasApiError(true);
    }

    if (res) {
      const {errors, successStep} = res;

      if (errors?.length) {
        errors.forEach((key) => {
          notification.error(getApiAuthEnterCodeError(key));
          setHasApiError(true);
        });
        setLoading(false);
        return;
      }

      if (!successStep?.token) {
        notification.error(t('auth_verification_code_form_notification_access_token_not_found'));
        setLoading(false);
        return;
      }

      await requestToVerifyNewEmail();

      formik.resetForm();
    }

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required(t('auth_verification_code_form_code_required'))
        .min(codeLength, t('auth_verification_code_form_code_validation', {length: codeLength})),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!allowVerifyNewEmail) {
        await verifyCurrentEmail(values.code);
      } else {
        setLoading(true);
        setHasApiError(false);
        const {error, res} = await userStore.updateEmailConfirmation(values.code);

        if (error) {
          notification.error(error.message);
          setHasApiError(true);
        }

        if (res) {
          const {errors} = res;

          if (errors?.length) {
            errors.forEach((error) => {
              notification.error(getUserUpdateEmailConfirmationError(error));
              setHasApiError(true);
            });
            setLoading(false);
            return;
          }

          notification.success(t('profile_form_email_update_successful'));
          props.switchToProfileTab();

          formik.resetForm();
        }
      }

      formik.resetForm();
    },
    onReset: () => {
      pinInputRef.current?.reset();
      setLoading(false);
    },
  });

  const handleChangeCode = async (value: string) => {
    await formik.setFieldValue('code', value, false);

    if (value.length === codeLength) {
      formik.submitForm();
    }
  };

  return (
    <Box sx={{width: 640, transform: 'translate(-50%, -50%)', left: '50%', top: '50%', position: 'relative'}}>
      <PageContentLayout sx={{padding: theme.spacing(4)}}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: theme.spacing(4),
              marginBottom: theme.spacing(4),
            }}
          >
            <VerifyEmailTypography
              variant="body3"
              sx={{borderColor: allowVerifyNewEmail ? theme.palette.success.primary : theme.palette.brand.primary}}
            >
              {t('profile_verify_old_email_label')}
            </VerifyEmailTypography>
            <VerifyEmailTypography
              variant="body3"
              sx={{borderColor: allowVerifyNewEmail ? theme.palette.brand.primary : theme.palette.border.primary}}
            >
              {t('profile_verify_new_email_label')}
            </VerifyEmailTypography>
          </Box>
          <Typography variant="h4" gutterBottom>
            {t('auth_verification_code_form_title')}
          </Typography>

          <YourEmailBox>
            <Box>
              <Typography variant="body3">{t('auth_verification_code_form_email_label')}</Typography>
              <Typography variant="body2">{verificationEmail}</Typography>
            </Box>
          </YourEmailBox>

          <InputLabel shrink>{t('auth_verification_code_form_code_label')}</InputLabel>
          <PinInput
            ref={pinInputRef}
            className={cn({
              error: formik.errors.code || hasApiError,
            })}
            name="code"
            onChange={handleChangeCode}
            value={formik.values.code}
            fields={codeLength}
            autoFocus
          />
          <FormHelperText error>{formik.errors.code || ' '}</FormHelperText>

          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            sx={{marginTop: theme.spacing(4)}}
            size="large"
            loading={loading}
          >
            {t('auth_verification_code_form_on_reset_password_submit')}
          </LoadingButton>
        </form>
      </PageContentLayout>
    </Box>
  );
});

export default VerifyCurrentAndNewEmailForm;
