import Lottie from 'lottie-react';
import {observer} from 'mobx-react-lite';
import React from 'react';
import animationData from '../assets/data/app-loader.json';
import {useStore} from '../stores/AppStore';

const AppLoader: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {isAppInit} = useStore();

  if (isAppInit) {
    return <>{children}</>;
  }

  return (
    <div className="app-loader">
      <div className="app-loader__loader">
        <Lottie animationData={animationData} loop={true} />
      </div>
    </div>
  );
});

export default AppLoader;
