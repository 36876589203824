import {observer} from 'mobx-react';
import {ToastContainer as BaseToastContainer} from 'react-toastify';
import {useStore} from '../stores/AppStore';

export const ToastContainer: React.FC = observer(() => {
  const {lightThemeChosen} = useStore();
  return (
    <BaseToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop
      closeOnClick
      pauseOnHover
      theme={lightThemeChosen ? 'light' : 'dark'}
    />
  );
});

export default ToastContainer;
