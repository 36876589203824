import {ca2} from './proto';

export interface IErrorEvent {
  message?: string | null;
  trx?: string | null;
}

export class ErrorEvent {
  constructor(properties?: IErrorEvent) {
    this.message = properties?.message;
  }

  public message?: string | null;
}

export type NetworkResponse<R extends ca2.IServerResponse> = {
  error: IErrorEvent | null;
  res?: R | null;
  trx?: string | null;
};
