import {Box, Button, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import Typography from '../../components/UI/Typography';
import {SUPPORT_EMAIL} from '../../constants';
import Paths from '../../routes/Paths';

const BlockedAccountPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t} = useTranslation();

  const handleBlockBackToAuth = () => {
    navigate(Paths.Authorization);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '705px',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h1" gutterBottom>
        {t('blocked_account_title')}
      </Typography>
      <Typography variant="body1">{t('blocked_account_text_1')}</Typography>
      <Typography variant="body1" sx={{marginBottom: theme.spacing(6)}}>
        {t('blocked_account_text_2')}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
          backgroundColor: theme.palette.backgroundSecondary,
          borderRadius: '12px',
          marginBottom: theme.spacing(6),
        }}
      >
        {t('blocked_account_support_email_label')}{' '}
        <a style={{color: theme.palette.brand.primary, textDecoration: 'underline'}} href={`mailto:${{SUPPORT_EMAIL}}`}>
          {SUPPORT_EMAIL}
        </a>
      </Typography>

      <Button variant="outlined" size="large" onClick={handleBlockBackToAuth}>
        {t('blocked_account_go_to_login_button')}
      </Button>
    </Box>
  );
};

export default BlockedAccountPage;
