import {Slider as MuiSlider, SliderProps, styled} from '@mui/material';
import React from 'react';

export interface ISliderProps extends Omit<SliderProps, 'value' | 'defaultValue'> {
  value?: number[];
  defaultValue?: number[];
}

const StyledSlider = styled(MuiSlider)(({theme}) => ({
  height: 8,
  color: theme.palette.brand.primary,
  paddingBottom: 0,
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.backgroundQuaternary,
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.brand.tertiary,
    '&:after': {
      position: 'absolute',
      width: 4,
      height: 4,
      content: '""',
      transform: 'transition(-50%, -50%)',
      left: '50%',
      top: '50%',
      backgroundColor: theme.palette.body.tint,
    },
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
}));

const Slider: React.FC<ISliderProps> = (props) => {
  return <StyledSlider {...props} />;
};

export default Slider;
