import React from 'react';
import Div100vh from '../components/Div100vh';
import Sidebar from '../pages/Sidebar/Sidebar';
import AuthorizedLayoutWrapper from './AuthorizedLayoutWrapper';

const CommonLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <AuthorizedLayoutWrapper>
      <Div100vh className="authorized-layout">
        <Sidebar />
        {children}
      </Div100vh>
    </AuthorizedLayoutWrapper>
  );
};

export default CommonLayout;
