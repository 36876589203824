import {LoadingButton} from '@mui/lab';
import {Button, Typography} from '@mui/material';
import validator from 'email-validator';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getApiAuthEnterPasswordError} from '../../api/getApiError';
import PasswordInput from '../../components/UI/PasswordInput';
import {TextField} from '../../components/UI/TextField';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

type LocationState = {
  email?: string;
};

interface IProps {
  onSubmit?(): void;
}

const EnterPasswordForm: React.FC<IProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const {authStore, notification} = useStore();
  const {t} = useTranslation();

  const [apiError, setApiError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: state?.email || '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t('auth_email_verification_form_email_required'))
        .test('is_email', t('auth_email_verification_form_email_incorrect'), validator.validate),
      password: Yup.string().required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setApiError('');
      setLoading(true);
      const {error, res} = await authStore.enterPassword({email: values.email, password: values.password});

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors, successStep} = res;

        if (errors?.length) {
          errors.forEach((key) => {
            setApiError(getApiAuthEnterPasswordError(key));
          });
        }

        if (successStep?.token) {
          props.onSubmit?.();
        }
      }

      setLoading(false);
    },
    onReset: () => {
      setApiError('');
      setLoading(false);
    },
  });

  const handleForgotPassword = () => {
    navigate(Paths.ForgotPassword, {state: {email: formik.values.email || ''}});
  };

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_enter_password_form_title')}
      </Typography>
      <Typography variant="h3" sx={(theme) => ({marginBottom: theme.spacing(6), color: theme.palette.body.tertiary})}>
        {t('auth_enter_password_form_subtitle')}
      </Typography>

      <TextField
        fullWidth
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        placeholder={t('auth_email_verification_form_email_placeholder')}
        label={t('auth_email_verification_form_email_label')}
        error={!!formik.errors.email}
        helperText={formik.errors.email}
        autoFocus={!state?.email}
        autoComplete="email"
      />

      <PasswordInput
        fullWidth
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        placeholder={t('auth_personal_info_form_password_placeholder')}
        label={t('auth_personal_info_form_password_label')}
        error={!!formik.errors.password || !!apiError}
        helperText={formik.errors.password || apiError}
        autoFocus={!!state?.email}
        inputProps={{
          autoComplete: "current-password"
        }}
        rightAdornment={
          <Button variant="outlined" size="large" sx={{marginLeft: 'auto'}} onClick={handleForgotPassword}>
            {t('auth_enter_password_forgot_password_button')}
          </Button>
        }
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_enter_password_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default EnterPasswordForm;
