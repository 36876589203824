class BrowserStorage {
  setItem = (key: string, value: string) => {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  };

  getItem = (key: string): string | null => {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  removeItem = (key: string) => {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  };

  session = (key: string, value?: string | null): string | null | undefined => {
    try {
      if (typeof value === 'undefined') {
        return window.sessionStorage.getItem(key);
      } else if (value === null) {
        window.sessionStorage.removeItem(key);
      } else {
        window.sessionStorage.setItem(key, value);
      }
    } catch (e) {
      console.log(e);
    }
    return undefined;
  };

  local = (key: string, value?: string | null): string | null | undefined => {
    try {
      if (typeof value === 'undefined') {
        return window.localStorage.getItem(key);
      } else if (value === null) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, value);
      }
    } catch (e) {
      console.log(e);
    }
    return undefined;
  };
}

export const browserStorage = new BrowserStorage();

export default browserStorage;
