import {Box, Button, keyframes, styled, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2billing, ca2types} from '../../api/proto';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-xl.svg';
import {useStore} from '../../stores/AppStore';

import PriceFormatter from '../../utils/priceFormatter';
import CurrencyIcon from '../CurrencyIcon';
import TextField from '../UI/TextField';
import Typography from '../UI/Typography';
import OrderIdTitle from './OrderIdTitle';

const waveAnimation = keyframes`
  0% {
    background-position: -100% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
`;

const BorderBox = styled(Box)(({theme}) => ({
  borderRadius: '8px',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  border: `1px solid ${theme.palette.border.primary}`,
  background: `linear-gradient(90deg, transparent, rgba(200, 200, 200, 0.15) 10%, transparent)`,
  backgroundSize: '300% 100%',
  animation: `${waveAnimation} 3s ease-in-out infinite`,
}));

const MarkAsPaidBox = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.success.primary,
  marginBottom: theme.spacing(2),
  borderRadius: '8px',
}));

interface IProps {
  topUpBalanceResponse: ca2billing.ITopUpBalanceResponse;
  onSwitchToPaymentConfirmed(): void;
}

export const AwaitingPaymentForm: React.FC<IProps> = observer((props) => {
  const {billingStore} = useStore();
  const theme = useTheme();
  const {t} = useTranslation();

  const handleBillingPayments = React.useCallback(
    (payments: ca2types.IPayment[] | null) => {
      if (!payments) {
        return;
      }

      for (const {foreignId, status} of payments) {
        if (foreignId === props.topUpBalanceResponse.foreignId && status === ca2types.PaymentStatus.PST_SUCCESS) {
          props.onSwitchToPaymentConfirmed();
          break;
        }
      }
    },
    [props],
  );

  React.useEffect(() => {
    billingStore.subscribeToPayments(handleBillingPayments);

    return () => {
      billingStore.unsubscribeFromPayments(handleBillingPayments);
    };
  }, [billingStore, handleBillingPayments]);

  const amountFormatter = new PriceFormatter(props.topUpBalanceResponse.amount);

  return (
    <>
      <BorderBox>
        <Typography variant="h4" gutterBottom>
          {t('component_payment_viewer_awaiting_payment_title')}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('component_payment_viewer_awaiting_payment_description')}
        </Typography>
      </BorderBox>

      <TextField
        fullWidth
        value={props.topUpBalanceResponse.amountToPay}
        label={t('component_payment_viewer_amount_label')}
        formControlProps={{sx: {marginBottom: 0}}}
        endAdornment={
          <div style={{color: theme.palette.body.placeholder}}>{props.topUpBalanceResponse.currencyCode}</div>
        }
        startAdornment={
          props.topUpBalanceResponse.currencyCode ? (
            <CurrencyIcon code={props.topUpBalanceResponse.currencyCode} style={{marginRight: theme.spacing(2)}} />
          ) : null
        }
      />
      <Typography variant="body3" sx={{marginBottom: theme.spacing(4)}}>
        = {amountFormatter.formatted}
      </Typography>

      <MarkAsPaidBox onClick={props.onSwitchToPaymentConfirmed}>
        <CheckIcon style={{fill: theme.palette.body.tint, marginRight: theme.spacing(1)}} />
        <Typography variant="subtitle1" color="body.tint">
          {t('component_payment_viewer_mark_as_paid_button')}
        </Typography>
      </MarkAsPaidBox>

      <Button fullWidth variant="outlined" size="large">
        {t('component_payment_viewer_contact_support_button')}
      </Button>

      <OrderIdTitle orderId={props.topUpBalanceResponse.foreignId} />
    </>
  );
});

export default AwaitingPaymentForm;
