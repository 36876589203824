export function convertHoursToDaysAndHours(hours: number): {
  days: number;
  hours: number;
} {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  return {
    days: days,
    hours: remainingHours,
  };
}

export default convertHoursToDaysAndHours;
