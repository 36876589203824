import {FormHelperText, Select, SelectProps} from '@mui/material';
import FormControl, {FormControlProps} from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {alpha, styled} from '@mui/material/styles';
import {PropsWithChildren} from 'react';
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/arrow-down.svg';

const StyledSelect = styled(Select)(({theme, error}) => ({
  position: 'relative',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  fontSize: theme.size.fontSize.S,
  fontWeight: theme.size.fontWeight.regular,
  lineHeight: theme.size.lineHeight.S,
  letterSpacing: theme.size.letterSpacing.S,
  border: `1px solid ${theme.palette.border.primary}`,
  color: theme.palette.body.primary,
  borderRadius: 8,
  marginTop: '0 !important',
  '&:after, &:before': {
    display: 'none',
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 12px)',
    right: 10,
    fill: theme.palette.body.primary,
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
    borderColor: theme.palette.border.tertiary,
  },
  '&.Mui-focused': {
    borderColor: theme.palette.brand.primary,
    boxShadow: `${alpha(theme.palette.body.placeholder, 0.25)} 0 0 0 0.1rem`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.backgroundQuaternary,
  },
  ...(error && {
    borderColor: `${theme.palette.error.primary} !important`,
  }),
}));

export type SelectorProps = SelectProps & {
  label?: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  bottomGutter?: boolean;
};

export const Selector: React.FC<PropsWithChildren<SelectorProps>> = ({
  label,
  error,
  helperText,
  fullWidth = true,
  formControlProps,
  children,
  bottomGutter,
  ...rest
}) => {
  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      sx={(theme) => ({marginBottom: bottomGutter ? theme.spacing(4) : 0})}
      {...formControlProps}
    >
      <InputLabel shrink>{label}</InputLabel>
      <StyledSelect fullWidth={fullWidth} error={error} {...rest} IconComponent={ArrowDownIcon}>
        {children}
      </StyledSelect>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Selector;
