import {useTheme} from '@mui/material';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import {ca2types} from '../api/proto';
import {formattedCountryCode, formattedCountryName} from '../utils/regions';

interface IProps {
  regionOrDatacenter: ca2types.IRegion | ca2types.IDatacenter;
}

const RegionLabel: React.FC<IProps> = ({regionOrDatacenter}) => {
  const theme = useTheme();
  const {city, countryCode} = regionOrDatacenter;

  let extra = '';

  if (regionOrDatacenter instanceof ca2types.Datacenter) {
    extra = regionOrDatacenter.extra;
  }

  return (
    <>
      <ReactCountryFlag
        countryCode={formattedCountryCode(countryCode)}
        className="emojiFlag"
        svg
        style={{
          fontSize: '1.5em',
          lineHeight: '18px',
          marginRight: theme.spacing(2),
          height: '0.9rem',
        }}
      />
      {city
        ? `${city}, ${formattedCountryCode(countryCode)} ${extra}`
        : `${formattedCountryName(countryCode)} ${extra}`}
    </>
  );
};

export default RegionLabel;
