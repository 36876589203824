import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import {formatTimestamp} from '../../utils/format/format';
import PriceFormatter from '../../utils/priceFormatter';

export class UpcomingPayment {
  @observable domainId: Long | null = null;
  @observable private amount: ca2types.IAmount | null = null;
  @observable private date: Long | null = null;

  @observable amountFormatter: PriceFormatter;

  constructor(public raw: ca2types.IUpcomingPayment) {
    makeObservable(this);
    this.update_(raw);

    this.amountFormatter = new PriceFormatter(raw.amount);
  }

  @computed get dateFormatted() {
    return this.date ? formatTimestamp(this.date.toNumber() * 1000, false) : 0;
  }

  @action private update_ = (raw: ca2types.IUpcomingPayment) => {
    Object.assign(this, raw);
  };
}

export default UpcomingPayment;
