import {Box, Button, Tooltip, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../assets/icons/copy.svg';

const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .copy-button': {
    minWidth: 88,
    maxHeight: 32,
    backgroundColor: theme.palette.backgroundPrimary,
  },
  '& .value': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export interface ICopyableBoxRef {
  hideCopyButton(): void;
  showCopyButton(): void;
}

interface IProps {
  className?: string;
  value?: string | null;
}

const CopyableBox = React.forwardRef<ICopyableBoxRef, IProps>(({value, className}, ref) => {
  const {t} = useTranslation();
  const {palette} = useTheme();

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [showButton, setShowButton] = React.useState<boolean>(true);

  React.useImperativeHandle(ref, () => ({
    hideCopyButton: () => {
      setShowButton(false);
    },
    showCopyButton: () => {
      setShowButton(true);
    },
  }));

  const handleCopy = async () => {
    if (typeof value !== 'string') {
      return;
    }

    try {
      await navigator.clipboard.writeText(value);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    } catch (error) {
      console.error('Error on copy to clipboard: ', error);
    }
  };

  if (!value) {
    return null;
  }

  return (
    <StyledBox className={className}>
      <Box className="value">{value}</Box>
      {showButton ? (
        <Tooltip title={t('component_copyable_text_input_copy_to_clipboard')} open={tooltipOpen}>
          <Button
            className="copy-button"
            variant="outlined"
            onClick={handleCopy}
            startIcon={<CopyIcon style={{fill: palette.body.primary, cursor: 'pointer'}} />}
          >
            {t('action_button_copy')}
          </Button>
        </Tooltip>
      ) : null}
    </StyledBox>
  );
});

export default CopyableBox;
