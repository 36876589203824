import {observer} from 'mobx-react';
import React from 'react';
import {animated} from 'react-spring';
import {getApiCreateInstanceError} from '../../api/getApiError';
import {ca2instances} from '../../api/proto';
import Div100vh from '../../components/Div100vh';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import getQueryStringParam from '../../utils/getQueryStringParam';
import ChoosePlanStep from './ChoosePlanStep/ChoosePlanStep';
import CreateServerConfirmation from './CreateServerConfirmation';
import CreateServerPageTopBar from './CreateServerPageTopBar';
import SetupPlanForm from './SetupPlanForm';

export enum CreateServerStep {
  CHOOSE_PLAN,
  SETUP_PLAN,
  PAYMENT,
  CONFIRMATION,
}

const debug: string = getQueryStringParam('debug');
const qsStep: string = getQueryStringParam('step');

const INITIAL_STEP = debug && qsStep ? parseFloat(qsStep) : CreateServerStep.CHOOSE_PLAN;

export const CreateServerPage: React.FC = observer(() => {
  const {instancesStore, notification} = useStore();

  const [prevStep, setPrevStep] = React.useState<CreateServerStep>(CreateServerStep.CHOOSE_PLAN);
  const [currentStep, setCurrentStep] = React.useState<CreateServerStep>(INITIAL_STEP);

  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const [setupPlanFormData, setSetupPlanFormData] = React.useState<ca2instances.ICreateInstanceRequest | null>(null);
  const [createServerResponse, setCreateServerResponse] = React.useState<ca2instances.ICreateInstanceResponse | null>(
    null,
  );

  const clearSelectedPlanData = () => {
    selectedPlan?.resetSetupPlanState();
    setSelectedPlan(null);
    setSetupPlanFormData(null);
    setCreateServerResponse(null);
  };

  const switchToPrevStep = () => {
    const nextStep = currentStep - 1 === CreateServerStep.PAYMENT ? currentStep - 2 : currentStep - 1;

    if (currentStep > 0) {
      if (currentStep - 1 === CreateServerStep.PAYMENT) {
        switchToStep(nextStep);
      } else {
        switchToStep(nextStep);
      }
    }

    if (nextStep === CreateServerStep.CHOOSE_PLAN) {
      clearSelectedPlanData();
    }
  };

  const switchToStep = (step: CreateServerStep) => {
    setPrevStep(currentStep);
    setCurrentStep(step);
  };

  const handelSelectPlan = (plan: Plan) => {
    setSelectedPlan(plan);
    switchToStep(CreateServerStep.SETUP_PLAN);
  };

  const switchToChoosePlan = () => {
    clearSelectedPlanData();

    switchToStep(CreateServerStep.CHOOSE_PLAN);
  };

  const switchToConfirmation = () => {
    switchToStep(CreateServerStep.CONFIRMATION);
  };

  const handleBackButton = () => {
    switchToPrevStep();
  };

  const handleSubmitSetupPlanForm = (formData: ca2instances.ICreateInstanceRequest) => {
    setSetupPlanFormData(formData);

    switchToStep(CreateServerStep.PAYMENT);
  };

  const handleCreateServerInstance = async () => {
    if (!setupPlanFormData) {
      return;
    }

    const {res, error} = await instancesStore.createInstance(setupPlanFormData);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiCreateInstanceError(error);
          notification.error(errorMessage);
        });

        return;
      }

      setCreateServerResponse(res);
      switchToConfirmation();
    }
  };

  const transitions = useOrderTransition({
    prevStep,
    currentStep,
  });

  const renderStepComponent = (step: CreateServerStep) => {
    switch (step) {
      case CreateServerStep.CHOOSE_PLAN:
        return <ChoosePlanStep onSelectPlan={handelSelectPlan} />;
      case CreateServerStep.SETUP_PLAN:
        return selectedPlan ? (
          <SetupPlanForm
            plan={selectedPlan}
            initialValues={setupPlanFormData}
            onSwitchToChoosePlan={switchToChoosePlan}
            onSubmit={handleSubmitSetupPlanForm}
          />
        ) : null;
      case CreateServerStep.PAYMENT:
        return selectedPlan ? (
          <PaymentViewer
            orderInstance={selectedPlan}
            onCancelPaymentOrder={switchToChoosePlan}
            onClickPayButton={handleCreateServerInstance}
          />
        ) : null;
      case CreateServerStep.CONFIRMATION:
        return selectedPlan && createServerResponse ? (
          <CreateServerConfirmation
            plan={selectedPlan}
            createServerResponse={createServerResponse}
            onCancelPaymentOrder={switchToChoosePlan}
          />
        ) : null;
    }
  };

  return (
    <Div100vh className="page page--create-server">
      <CreateServerPageTopBar currentStep={currentStep} onBack={handleBackButton} />

      <div className="page__inner">
        {transitions((styles, step) => (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            {renderStepComponent(step)}
          </animated.div>
        ))}
      </div>
    </Div100vh>
  );
});

export default CreateServerPage;
