import {Box, Button, styled, Tooltip, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as CloseEyeIcon} from '../../../assets/icons/eye-close.svg';
import {ReactComponent as OpenEyeIcon} from '../../../assets/icons/eye-open.svg';
import {Instance} from '../../../stores/Instance';
import AccessesRow from './AccessesRow';

const Container = styled(AccessesRow)(({theme}) => ({
  '& .MuiButtonBase-root': {
    '& svg': {
      fill: theme.palette.body.primary,
    },

    '&:last-of-type': {
      marginLeft: theme.spacing(2),
    },
  },
  '& .password': {
    display: 'flex',
  },
}));

interface IProps {
  instance: Instance;
}

export const RootPassword: React.FC<IProps> = observer(({instance}) => {
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const rootPassword = instance?.rootPassword;

  const handleCopy = async () => {
    if (typeof rootPassword !== 'string') {
      return;
    }

    try {
      await navigator.clipboard.writeText(rootPassword);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    } catch (error) {
      console.error('Error on copy to clipboard: ', error);
    }
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (!rootPassword) {
    return null;
  }

  return (
    <Container>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box>
          <Typography variant="body3" color="body.placeholder">
            {t('server_info_accesses_server_password_label')}
          </Typography>
          <Typography
            sx={{
              fontSize: showPassword ? 'inherit' : 20,
              letterSpacing: showPassword ? 'inherit' : 3,
            }}
          >
            {showPassword ? rootPassword : Array(rootPassword.length).fill('.')}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={(theme) => ({marginLeft: theme.spacing(3)})}
          startIcon={showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
          onClick={handleToggleShowPassword}
        >
          {showPassword ? t('action_button_hide_password') : t('action_button_show_password')}
        </Button>
      </Box>
      <Button variant="outlined" sx={{marginLeft: 'auto'}}>
        {t('action_button_reset_password')}
      </Button>

      <Tooltip
        title={t('server_info_accesses_password_copied_notification')}
        open={tooltipOpen}
      >
        <Button variant="outlined" startIcon={<CopyIcon />} onClick={handleCopy}>
          {t('action_button_copy_password')}
        </Button>
      </Tooltip>
    </Container>
  );
});

export default RootPassword;
