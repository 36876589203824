import {Box, Button, DialogActions, DialogContent, styled} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../api/proto.js';
import {ReactComponent as DeleteIcon} from '../assets/icons/delete.svg';
import TldGroup from '../components/TldGroup.tsx';
import Typography from '../components/UI/Typography';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore.js';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

const ZonesCountBox = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  marginRight: 'auto',
  '& .MuiTypography-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  '& svg path': {
    fill: theme.palette.body.secondary,
  },
}));

interface IProps {
  modal: ModalItem;
}

const ModalTldFilter: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {
    domainsStore: {
      search: {tldGroups, findTldsByGroupId, findTldNamesBySearch},
    },
  } = useStore();

  const modalRef = React.useRef<ModalDialogRef>(null);
  const initialTldNames = props.modal.data.tldNames || [];

  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedTldNames, setSelectedTldNames] = React.useState<string[]>(initialTldNames);

  const isSearching = !!searchText;

  const searchResultsTldGroup = React.useMemo<ca2types.ITLDGroup>(() => {
    const tldsNames = findTldNamesBySearch(searchText);

    return {
      id: 'search_tld',
      title: tldsNames.length
        ? t('component_tld_group_found_zones_title', {count: tldsNames.length})
        : t('component_tld_group_not_found_zones_title'),
      tldsNames,
    };
  }, [searchText, findTldNamesBySearch, t]);

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  const handleToggleSelectedTld = (tldName: string) => {
    const tldNames_ = [...selectedTldNames];
    const index = tldNames_.indexOf(tldName);

    if (index > -1) {
      tldNames_.splice(index, 1);
    } else {
      tldNames_.push(tldName);
    }

    setSelectedTldNames(tldNames_);
  };

  const handleSelectGroup = (groupId: string) => {
    setSelectedTldNames([...new Set([...selectedTldNames, ...findTldsByGroupId(groupId)])]);
  };
  const handleDeselectGroup = (groupId: string) => {
    const foundTlds = findTldsByGroupId(groupId);
    const selectedTlds_ = selectedTldNames.filter((tld) => !foundTlds.includes(tld));
    setSelectedTldNames(selectedTlds_);
  };

  const handleSelectSearchResultsGroup = () => {
    setSelectedTldNames([...new Set([...selectedTldNames, ...(searchResultsTldGroup.tldsNames || [])])]);
  };
  const handleDeselectSearchResultsGroup = () => {
    const selectedTlds_ = selectedTldNames.filter((tld) => !searchResultsTldGroup.tldsNames?.includes(tld));
    setSelectedTldNames(selectedTlds_);
  };

  const handleClearSelected = () => {
    setSelectedTldNames([]);
  };

  const handleClose = () => {
    modalRef.current?.close();
  };

  const handleSubmit = () => {
    props.modal.data.callbacks?.['onSelectTldNames']({tldNames: selectedTldNames});
    handleClose();
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={props.modal}
      headerText={t('modal_tld_filer_title')}
      maxWidth="lg"
      PaperProps={{sx: {height: 750}}}
      withSearch
      fullWidth
      SearchInputProps={{
        value: searchText,
        onChange: handleChangeSearchInput,
        onClear: handleClearSearch,
        placeholder: t('component_tld_group_search_placeholder'),
      }}
    >
      <DialogContent sx={{padding: 0}}>
        {isSearching ? (
          <TldGroup
            group={searchResultsTldGroup}
            selectedTldNames={selectedTldNames}
            onToggleTld={handleToggleSelectedTld}
            onSelectGroup={handleSelectSearchResultsGroup}
            onDeselectGroup={handleDeselectSearchResultsGroup}
          />
        ) : (
          <>
            {tldGroups.map((tldGroup) => (
              <TldGroup
                key={tldGroup.id}
                group={tldGroup}
                selectedTldNames={selectedTldNames}
                onToggleTld={handleToggleSelectedTld}
                onSelectGroup={handleSelectGroup}
                onDeselectGroup={handleDeselectGroup}
              />
            ))}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <ZonesCountBox>
          <Typography variant="body1">
            {t('modal_tld_filer_selected_zones_label', {count: selectedTldNames.length})}
          </Typography>
          {selectedTldNames.length ? (
            <Button variant="outlined" size="large" onClick={handleClearSelected} startIcon={<DeleteIcon />}>
              {t('action_button_clear')}
            </Button>
          ) : null}
        </ZonesCountBox>

        <Button size="large" variant="outlined" onClick={handleClose}>
          {t('action_button_cancel')}
        </Button>
        <Button size="large" onClick={handleSubmit}>
          {t('action_button_submit')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalTldFilter;
