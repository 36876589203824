import {MenuProps, Menu as MuiMenu, styled} from '@mui/material';
import React from 'react';

const Menu = styled(MuiMenu)(({theme}) => ({
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.backgroundSecondary,
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    boxShadow: 'none',
  },
  '& .MuiList-root': {
    padding: 0,
  },
}));

const DropdownMenu: React.FC<MenuProps> = ({anchorEl, ...restProps}) => {
  const getMinWidth = (anchorEl: MenuProps['anchorEl']) => {
    if (anchorEl instanceof HTMLElement) {
      return anchorEl.clientWidth;
    }
    return 'auto';
  };

  return (
    <Menu
      anchorEl={anchorEl}
      slotProps={{
        paper: {
          style: {
            minWidth: getMinWidth(anchorEl),
            maxHeight: 250,
          },
        },
      }}
      {...restProps}
    />
  );
};

export default DropdownMenu;
