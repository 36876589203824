import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../stores/AppStore';
import {Plan} from '../../../stores/Plan';
import Typography from '../../UI/Typography';
import BalanceWithAmountHoursLeft from '../BalanceWithAmountHoursLeft';

interface IProps {
  plan: Plan;
  loading: boolean;
  onClickPayButton?(): void;
}

export const PayPlanButtonWithHoursLeft: React.FC<IProps> = observer((props) => {
  const {instancesStore} = useStore();
  const {t} = useTranslation();

  return (
    <>
      {instancesStore.hasInstances || props.plan ? (
        <Box sx={(theme) => ({marginBottom: theme.spacing(4)})}>
          <BalanceWithAmountHoursLeft plan={props.plan} />
        </Box>
      ) : null}

      <Box sx={{textAlign: 'center'}}>
        <LoadingButton
          variant="contained"
          loading={props.loading}
          fullWidth
          size="large"
          onClick={props.onClickPayButton}
        >
          {t('component_payment_viewer_pay_and_launch_server_button', {
            amount: props.plan.firstHoursRateFormatter.formatted,
          })}
        </LoadingButton>
        <Typography variant="body3" sx={(theme) => ({marginTop: theme.spacing(2), color: theme.palette.body.tertiary})}>
          {t('component_payment_viewer_minimum_payment_duration_for_server', {hours: props.plan.firstPaymentHours})}
        </Typography>
      </Box>
    </>
  );
});

export default PayPlanButtonWithHoursLeft;
