import {observer} from 'mobx-react';
import {Suspense} from 'react';
import {Routes as BrowserRoutes, Navigate, Route, useLocation} from 'react-router-dom';

import {useStore} from '../stores/AppStore';
import {BrowserRouter} from './BrowserRouter';
import Paths from './Paths';
import browserHistory from './browserHistory';
import {IRouteProps, routes} from './routes';

const AppRouteLayout = observer(({component: Component, layout: Layout, isAuthProtected, ...rest}: IRouteProps) => {
  const location = useLocation();
  const {authStore} = useStore();

  if (isAuthProtected && !authStore.isLoggedIn && location.pathname !== Paths.Authorization) {
    return <Navigate to={Paths.Authorization} replace state={{from: location}} />;
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
});

export const Routes = observer(() => {
  // const {userStore} = useStore();

  // React.useEffect(() => {
  //   if (userStore.user?.isInit) {
  //     window.document.documentElement.classList.remove('loading');
  //   }
  // }, [userStore.user]);

  return (
    <BrowserRouter history={browserHistory}>
      <Suspense fallback={<div />}>
        <BrowserRoutes>
          {routes.map((route, idx) => (
            <Route key={idx} element={<AppRouteLayout {...route} />} path={route.path} />
          ))}
        </BrowserRoutes>
      </Suspense>
      {/* <Modals /> */}
    </BrowserRouter>
  );
});

export default Routes;
