import {Button, Typography} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {BillingPageTab} from '../BillingPage/BillingPage';

export const UserBalance: React.FC = observer(() => {
  const {
    billingStore,
    layoutStore: {sidebar},
  } = useStore();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const navigateToBilling = () => {
    navigate(Paths.Billing, {
      state: {
        currentTab: BillingPageTab.TOP_UP_BALANCE,
      },
    });
  };

  return (
    <div className={cn('user-balance', {hide: sidebar.collapsed})}>
      <div className="user-balance__content">
        <div className="user-balance__content-left">
          <Typography className="user-balance__amount-label" variant="body3">
            {t('sidebar_user_balance_title')}
          </Typography>
          <Typography variant="body1" sx={(theme) => ({fontWeight: theme.size.fontWeight.bold})}>
            {billingStore.balanceFormatter.formatted}
          </Typography>
        </div>
        <Button className="user-balance__top-up-button" variant="outlined" size="small" onClick={navigateToBilling}>
          <PlusIcon className="user-balance__plus-icon" />
        </Button>
      </div>
    </div>
  );
});

export default UserBalance;
