import {Box, IconButton, styled} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {ReactComponent as InfoIcon} from '../../../assets/icons/info-orange.svg';
import Typography from '../../../components/UI/Typography';
import AccessesRow from './AccessesRow';

const Container = styled(AccessesRow)(({theme}) => ({
  '& .info-icon': {
    marginRight: theme.spacing(4),
    marginBottom: 'auto',
  },
  '& .close-btn': {
    marginLeft: 'auto',
    '& svg': {
      fill: theme.palette.body.primary,
    },
  },
}));

export const UsageRecommendation: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Container>
      <InfoIcon className="info-icon" />

      <Box>
        <Typography variant="body1">{t('server_info_accesses_usage_recommendation_title')}</Typography>
        <Typography variant="body2" color="body.secondary">
          {t('server_info_accesses_usage_recommendation_description')}
        </Typography>
      </Box>

      <IconButton className="close-btn" edge="end" size="small" tabIndex={-1}>
        <CloseIcon />
      </IconButton>
    </Container>
  );
};

export default UsageRecommendation;
