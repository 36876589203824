import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../../stores/AppStore';

export const ThemeSelector: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {lightThemeChosen} = useStore();

  React.useEffect(() => {
    try {
      const classDict = {
        light: lightThemeChosen,
        dark: !lightThemeChosen,
      };

      for (const [className, condition] of Object.entries(classDict)) {
        if (condition) {
          window.document.documentElement.classList.add(className);
        } else {
          window.document.documentElement.classList.remove(className);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [lightThemeChosen]);

  return <>{children}</>;
});

export default ThemeSelector;
