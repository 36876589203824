import Decimal from 'decimal.js';
import Long from 'long';
import {ca2types} from '../api/proto';

export class PriceFormatter {
  private points_: Decimal;
  private formatted_: string;

  private initialPoints_: Decimal;
  private initialFormatted_: string;

  constructor(private raw_?: ca2types.IAmount | null) {
    this.points_ = new Decimal(raw_?.points?.toNumber() || 0);
    this.formatted_ = raw_?.formatted || '$0';

    this.initialPoints_ = this.points_;
    this.initialFormatted_ = this.formatted_;
  }

  private get decimalDollars(): Decimal {
    return this.points_.dividedBy(10000);
  }

  private get decimalCents(): Decimal {
    return this.points_.dividedBy(100);
  }

  get points(): number {
    return this.points_.toNumber();
  }

  get formatted(): string {
    return this.formatted_;
  }

  get pointsLong(): Long {
    return this.raw_?.points || Long.fromNumber(0);
  }

  get dollars(): number {
    return this.decimalDollars.toDecimalPlaces(2).toNumber();
  }

  get cents(): number {
    return this.decimalCents.toDecimalPlaces(2).toNumber();
  }

  reset(): void {
    this.points_ = this.initialPoints_;
    this.formatted_ = this.initialFormatted_;
  }

  clone(): PriceFormatter {
    const clonedRaw = {
      points: Long.fromNumber(this.points_.toNumber()),
      formatted: this.formatted_,
    } as ca2types.IAmount;
    return new PriceFormatter(clonedRaw);
  }

  update(newRaw?: ca2types.IAmount | null): void {
    if (newRaw) {
      this.points_ = new Decimal(newRaw.points?.toNumber() || 0);
      this.formatted_ = newRaw.formatted || '$0';

      this.initialPoints_ = this.points_;
      this.initialFormatted_ = this.formatted_;
    } else {
      // this.reset();
    }
  }

  private formatWithPrecision_(value: string): string {
    const num = parseFloat(value);

    if (Math.abs(num) >= 0.01) {
      return num.toFixed(2);
    } else {
      return num.toFixed(5);
    }
  }

  private updateFormatted_(): void {
    const formattedValue = this.points_.dividedBy(10000).toFixed(10); // Get the value with high precision (10 decimal places)
    this.formatted_ = `$${this.formatWithPrecision_(formattedValue)}`; // Format and trim to three significant digits after leading zeros
  }

  convertToCrypto(rateInPoints: number): number {
    const rate = new Decimal(rateInPoints);
    const cryptoAmount = this.points_.dividedBy(rate); // Convert points to the amount of cryptocurrency based on the rate
    return parseFloat(cryptoAmount.toFixed(6)); // Round to 6 decimal places and return as a number
  }

  // Mutating methods to add values to the instance with formatted update

  addPoints(value: number): void {
    this.points_ = this.points_.plus(new Decimal(value));
    this.updateFormatted_();
  }

  addDollars(value: number): void {
    const additionalPoints = new Decimal(value).times(10000); // Convert dollars to points
    this.points_ = this.points_.plus(additionalPoints);
    this.updateFormatted_();
  }

  addCents(value: number): void {
    const additionalPoints = new Decimal(value).times(100); // Convert cents to points
    this.points_ = this.points_.plus(additionalPoints);
    this.updateFormatted_();
  }

  addFormatted(value: string): void {
    const valueInPoints = new Decimal(value.replace(/[^0-9.-]+/g, '')).times(10000);
    this.points_ = this.points_.plus(valueInPoints);
    this.updateFormatted_();
  }

  // Mutating methods to subtract values from the instance with formatted update

  subtractPoints(value: number): void {
    this.points_ = this.points_.minus(new Decimal(value));
    this.updateFormatted_();
  }

  subtractDollars(value: number): void {
    const subtractionPoints = new Decimal(value).times(10000); // Convert dollars to points
    this.points_ = this.points_.minus(subtractionPoints);
    this.updateFormatted_();
  }

  subtractCents(value: number): void {
    const subtractionPoints = new Decimal(value).times(100); // Convert cents to points
    this.points_ = this.points_.minus(subtractionPoints);
    this.updateFormatted_();
  }

  subtractFormatted(value: string): void {
    const valueInPoints = new Decimal(value.replace(/[^0-9.-]+/g, '')).times(10000);
    this.points_ = this.points_.minus(valueInPoints);
    this.updateFormatted_();
  }

  // Compare points
  isGreaterThanPoints(value: number): boolean {
    return this.points_.greaterThan(new Decimal(value));
  }

  isLessThanPoints(value: number): boolean {
    return this.points_.lessThan(new Decimal(value));
  }

  isEqualToPoints(value: number): boolean {
    return this.points_.equals(new Decimal(value));
  }

  isGreaterOrEqualPoints(value: number): boolean {
    return this.points_.greaterThanOrEqualTo(new Decimal(value));
  }

  isLessOrEqualPoints(value: number): boolean {
    return this.points_.lessThanOrEqualTo(new Decimal(value));
  }

  // Compare dollars
  isGreaterThanDollars(value: number): boolean {
    return this.decimalDollars.greaterThan(new Decimal(value));
  }

  isLessThanDollars(value: number): boolean {
    return this.decimalDollars.lessThan(new Decimal(value));
  }

  isEqualToDollars(value: number): boolean {
    return this.decimalDollars.equals(new Decimal(value));
  }

  isGreaterOrEqualDollars(value: number): boolean {
    return this.decimalDollars.greaterThanOrEqualTo(new Decimal(value));
  }

  isLessOrEqualDollars(value: number): boolean {
    return this.decimalDollars.lessThanOrEqualTo(new Decimal(value));
  }

  // Compare cents
  isGreaterThanCents(value: number): boolean {
    return this.decimalCents.greaterThan(new Decimal(value));
  }

  isLessThanCents(value: number): boolean {
    return this.decimalCents.lessThan(new Decimal(value));
  }

  isEqualToCents(value: number): boolean {
    return this.decimalCents.equals(new Decimal(value));
  }

  isGreaterOrEqualCents(value: number): boolean {
    return this.decimalCents.greaterThanOrEqualTo(new Decimal(value));
  }

  isLessOrEqualCents(value: number): boolean {
    return this.decimalCents.lessThanOrEqualTo(new Decimal(value));
  }
}

export default PriceFormatter;
