export function extractNameFromSshKey(sshKey: string): string | null {
  const sshKeyPattern =
    /^(ssh-(rsa|dss|ed25519|ecdsa)|ecdsa-sha2-nistp\\d+|sk-ecdsa-sha2-nistp\\d+@openssh\\.com|sk-ssh-ed25519@openssh\\.com) [A-Za-z0-9+\\/=]+ ?(.+)?$/;

  const match = sshKey.match(sshKeyPattern);

  if (match && match[3]) {
    return match[3];
  } else {
    return null;
  }
}

export default extractNameFromSshKey;
