import {Box, Button, styled, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {ProfilePageTab} from './ProfilePage';

const Container = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.backgroundPrimary,
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}));

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(1)}`,
}));

interface IProps {
  currentTab: ProfilePageTab;
  onClickBackButton?(): void;
}

export const ProfilePageTabs: React.FC<IProps> = ({currentTab, onClickBackButton}) => {
  const {t} = useTranslation();

  const isChangeEmailOrPassword = [ProfilePageTab.CHANGE_EMAIL, ProfilePageTab.CHANGE_PASSWORD].includes(currentTab);

  const tabTitles = {
    [ProfilePageTab.PROFILE]: t('profile_account_tab'),
    [ProfilePageTab.CHANGE_EMAIL]: t('profile_change_email_tab'),
    [ProfilePageTab.CHANGE_PASSWORD]: t('profile_change_password_tab'),
  };

  if (isChangeEmailOrPassword) {
    return (
      <div className="profile-page-top-bar">
        <div className="profile-page-top-bar__back">
          <Button
            className="profile-page-top-bar__back-btn"
            variant="outlined"
            size="large"
            onClick={onClickBackButton}
            sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
          >
            <ArrowLeftIcon width={24} height={24} />
          </Button>
        </div>
        <div className="profile-page-top-bar__title-wrap">
          <Typography variant="body3" color="body.tertiary">
            {t('profile_title')}
            <Delimiter component="span">/</Delimiter>
            {tabTitles[ProfilePageTab.PROFILE]}
          </Typography>
          <div className="profile-page-top-bar__title-bottom">
            <Typography variant="h4" component="span">
              {tabTitles[currentTab]}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Typography variant="h4">{t('profile_title')}</Typography>
    </Container>
  );
};

export default ProfilePageTabs;
