import {useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as DigitalOceanLogo} from '../assets/image-icons/digital-ocean.svg';
import {ReactComponent as LinodeLogo} from '../assets/image-icons/linode.svg';
import {ReactComponent as VultrLogo} from '../assets/image-icons/vultr.svg';

interface IProps {
  providerId?: string | null;
}

export const ProviderLogo: React.FC<IProps> = ({providerId}) => {
  const theme = useTheme();

  const providerLogoById = {
    ['do']: <DigitalOceanLogo />,
    ['linode']: <LinodeLogo style={{fill: theme.palette.body.primary}} />,
    ['vultr']: <VultrLogo />,
  };

  if (!providerId) {
    return;
  }

  return providerLogoById[providerId] || null;
};

export default ProviderLogo;
