import {makeObservable} from 'mobx';
import BaseServerOptions from './BaseServerOptions';

export class ServerOptions<T extends {id?: string | null}> extends BaseServerOptions<T> {
  constructor() {
    super();
    makeObservable(this);
  }

  findByIds(ids: string[]) {
    return this.list.filter((item) => item.id && ids.includes(item.id));
  }

  findById(id: string) {
    return this.list.find((item) => item.id && id === item.id);
  }
}

export default ServerOptions;
