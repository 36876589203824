import {CURRENCY_FRACTION_MAX_DIGITS, CURRENCY_FRACTION_MIN_DIGITS} from '../../constants';

interface IOptions {
  currency?: string;
  locale?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export function formatCurrency(
  value: number,
  {
    currency = 'USD',
    locale = 'en-US',
    minimumFractionDigits = CURRENCY_FRACTION_MIN_DIGITS,
    maximumFractionDigits = CURRENCY_FRACTION_MAX_DIGITS,
  }: IOptions = {},
) {
  if (typeof value !== 'number') {
    throw new Error('Value must be a number');
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}
