import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';

export class Currency {
  @observable code: string | null = null;
  @observable private title: string | null = null;
  @observable private rate: ca2types.IAmount | null = null;
  @observable private minAmount: ca2types.IAmount | null = null;
  @observable places: number | null = null;

  @observable rateFormatter: PriceFormatter;
  @observable minAmountFormatter: PriceFormatter;

  constructor(public raw: ca2types.ICurrency) {
    makeObservable(this);
    this.update_(raw);

    this.rateFormatter = new PriceFormatter(this.rate);
    this.minAmountFormatter = new PriceFormatter(this.minAmount);
  }

  @computed get name(): string {
    const code = this.code?.toUpperCase() || '';

    if (!code) {
      return '';
    }

    const networkMap: {[key: string]: string} = {
      USDTT: 'TRC20',
      USDTE: 'ERC20',
    };

    const network = networkMap[code] || '';
    const name = ['USDTT', 'USDTE'].includes(code) ? 'USDT' : code;

    return `${name} ${network}`;
  }

  @action
  private update_ = (raw: ca2types.ICurrency) => {
    Object.assign(this, raw);
  };
}

export default Currency;
