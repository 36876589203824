import {blue, gray, green, orange, red} from './baseColors';

const light = {
  background: {
    primary: gray[0],
    secondary: gray[50],
    tertiary: gray[100],
    quaternary: gray[200],
  },
  body: {
    primary: gray[1000],
    secondary: gray[800],
    tertiary: gray[700],
    placeholder: gray[600],
    tint: gray[0],
  },
  border: {
    primary: gray[200],
    secondary: gray[250],
    tertiary: gray[300],
  },
  brand: {
    primary: blue[700],
    secondary: blue[600],
    tertiary: blue[750],
    quaternary: blue[800],
    tint3: blue[100],
    tint2: blue[50],
    tint1: blue[0],
    alpha3: '#480dcf4d',
    alpha2: '#480dcf33',
    alpha1: '#480dcf1a',
  },
  shadow: {
    primary: gray[100],
  },
  error: {
    primary: red[400],
    secondary: red[300],
    tertiary: red[600],
    tint3: red[100],
    tint2: red[50],
    tint1: red[0],
    alpha3: '#ed2d454d',
    alpha2: '#ed2d4533',
    alpha1: '#ed2d451a',
  },
  success: {
    primary: green[400],
    secondary: green[250],
    tertiary: green[600],
    tint3: green[100],
    tint2: green[50],
    tint1: green[0],
    alpha3: '#0fa4644d',
    alpha2: '#0fa46433',
    alpha1: '#0fa4641a',
  },
  warning: {
    primary: orange[200],
    secondary: orange[100],
    tertiary: orange[500],
    tint3: orange[100],
    tint2: orange[50],
    tint1: orange[0],
    alpha3: '#faa01b4d',
    alpha2: '#faa01b33',
    alpha1: '#faa01b1a',
  },
};

const dark = {
  background: {
    primary: gray[900],
    secondary: gray[800],
    tertiary: gray[750],
    quaternary: gray[700],
  },
  body: {
    primary: gray[100],
    secondary: gray[250],
    tertiary: gray[400],
    placeholder: gray[500],
    tint: gray[0],
  },
  border: {
    primary: gray[700],
    secondary: gray[600],
    tertiary: gray[500],
  },
  brand: {
    primary: blue[500],
    secondary: blue[400],
    tertiary: blue[600],
    quaternary: blue[700],
    tint3: blue[900],
    tint2: blue[950],
    tint1: blue[1000],
    alpha3: '#6d56ff4d',
    alpha2: '#6d56ff33',
    alpha1: '#6d56ff1a',
  },
  shadow: {
    primary: gray[1000],
  },
  error: {
    primary: red[400],
    secondary: red[300],
    tertiary: red[600],
    tint3: red[900],
    tint2: red[950],
    tint1: red[1000],
    alpha3: '#ed2d454d',
    alpha2: '#ed2d4533',
    alpha1: '#ed2d451a',
  },
  success: {
    primary: green[400],
    secondary: green[250],
    tertiary: green[600],
    tint3: green[900],
    tint2: green[950],
    tint1: green[1000],
    alpha3: '#0fa4644d',
    alpha2: '#0fa46433',
    alpha1: '#0fa4641a',
  },
  warning: {
    primary: orange[200],
    secondary: orange[100],
    tertiary: orange[500],
    tint3: orange[900],
    tint2: orange[950],
    tint1: orange[1000],
    alpha3: '#faa01b4d',
    alpha2: '#faa01b33',
    alpha1: '#faa01b1a',
  },
};

const palette = {
  dark,
  light,
};

export default palette;
