import {LoadingButton} from '@mui/lab';
import {Box, Button, styled} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import TextField, {TextFieldProps} from './UI/TextField';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  gap: theme.spacing(2),

  '& .buttons': {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
  },
}));

export interface ITextFieldWithSubmitButtonsRef {
  showButtons(): void;
  hideButtons(): void;
}

interface IProps {
  TextFieldProps?: TextFieldProps;
  onSubmit?(): void;
  onCancel?(): void;
  loading?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  disableSubmitButton?: boolean;
  alwaysShowButtons?: boolean;
}

const TextFieldWithSubmitButton = React.forwardRef<ITextFieldWithSubmitButtonsRef, IProps>((props, ref) => {
  const {t} = useTranslation();
  const [isFocus, setIsFocus] = React.useState<boolean>(!!props.alwaysShowButtons);

  React.useImperativeHandle(ref, () => ({
    showButtons: () => {
      setIsFocus(true);
    },
    hideButtons: () => {
      setIsFocus(false);
    },
  }));

  const handleFocus = () => {
    setIsFocus(true);
  };

  return (
    <Container>
      <TextField fullWidth onFocus={handleFocus} {...props.TextFieldProps} />

      {isFocus || props.alwaysShowButtons ? (
        <Box className="buttons" sx={{marginTop: props.TextFieldProps?.label ? '18px' : 0}}>
          <LoadingButton
            loading={props.loading}
            variant="contained"
            size="large"
            onClick={props.onSubmit}
            disabled={props.disableSubmitButton}
          >
            {props.submitButtonText || t('action_button_submit')}
          </LoadingButton>
          <Button variant="outlined" size="large" onClick={props.onCancel}>
            {props.cancelButtonText || t('action_button_cancel')}
          </Button>
        </Box>
      ) : null}
    </Container>
  );
});

export default TextFieldWithSubmitButton;
