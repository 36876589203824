import {Box, BoxProps} from '@mui/material';
import React from 'react';

export const PageContentLayout: React.FC<BoxProps> = (props) => {
  return (
    <div className="page-content">
      <div className="page-content__wrapper">
        <div className="page-content__inner">
          <Box {...props} />
        </div>
      </div>
    </div>
  );
};

export default PageContentLayout;
