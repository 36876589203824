import {MenuItem, MenuItemProps, styled} from '@mui/material';
import cn from 'classnames';
import React, {PropsWithChildren} from 'react';
import {matchPath, useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';
import {ReactComponent as ArrowRightIcon} from '../assets/icons/arrow-right.svg';
import Paths from '../routes/Paths';

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  padding: theme.spacing(3),
  border: `1px solid transparent`,
  borderRadius: '8px',
  color: theme.palette.body.placeholder,
  transition: theme.transitions.create(['border-color', 'background-color', 'color']),

  '& .active-item-icon': {
    display: 'none',
    fill: theme.palette.body.placeholder,
  },
  '.sidebarCollapsed &': {
    '& .MuiTypography-root, .active-item-icon': {
      display: 'none !important',
    },
  },

  '& .MuiListItemIcon-root path': {
    transition: theme.transitions.create(['fill']),
    fill: theme.palette.body.placeholder,
  },

  '&:not(.active):hover': {
    color: theme.palette.body.secondary,
    backgroundColor: theme.palette.backgroundSecondary,
    '& .MuiListItemIcon-root path': {
      fill: theme.palette.body.secondary,
    },
  },

  '&.active': {
    borderColor: theme.palette.border.primary,
    color: theme.palette.body.primary,
    cursor: 'inherit',
    '& .MuiListItemIcon-root path': {
      fill: theme.palette.body.primary,
    },
    '& .active-item-icon': {
      display: 'block',
    },
  },
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2),
  },
}));

interface ISidebarMenuItemProps extends MenuItemProps {
  path?: Paths;
  childPaths?: Paths[];
}

export const SidebarMenuItem: React.FC<PropsWithChildren<ISidebarMenuItemProps>> = ({
  path,
  childPaths,
  children,
  disableRipple = true,
  ...restProps
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  let isActive = path ? matchPath(path, location.pathname) : false;

  let hasChildPaths = false;

  if (!isActive && childPaths?.length) {
    hasChildPaths = childPaths?.some((path) => !!matchPath(path, location.pathname));
    isActive = childPaths.some((path) => !!matchPath(path, location.pathname));
  }

  const handleClick = () => {
    if (isActive) {
      if (hasChildPaths) {
        path && navigate(path);
      }
      return;
    }

    path && navigate(path);
  };

  return (
    <StyledMenuItem
      onClick={handleClick}
      className={cn({active: isActive})}
      disableRipple={disableRipple}
      {...restProps}
    >
      {children}
      <ArrowRightIcon className="active-item-icon" />
    </StyledMenuItem>
  );
};

export default SidebarMenuItem;
