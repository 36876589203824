import {ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps, styled} from '@mui/material';
import React from 'react';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({theme}) => ({
  display: 'flex',
  backgroundColor: theme.palette.backgroundSecondary,
  padding: theme.spacing(1),
  borderRadius: '12px',
  '& .MuiButtonBase-root': {
    flex: '1 1 auto',
    padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
    fontSize: theme.size.fontSize.S,
    fontWeight: theme.size.fontWeight.regular,
    color: theme.palette.body.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: `1px solid transparent`,
    margin: 0,
    '&.Mui-selected': {
      color: theme.palette.body.primary,
      borderColor: theme.palette.border.primary,
      backgroundColor: theme.palette.backgroundPrimary,
    },
  },
}));

export const InlineToggleButtonGroup: React.FC<ToggleButtonGroupProps> = (props) => {
  return <ToggleButtonGroup exclusive {...props} />;
};

export default InlineToggleButtonGroup;
