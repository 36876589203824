import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers} from '../../api/proto';
import {PLAN_MONTHLY_PRICE_MAX} from '../../constants';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import PlansStore from './PlansStore';

export function getInitialOptionsValueIdx(listLength?: number): [number, number] {
  if (!listLength) {
    return [0, 0];
  }

  return [0, listLength - 1];
}

export class PlansFilterStore extends APILayer {
  constructor(public app: AppStore, private plans: PlansStore) {
    super(app);
    makeObservable(this);
  }

  @observable cpuOptions: number[] = [];
  @observable ramOptions: number[] = [];
  @observable storageOptions: number[] = [];
  @observable transferOptions: number[] = [];

  @observable maxMonthlyRateUsd: number = PLAN_MONTHLY_PRICE_MAX;

  @action setMaxMonthlyRateUsd = (priceRate: number) => {
    this.maxMonthlyRateUsd = priceRate;
    this.monthlyPriceRange = getInitialOptionsValueIdx(priceRate);
  };

  @observable monthlyPriceRange: [number, number] = getInitialOptionsValueIdx(this.maxMonthlyRateUsd);

  @observable cpuRangeIdx: [number, number] = [0, 0];
  @observable ramRangeIdx: [number, number] = [0, 0];
  @observable storageRangeIdx: [number, number] = [0, 0];
  @observable transferRangeIdx: [number, number] = [0, 0];

  @action private resetToInitial_ = () => {
    this.monthlyPriceRange = getInitialOptionsValueIdx(this.maxMonthlyRateUsd);
    this.cpuRangeIdx = getInitialOptionsValueIdx(this.cpuOptions.length);
    this.ramRangeIdx = getInitialOptionsValueIdx(this.ramOptions.length);
    this.storageRangeIdx = getInitialOptionsValueIdx(this.storageOptions.length);
    this.transferRangeIdx = getInitialOptionsValueIdx(this.transferOptions.length);
  };

  reset = () => {
    this.resetToInitial_();
    this.plans.app.serversStore.resetSelectedOptions();

    this.plans.reload();
  };

  @computed get requestData(): ca2servers.IPlansListRequest {
    return {
      monthlyRateUsdRange: {
        min: this.monthlyPriceRange[0],
        max: this.monthlyPriceRange[1],
      },
      tabId: this.app.serversStore.tabs.selectedId,
      providerIds: this.app.serversStore.providers.selectedId ? [this.app.serversStore.providers.selectedId] : [],
      cpuModels: this.app.serversStore.cpuModels.selectedId ? [this.app.serversStore.cpuModels.selectedId] : [],
      gpuModels: this.app.serversStore.gpuModels.selectedId ? [this.app.serversStore.gpuModels.selectedId] : [],
      regionIds: this.app.serversStore.regions.selectedId ? [this.app.serversStore.regions.selectedId] : [],
      cpusRange: {
        min: this.cpuOptions[this.cpuRangeIdx[0]],
        max: this.cpuOptions[this.cpuRangeIdx[1]],
      },
      ramRange: {
        min: Long.fromNumber(this.ramOptions[this.ramRangeIdx[0]]),
        max: Long.fromNumber(this.ramOptions[this.ramRangeIdx[1]]),
      },
      storageRange: {
        min: Long.fromNumber(this.storageOptions[this.storageRangeIdx[0]]),
        max: Long.fromNumber(this.storageOptions[this.storageRangeIdx[1]]),
      },
      transferRange: {
        min: Long.fromNumber(this.transferOptions[this.transferRangeIdx[0]]),
        max: Long.fromNumber(this.transferOptions[this.transferRangeIdx[1]]),
      },
    };
  }

  @action onChangeMonthlyPriceRange = (range: [number, number]) => {
    this.monthlyPriceRange = range;

    this.plans.reload();
  };

  @action onChangeCpuRange = (rangeIdx: [number, number]) => {
    this.cpuRangeIdx = rangeIdx;

    this.plans.reload();
  };

  @action onChangeRamRange = (rangeIdx: [number, number]) => {
    this.ramRangeIdx = rangeIdx;

    this.plans.reload();
  };

  @action onChangeStorageRange = (rangeIdx: [number, number]) => {
    this.storageRangeIdx = rangeIdx;

    this.plans.reload();
  };

  @action onChangeTransferRange = (rangeIdx: [number, number]) => {
    this.transferRangeIdx = rangeIdx;

    this.plans.reload();
  };

  loadOptions = async () => {
    const {res} = await this.request({
      servers: {
        cpusOptions: {},
        ramOptions: {},
        storageOptions: {},
        transferOptions: {},
      },
    });

    if (res?.servers) {
      this.processLoadOptions_(res.servers);
    }
  };

  @action private processLoadOptions_ = (res: ca2servers.IResponse) => {
    this.cpuOptions = res.cpusOptions?.items || [];
    this.ramOptions = res.ramOptions?.items?.map((item) => item.toNumber()) || [];
    this.storageOptions = res.storageOptions?.items?.map((item) => item.toNumber()) || [];
    this.transferOptions = res.transferOptions?.items?.map((item) => item.toNumber()) || [];

    this.resetToInitial_();
  };
}

export default PlansFilterStore;
