import {Box, styled} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../../../api/proto';
import DropdownMenu from '../../../components/UI/DropdownMenu';
import MenuItem from '../../../components/UI/MenuItem';
import {useStore} from '../../../stores/AppStore';
import {ModalType} from '../../../stores/ModalsStore';

const CustomButton = styled(Box)(({theme}) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.body.placeholder,
  '&:hover, &.active': {
    color: theme.palette.body.primary,
  },
  '&:after': {
    position: 'absolute',
    content: '"..."',
    height: '10px',
    overflow: 'hidden',
    lineHeight: 0,
  },
}));

const Menu = styled(DropdownMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    minWidth: '150px',
    border: `1px solid ${theme.palette.border.primary}`,
  },
}));

interface IProps {
  sshKey: ca2types.ISSHKey;
}

export const SshKeyActionsDropdown: React.FC<IProps> = observer(({sshKey}) => {
  const {t} = useTranslation();
  const {modals} = useStore();
  const [dropdownEl, setDropdownEl] = React.useState<HTMLElement | null>(null);

  const handleToggleDropdown = (event) => {
    setDropdownEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setDropdownEl(null);
  };

  const handleEdit = () => {
    modals.open(ModalType.EDIT_SSH_KEY, {sshKey});
    handleCloseDropdown();
  };

  const handleDelete = () => {
    modals.open(ModalType.DELETE_SSH_KEY, {sshKey});
    handleCloseDropdown();
  };

  return (
    <>
      <CustomButton onClick={handleToggleDropdown} className={cn({active: !!dropdownEl})} />
      <Menu anchorEl={dropdownEl} open={Boolean(dropdownEl)} onClose={handleCloseDropdown}>
        <MenuItem onClick={handleEdit}>{t('ssh_keys_table_actions_edit')}</MenuItem>
        <MenuItem onClick={handleDelete} sx={(theme) => ({color: theme.palette.error.primary})}>
          {t('ssh_keys_table_actions_delete')}
        </MenuItem>
      </Menu>
    </>
  );
});

export default SshKeyActionsDropdown;
