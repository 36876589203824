import {styled} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import PageContentLayout from '../../../layouts/PageContentLayout';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import DomainSearchInput from './DomainSearchInput';
import DomainSuggestions from './DomainSuggestions';

const Container = styled(PageContentLayout)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  height: '100%',

  '&:not(.with-suggestions)': {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  onRegisterDomain?(suggestion: DomainSuggestion): void;
}

export const DomainsSearch: React.FC<IProps> = observer((props) => {
  const {domainsView} = useStore();
  return (
    <Container className={cn({'with-suggestions': domainsView.isSearchDomainStart})}>
      <DomainSearchInput />

      {domainsView.isSearchDomainStart && <DomainSuggestions onRegisterDomain={props.onRegisterDomain} />}
    </Container>
  );
});

export default DomainsSearch;
