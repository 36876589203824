import {RadioProps, FormControlLabel, FormControlLabelProps, useTheme, Radio} from '@mui/material';
import React from 'react';
import {ReactComponent as RadioCheckedIcon} from '../../assets/icons/radio-button-checked.svg';
import {ReactComponent as RadioIcon} from '../../assets/icons/radio-button.svg';

interface IRadioFieldProps extends RadioProps {
  label?: string | JSX.Element;
  FormLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
}

export const RadioField: React.FC<IRadioFieldProps> = ({label, FormLabelProps, ...restProps}) => {
  const theme = useTheme();

  const checkboxEl = (
    <Radio
      icon={<RadioIcon style={{stroke: theme.palette.body.placeholder}} />}
      checkedIcon={<RadioCheckedIcon style={{fill: theme.palette.brand.primary}} />}
      {...restProps}
    />
  );

  if (!label) {
    return checkboxEl;
  }

  return <FormControlLabel {...FormLabelProps} control={checkboxEl} label={label} />;
};

export default RadioField;
