export function extractFirstLetter(str?: string | null) {
  return str && str.charCodeAt(0) <= 2048 ? str.charAt(0) : '';
}

const cashStore = new Map<string | null | undefined, string>();

export const extractInitials = (text?: string | null): string => {
  const cashValue = cashStore.get(text);
  if (cashValue) {
    return cashValue;
  }

  const names = text?.split(' ');
  let initials = '';
  if (names?.length) {
    initials =
      names.length > 1 ? extractFirstLetter(names[0]) + extractFirstLetter(names[1]) : extractFirstLetter(names[0]);
  }

  cashStore.set(text, initials);
  return initials;
};

export default extractInitials;
