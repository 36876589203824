import {action, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';

export class Addon {
  @observable id: string | null = null;
  @observable providerId: string | null = null;
  @observable title: string | null = null;
  @observable description: string | null = null;
  @observable private monthlyRate: ca2types.IAmount | null = null;
  @observable private hourlyRate: ca2types.IAmount | null = null;

  @observable monthlyRateFormatter: PriceFormatter;
  @observable hourlyRateFormatter: PriceFormatter;

  constructor(public raw: ca2types.IAddon) {
    makeObservable(this);
    this.update_(raw);

    this.monthlyRateFormatter = new PriceFormatter(this.monthlyRate);
    this.hourlyRateFormatter = new PriceFormatter(this.hourlyRate);
  }

  @action private update_ = (raw: ca2types.IAddon) => {
    Object.assign(this, raw);
  };
}

export default Addon;
