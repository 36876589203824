import {Box, Button, Container} from '@mui/material';
import {observer} from 'mobx-react';
import PasswordInput from '../components/UI/PasswordInput';
import PasswordSmartInput from '../components/UI/PasswordSmartInput';
import {SearchInput} from '../components/UI/SearchInput';
import {Switch} from '../components/UI/Switch';
import {TextField} from '../components/UI/TextField';
import Typography from '../components/UI/Typography';
import {useStore} from '../stores/AppStore';

const UITheme = observer(() => {
  const {layoutStore} = useStore();

  return (
    <Container className="custom-scroll" style={{padding: 20, overflowY: 'scroll', height: '100vh'}}>
      <div style={{marginBottom: 20}}>
        <Button variant="contained" size="large" onClick={layoutStore.uiTheme.toggleCurrentTheme}>
          Toggle theme
        </Button>
      </div>
      <Box sx={{'& button': {m: 1}}}>
        <div>
          <Button variant="outlined" size="small">
            Label Small
          </Button>
          <Button variant="outlined" size="medium">
            Label Medium
          </Button>
          <Button variant="outlined" size="large">
            Label Large
          </Button>
          <Button variant="outlined" size="large" disabled>
            Large disabled
          </Button>
        </div>
        <div>
          <Button variant="contained" size="small">
            Label Small
          </Button>
          <Button variant="contained" size="medium">
            Label Medium
          </Button>
          <Button variant="contained" size="large">
            Label Large
          </Button>
          <Button variant="contained" size="large" disabled>
            Large disabled
          </Button>
        </div>
      </Box>
      <Box sx={{width: '100%', maxWidth: 800}}>
        <Typography variant="h1" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom>
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom>
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom>
          h4. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle3" gutterBottom component="h6">
          subtitle3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="label1" gutterBottom>
          Label 1
        </Typography>
        <Typography variant="label2" gutterBottom>
          Label 2
        </Typography>
        <Typography variant="label3" gutterBottom>
          Label 3
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
          beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
          Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
          beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
          Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body3" gutterBottom>
          body3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
          beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
          Eum quasi quidem quibusdam.
        </Typography>
      </Box>
      <Box sx={{'& > div': {m: 3}}}>
        <Switch />
        {/* <Switch startLabel="Off" endLabel="On" defaultChecked /> */}
      </Box>
      <Box sx={{'& > div': {m: 3}}} style={{maxWidth: 400}}>
        <div>
          <SearchInput placeholder="Search" />
        </div>
        <div style={{minWidth: '500px'}}>
          <PasswordSmartInput placeholder="Input password" label="Password field" fullWidth />
        </div>
        <div>
          <PasswordInput placeholder="Input password" label="Password field" />
        </div>
        <div>
          <TextField placeholder="Placeholder" label="Label" />
        </div>
        <div>
          <TextField placeholder="Placeholder" label="Label" helperText="Helper text" />
        </div>
        <div>
          <TextField placeholder="Placeholder" label="Label" error helperText="Error text" />
        </div>

        <div>
          <TextField placeholder="Placeholder" label="Label" disabled />
        </div>
      </Box>
    </Container>
  );
});

export default UITheme;
