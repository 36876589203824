
export function isObject(o: any): boolean {
  return Object.prototype.toString.call(o) === '[object Object]';
}

export default isObject;

export function isPlainObject(o: any): boolean {
  if (isObject(o) === false) return false;

  // If has modified constructor
  const ctor = o.constructor;
  if (ctor === undefined) return true;

  // If has modified prototype
  const prot = ctor.prototype;
  if (isObject(prot) === false) return false;

  // If constructor does not have an Object-specific method
  if (Object.prototype.hasOwnProperty.call(prot, 'isPrototypeOf') === false) {
    return false;
  }

  // Most likely a plain Object
  return true;
}
