import {Box, Button, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import Div100vh from '../../components/Div100vh';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import {ModalType} from '../../stores/ModalsStore';
import SshKeysPageHead from './SshKeysPageHead';
import SshKeysTable from './SshKeysTable/SshKeysTable';

const Container = styled(PageContentLayout)(() => ({
  position: 'relative',
  height: '100%',

  '&.noKeysAdded': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export const SshKeysPage: React.FC = observer(() => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {sshKeysStore, modals} = useStore();

  const [searchText, setSearchText] = React.useState<string>('');

  const hasCreatedKeys = !!sshKeysStore.list.length;

  const filteredKeysList = React.useMemo(() => {
    if (!searchText) {
      return sshKeysStore.list;
    }

    return sshKeysStore.list.filter(({name}) => name?.includes(searchText));
  }, [sshKeysStore.list, searchText]);

  const handleClickAddSshKey = () => {
    modals.open(ModalType.ADD_SSH_KEY);
  };

  return (
    <Div100vh className="page">
      {hasCreatedKeys ? (
        <SshKeysPageHead searchText={searchText} onSearch={setSearchText} onClickAddSshKey={handleClickAddSshKey} />
      ) : null}

      <Container className={cn({noKeysAdded: !hasCreatedKeys})}>
        {hasCreatedKeys ? (
          <>
            {filteredKeysList.length ? (
              <SshKeysTable sshKeys={filteredKeysList} />
            ) : (
              <Typography
                variant="body2"
                color="body.placeholder"
                sx={{paddingTop: theme.spacing(6), textAlign: 'center'}}
              >
                {t('ssh_keys_no_keys_no_keys_found')}
              </Typography>
            )}
          </>
        ) : (
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {t('ssh_keys_no_keys_added')}
            </Typography>
            <Typography variant="body1" sx={{marginBottom: theme.spacing(4)}} color="body.tertiary">
              {t('ssh_keys_no_keys_added_description')}
            </Typography>

            <Button
              startIcon={<PlusIcon style={{fill: 'currentcolor'}} />}
              variant="contained"
              onClick={handleClickAddSshKey}
              size="large"
            >
              {t('ssh_keys_no_keys_added_add_button')}
            </Button>
          </Box>
        )}
      </Container>
    </Div100vh>
  );
});

export default SshKeysPage;
