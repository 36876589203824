import {IconButton, useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as CloseEyeIcon} from '../../assets/icons/eye-close.svg';
import {ReactComponent as OpenEyeIcon} from '../../assets/icons/eye-open.svg';
import {TextField, TextFieldProps} from './TextField';

const PasswordInput: React.FC<TextFieldProps> = (props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const iconStyles = {fill: theme.palette.body.placeholder};

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton sx={{padding: 0}} disableRipple onClick={handleToggleShowPassword}>
          {showPassword ? <CloseEyeIcon style={iconStyles} /> : <OpenEyeIcon style={iconStyles} />}
        </IconButton>
      }
    />
  );
};

export default PasswordInput;
