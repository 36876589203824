import {GoogleOAuthProvider} from '@react-oauth/google';
import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../stores/AppStore';

export const AuthProvider: React.FC<React.PropsWithChildren> = observer(({children}) => {
  const {authStore} = useStore();

  if (!authStore.googleAuthClientId) {
    return <>{children}</>;
  }

  return <GoogleOAuthProvider clientId={authStore.googleAuthClientId}>{children}</GoogleOAuthProvider>;
});

export default AuthProvider;
