const blue = {
  0: '#f7fcff',
  50: '#e2e6ff',
  100: '#c9c4ff',
  200: '#b0a9ff',
  250: '#a59bff',
  300: '#998eff',
  400: '#8372ff',
  500: '#6d56ff',
  600: '#5a38eb',
  700: '#480dcf',
  750: '#3200a5',
  800: '#260083',
  900: '#1e0062',
  950: '#0c0031',
  1000: '#030014',
};

const gray = {
  0: '#ffffff',
  50: '#f5f5f5',
  100: '#ebebeb',
  200: '#dbdbdb',
  250: '#cccccc',
  300: '#b8b8b8',
  400: '#9e9e9e',
  500: '#7a7a7a',
  600: '#565656',
  700: '#414141',
  750: '#2e2e2e',
  800: '#242424',
  900: '#1c1c1c',
  950: '#141414',
  1000: '#0d0d0d',
};

const green = {
  0: '#e6fff3',
  50: '#b8ffd5',
  100: '#77f3ad',
  200: '#5bd894',
  250: '#4ccb88',
  300: '#3cbe7c',
  400: '#0fa464',
  500: '#008e50',
  600: '#006a2e',
  700: '#005218',
  750: '#003c01',
  800: '#002600',
  900: '#001b00',
  950: '#000e00',
  1000: '#000000',
};

const orange = {
  0: '#fffcdb',
  50: '#ffe7a3',
  100: '#ffd15f',
  200: '#faa01b',
  250: '#ec9300',
  300: '#df8700',
  400: '#c46e00',
  500: '#a95500',
  600: '#8f3d00',
  700: '#7f2d00',
  750: '#661200',
  800: '#4d0000',
  900: '#280000',
  950: '#190000',
  1000: '#050000',
};

const red = {
  0: '#ffeeec',
  50: '#ffcbc8',
  100: '#ff9fa3',
  200: '#ff8b90',
  250: '#ff6f78',
  300: '#ff5260',
  400: '#ed2d45',
  500: '#d0002d',
  600: '#b60017',
  700: '#990000',
  750: '#840000',
  800: '#670000',
  900: '#4a0000',
  950: '#1e0000',
  1000: '#0b0000',
};

export {blue, gray, green, orange, red};
