import {DialogContent} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../api/proto';
import SshKeyItem from '../components/SshKeyItem';
import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore/ModalsStore';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';

interface IProps {
  modal: ModalItem;
}

const ModalSelectSshKey: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation();
  const {sshKeysStore} = useStore();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const isSelected = (id?: number | null) => {
    return !!(id && props.modal.data.selectedSshKeysIds?.includes(id));
  };

  const handleClick = (sshKey: ca2types.ISSHKey) => {
    props.modal.data.callbacks?.['onSelectSshKey']({sshKey});
    modalRef.current?.close();
  };

  return (
    <ModalDialog ref={modalRef} modal={props.modal} headerText={t('modal_select_ssh_key_title')}>
      <DialogContent sx={(theme) => ({padding: theme.spacing(1)})}>
        {sshKeysStore.list.map((sshKey) => (
          <SshKeyItem key={sshKey.id} sshKey={sshKey} selected={isSelected(sshKey.id)} onClick={handleClick} />
        ))}
      </DialogContent>
    </ModalDialog>
  );
});

export default ModalSelectSshKey;
