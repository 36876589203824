import {makeObservable} from 'mobx';
import {ca2types} from '../../../api/proto';
import ServerOptions from './ServerOptions';

export class ImageOptions extends ServerOptions<ca2types.IImage> {
  constructor() {
    super();
    makeObservable(this);
  }

  getDictionaryByFamily = (
    providerId: string | null,
    allowWindowsImages: boolean = false,
  ): Record<string, ca2types.IImage[]> => {
    return this.list.reduce<Record<string, ca2types.IImage[]>>((acc, image) => {
      if (image.isWindows && !allowWindowsImages) {
        return acc;
      }

      if (image.providerId !== providerId) {
        return acc;
      }

      if (image.deprecated) {
        return acc;
      }

      if (image.family && !acc[image.family]) {
        acc[image.family] = [];
      }

      if (image.family) {
        acc[image.family].push(image);
      }

      return acc;
    }, {});
  };

  findImageById = (imageId: string | null): ca2types.IImage | null => {
    return imageId ? this.list.find(({id}) => id && id === imageId) || null : null;
  };
}

export default ImageOptions;
