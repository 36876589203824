import {Box, styled} from '@mui/material';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DomainsLogoDark} from '../../../../assets/image-icons/domain-logo-dark.svg';
import {ReactComponent as DomainsLogo} from '../../../../assets/image-icons/domain-logo.svg';
import Typography from '../../../../components/UI/Typography';
import {useStore} from '../../../../stores/AppStore';

const Container = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(5),
  textAlign: 'center',
  '& svg': {
    marginBottom: theme.spacing(2),
  },
}));

export const DomainsSearchLogoTitle = observer(() => {
  const {t} = useTranslation();
  const {lightThemeChosen} = useStore();

  return (
    <Container>
      {lightThemeChosen ? <DomainsLogo /> : <DomainsLogoDark />}

      <Typography variant="h3">{t('domains_search_title')}</Typography>
    </Container>
  );
});

export default DomainsSearchLogoTitle;
