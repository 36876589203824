import {Box, Button, Menu as MuiMenu, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PropertiesIcon} from '../../assets/icons/properties.svg';
import MenuItem from '../../components/UI/MenuItem';
import {Switch} from '../../components/UI/Switch';
import {Column, ColumnType} from './useServersTableColumns';

const CounterBox = styled(Box)(({theme}) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: `0 ${theme.spacing(1)}`,
  marginLeft: theme.spacing(2),
  borderRadius: '8px',
  fontSize: theme.size.fontSize.XS,
  maxHeight: 20,
  backgroundColor: theme.palette.backgroundQuaternary,
}));

const Menu = styled(MuiMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    minWidth: '250px',
    marginTop: theme.spacing(3),
    border: `1px solid ${theme.palette.border.primary}`,
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '& .MuiMenuItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiStack-root': {
      width: 'auto !important',
    },
  },
}));

interface IProps {
  columns: Column[];
  toggleColumn(columnId: ColumnType): void;
  resetColumns(): void;
  hidden?: boolean;
}

const TableColumnsSwitcher: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [dropdownEl, setDropdownEl] = React.useState(null);

  const handleToggleDropdown = (event) => {
    setDropdownEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setDropdownEl(null);
  };

  const visibleProperties = props.columns.filter(({visible, allowHide}) => visible && allowHide);

  if (props.hidden) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" size="large" onClick={handleToggleDropdown}>
        <PropertiesIcon style={{fill: theme.palette.body.primary, marginRight: theme.spacing(2)}} />
        {t('servers_page_properties_button')}
        <CounterBox>{visibleProperties.length}</CounterBox>
      </Button>

      <Menu anchorEl={dropdownEl} keepMounted open={Boolean(dropdownEl)} onClose={handleCloseDropdown}>
        {props.columns.map(({label, id, visible, allowHide}) =>
          allowHide ? (
            <MenuItem key={id} onClick={() => props.toggleColumn(id)}>
              {label}
              <Switch checked={visible} />
            </MenuItem>
          ) : null,
        )}
        <MenuItem>
          <Button variant="outlined" fullWidth onClick={props.resetColumns}>
            {t('servers_page_reset_properties_button')}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TableColumnsSwitcher;
