import {makeObservable} from 'mobx';
import BaseServerOptions from './BaseServerOptions';

export class StringableServerOptions extends BaseServerOptions<string> {
  constructor() {
    super();
    makeObservable(this);
  }

  findByIds(ids: string[]) {
    return this.list.filter((item) => ids.includes(item));
  }
}

export default StringableServerOptions;
