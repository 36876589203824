import InputBase, {InputBaseProps} from '@mui/material/InputBase';
import {alpha, styled} from '@mui/material/styles';

const StyledInput = styled(InputBase)(({theme, error}) => ({
  position: 'relative',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  fontFamily: '"Inter", sans-serif',
  fontSize: theme.size.fontSize.S,
  fontWeight: theme.size.fontWeight.regular,
  lineHeight: theme.size.lineHeight.S,
  letterSpacing: theme.size.letterSpacing.S,
  border: `1px solid ${theme.palette.border.primary}`,
  color: theme.palette.body.primary,
  borderRadius: 8,
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
    borderColor: theme.palette.border.tertiary,
  },
  '&.Mui-focused': {
    borderColor: theme.palette.brand.primary,
    boxShadow: `${alpha(theme.palette.body.placeholder, 0.25)} 0 0 0 0.1rem`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.backgroundQuaternary,
  },
  ...(error && {
    borderColor: `${theme.palette.error.primary} !important`,
  }),
  '& .MuiInputBase-input': {
    padding: 0,
    WebkitTextFillColor: 'unset !important',
    '&::placeholder': {
      color: theme.palette.body.placeholder,
      opacity: 1,
    },
  },
}));

export const Input: React.FC<InputBaseProps> = (props) => {
  return <StyledInput {...props} />;
};

export default Input;
