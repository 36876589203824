import {LoadingButton} from '@mui/lab';
import {Box, FormControlLabel, styled, useTheme} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {getApiRegisterDomainError} from '../../../api/getApiError';
import {ca2domains} from '../../../api/proto';
import {ReactComponent as ProtectionIcon} from '../../../assets/icons/protection.svg';
import CheckboxField from '../../../components/UI/CheckboxField';
import MenuItem from '../../../components/UI/MenuItem';
import Selector from '../../../components/UI/Selector';
import {Switch} from '../../../components/UI/Switch';
import Typography from '../../../components/UI/Typography';
import PageContentLayout from '../../../layouts/PageContentLayout';
import {useStore} from '../../../stores/AppStore';
import DomainSuggestion from '../../../stores/Domain/DomainSuggestion';
import DomainContacts, {IDomainContactsRef} from '../DomainContacts';

const BorderBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
}));

const GridBox = styled(Box)(({theme}) => ({
  display: 'grid',
  maxWidth: 675,
  columnGap: theme.spacing(4),
  gridTemplateColumns: '1fr 1fr',
}));

const WhoisFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  padding: theme.spacing(2),
  borderRadius: '8px',
  width: '100%',
  margin: 0,
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.border.primary}`,

  '& .MuiStack-root': {
    width: 'auto !important',
  },

  '& .MuiFormControlLabel-label': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const INITIAL_TERM_YEAR = 1;

interface IProps {
  domainSuggestion: DomainSuggestion;
  onSwitchToPaymentStep?(): void;
}

export const RegisterDomainForm: React.FC<IProps> = observer((props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {domainsStore, notification} = useStore();
  const domainContactsRef = React.useRef<IDomainContactsRef>(null);

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik<ca2domains.IRegisterDomainRequest>({
    initialValues: {
      domain: props.domainSuggestion.name,
      termYears: INITIAL_TERM_YEAR,
      whoisPrivacy: false,
      autoRenew: false,
      existingContactId: null,
      newContact: null,
    },
    validationSchema: Yup.object({
      domain: Yup.string().required(t('domains_register_form_name_required')),
      termYears: Yup.number().required(t('domains_register_form_term_years_required')),
      whoisPrivacy: Yup.boolean(),
      autoRenew: Yup.boolean(),
      existingContactId: Yup.number().nullable(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      const {res, error} = await domainsStore.validateRegisterDomainRequestData(values);

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors, contactErrors} = res;

        if (errors?.length || contactErrors?.length) {
          domainContactsRef.current?.setApiErrors(contactErrors);

          errors?.forEach((error) => {
            const errorMessage = getApiRegisterDomainError(error);

            notification.error(errorMessage);
          });

          setLoading(false);
          return;
        }

        domainsStore.setRegisterDomainFormData(values);
        props.onSwitchToPaymentStep?.();

        setLoading(false);
      }
    },
  });

  React.useEffect(() => {
    if (
      domainsStore.registerDomainFormData &&
      props.domainSuggestion.name === domainsStore.registerDomainFormData.domain
    ) {
      const {newContact, ...registerDomainFormData} = domainsStore.registerDomainFormData;

      formik.setValues(registerDomainFormData);

      if (newContact) {
        domainContactsRef.current?.setFormValues(newContact);
      }
    }
    // eslint-disable-next-line
  }, [props.domainSuggestion, domainsStore.registerDomainFormData]);

  const handleSubmitForm = async () => {
    const selectedContactId = domainContactsRef.current?.getSelectedContactId();

    if (selectedContactId) {
      formik.setFieldValue('existingContactId', selectedContactId);
      formik.handleSubmit();
      return;
    }

    const newContactValues = await domainContactsRef.current?.getFormFields();
    if (newContactValues) {
      formik.setFieldValue('newContact', newContactValues);

      formik.handleSubmit();
    }
  };

  return (
    <PageContentLayout>
      <BorderBox>
        <Typography variant="label3" sx={{marginBottom: theme.spacing(4), color: theme.palette.body.tertiary}}>
          {t('domains_register_form_title')}
        </Typography>

        <Typography variant="body3">{t('domains_register_form_domain_name_label')}</Typography>
        <Typography variant="h3" sx={{marginBottom: theme.spacing(4)}}>
          {props.domainSuggestion.name}
        </Typography>

        <GridBox>
          <div>
            <Selector
              name="termYears"
              label={t('domains_register_form_term_years_label')}
              value={formik.values.termYears}
              onChange={formik.handleChange}
              error={!!formik.errors.termYears}
              helperText={formik.errors.termYears}
            >
              {props.domainSuggestion.termYears.map((count) => (
                <MenuItem key={count} value={count}>
                  {count === 1
                    ? t('domains_register_form_term_year_list_item_label', {count})
                    : t('domains_register_form_term_years_list_item_label', {count})}
                </MenuItem>
              ))}
            </Selector>

            <WhoisFormControlLabel
              control={<Switch />}
              labelPlacement="start"
              label={
                <>
                  <ProtectionIcon
                    style={{
                      fill: formik.values.whoisPrivacy ? theme.palette.success.primary : theme.palette.body.placeholder,
                      marginRight: theme.spacing(2),
                    }}
                  />{' '}
                  <span>{t('domains_register_form_whois_protection_label')}</span>
                  <span style={{marginLeft: 'auto', marginRight: theme.spacing(2)}}>
                    {t('domains_register_form_whois_protection_free_label')}
                  </span>
                </>
              }
              checked={!!formik.values.whoisPrivacy}
              onChange={() => {
                formik.setFieldValue('whoisPrivacy', !formik.values.whoisPrivacy);
              }}
            />

            <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(4)}}>
              <Box>
                <Typography variant="body3">{t('domains_register_form_price_label')}</Typography>
                <Typography variant="h4">{props.domainSuggestion.registrationPriceFormatter.formatted}</Typography>
              </Box>

              <CheckboxField
                FormLabelProps={{
                  sx: {
                    padding: `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(0)}`,
                    border: `1px solid ${theme.palette.border.primary}`,
                    borderRadius: '8px',
                    margin: 0,
                  },
                }}
                label={t('domains_register_form_auto_renewal_label')}
                checked={!!formik.values.autoRenew}
                onChange={() => {
                  formik.setFieldValue('autoRenew', !formik.values.autoRenew);
                }}
              />
            </Box>
          </div>
        </GridBox>
      </BorderBox>

      <BorderBox>
        <Typography variant="label3" sx={{marginBottom: theme.spacing(2)}}>
          {t('domain_contacts_title')}
        </Typography>

        <DomainContacts
          ref={domainContactsRef}
          existingContactId={domainsStore.registerDomainFormData?.existingContactId}
          isEditableMode
        />
      </BorderBox>

      <BorderBox>
        <GridBox>
          <div>
            <Typography variant="body2" color="body.tertiary" gutterBottom>
              {t('domains_register_form_price_total')}
            </Typography>
            <Typography variant="h4" sx={{marginBottom: theme.spacing(4)}}>
              {props.domainSuggestion.registrationPriceFormatter.formatted}
            </Typography>

            <LoadingButton variant="contained" size="large" fullWidth loading={loading} onClick={handleSubmitForm}>
              {t('domains_register_form_submit_button', {
                price: props.domainSuggestion.registrationPriceFormatter.formatted,
              })}
            </LoadingButton>
          </div>
        </GridBox>
      </BorderBox>
    </PageContentLayout>
  );
});

export default RegisterDomainForm;
